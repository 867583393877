{
  "settings": {
    "calendar": {
      "calendar_settings": "Impostazioni calendario",
      "group": "Gruppo",
      "manage_your_calendars_and_groups": "Gestisci i tuoi calendari e gruppi",
      "groups": "Gruppi",
      "create_group": "Crea Gruppo",
      "create_calendar": "Crea calendario",
      "calendar_name": "Nome del calendario",
      "group_duration": "Durata del gruppo",
      "calendar_type": "Tipo di calendario",
      "action": "Azione",
      "round_robin": "Round Robin",
      "event": "Evento",
      "group_name": "Nome del gruppo",
      "calendars": "Calendari",
      "date_updated": "Data Aggiornamento",
      "status": "Stato",
      "action_dropdown": "Menu a discesa delle azioni",
      "edit": "Modifica",
      "duplicate": "duplicato",
      "copy_embed_code": "Copia Codice di Incorporamento",
      "copy_permanent_link": "Copia link permanente",
      "copy_new_slug_link": "Copia link di pianificazione",
      "copy_scheduling_link": "Copia collegamento pianificazione (Legacy non più supportato)",
      "deactivate_calendar": "Disattivare il calendario",
      "move_to_group": "Sposta nel gruppo",
      "delete_calendar": "Elimina calendario",
      "delete_group": "Cancellare",
      "delete_this_group": "Cancella questo gruppo",
      "activate_calendar": "Attiva il calendario",
      "duration": "Durata (minuti)",
      "all": "Tutto",
      "draft": "Bozza",
      "active": "Attivo",
      "rearrange_calendars": "Riorganizza calendari",
      "deactivate_all_calendars_in_group": "Disattiva tutti i calendari nel gruppo",
      "activate_all_calendars_in_group": "Attivare tutti i calendari nel gruppo",
      "no_groups_found": "La tua ricerca non ha prodotto risultati corrispondenti a nessun gruppo di calendari",
      "no_groups_in_location": "Inizia ad usare il gruppo creando un nuovo gruppo calendario",
      "no_calendars_found": "Inizia usando il calendario creando un nuovo calendario",
      "no_calendars_found_search": "La tua ricerca non ha prodotto risultati corrispondenti a nessun calendario",
      "delete_calendar_message": "Stai per eliminare il Calendario. Quando premi elimina, eliminerai anche tutti gli appuntamenti associati",
      "delete_calendar_message_title": "Eliminare questo calendario?",
      "no_calendars_found_title": "Nessun calendario trovato",
      "no_groups_in_location_title": "Nessun gruppo trovato",
      "delete_group_confirmation_title": "Eliminare questo gruppo?",
      "delete_group_confirmation": "Sei sicuro di voler eliminare il gruppo selezionato?",
      "delete_group_confirmation_checkbox_title": "Elimina calendari e appuntamenti associati",
      "delete_group_confirmation_checkbox_description": "Se si seleziona questa opzione, verranno cancellati anche i calendari in questo gruppo, insieme a tutti gli appuntamenti presenti in quei calendari",
      "group_status_message_title": "{status} questo gruppo?",
      "calendar_status_message_title": "{status} questo calendario?",
      "calendar_status_message": "Stai per {status} il calendario",
      "group_status_message": "Stai per diventare un Gruppo {status}",
      "cancel": "Annulla",
      "new_service_menu": "Nuovo menu del servizio",
      "service_menus": "Menu dei servizi",
      "manage_your_calendars_groups_and_service_menus": "Gestisci i tuoi calendari, gruppi e menu di servizio",
      "deactivate_service_menu": "Disattiva menu di servizio",
      "delete_service_menu": "Elimina questo Menu di Servizio",
      "delete_service_menu_confirmation": "Sei sicuro di voler eliminare questo menu dei servizi?",
      "service_menu_status_message_title": "{status} questo Menù del Servizio?",
      "service_menu_status_message": "Stai per {status} menu del servizio",
      "no_service_menus_in_location_title": "Crea il tuo menu dei servizi personalizzato",
      "add_new_service_menu": "Aggiungi nuovo menu di servizio",
      "no_service_menus_in_location": "Inizia creando un nuovo menu di servizio e aggiungendo dei gruppi ad esso",
      "no_service_menu_found": "Nessun menu di servizio trovato",
      "no_service_menu_found_desc": "La tua ricerca non corrisponde a nessun menu dei servizi",
      "rooms": "Stanze",
      "equipments": "Attrezzature",
      "share_otl": "Condividi la tua disponibilità ogni volta con un link unico che scade dopo una prenotazione, garantendo un accesso controllato",
      "generate_link": "Genera nuovo link",
      "share_schedule_link": "Il collegamento per la pianificazione è determinato dallo slug. Modifica lo slug e il collegamento per la pianificazione si adatterà automaticamente alla modifica",
      "share_permanent_link": "Ideale per imbuto, reindirizzamenti di siti web o annunci, il link permanente rimane costante, non influenzato dai cambiamenti dello slug",
      "copy": "Copia",
      "share_embed_code": "Inserisci questo codice nel tuo HTML dove desideri che appaia il tuo widget {embedType}",
      "scheduling_link": "Link di pianificazione",
      "permanent_link": "Link permanente",
      "one_time_link": "Collegamento ad utilizzo unico",
      "embed_code": "Codice Embed",
      "loading": "Caricamento...",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Assegna gli appuntamenti tra i membri del team in un ordine rotativo",
      "round_robin_example": "Per esempio: Chiamate di vendita, sessioni di introduzione",
      "unassigned_booking": "Calendario degli eventi",
      "unassigned_description": "Per la pianificazione di eventi fisici senza associazione ospitante",
      "unassigned_example": "Ad esempio: Conferenze, seminari pubblici, fiere",
      "class_booking": "Prenotazione classe",
      "class_booking_description": "Un host incontra più partecipanti",
      "class_booking_example": "Per esempio: Webinar, formazione di gruppo, corsi online",
      "collective_booking": "Prenotazione collettiva",
      "collective_booking_description": "Diversi host incontrano un singolo partecipante",
      "collective_booking_example": "EAd esempio: colloqui di gruppo, revisioni da parte di commissioni",
      "service_booking": "Prenotazione servizio",
      "service_booking_description": "Pianificazione senza soluzione di continuità per le aziende basate sui servizi",
      "service_booking_example": "Ad esempio: appuntamenti in spa, servizi di riparazione, consulenze",
      "personal_booking": "Prenotazione personale",
      "personal_booking_description": "Programma incontri one-on-one con un membro specifico del team",
      "personal_booking_example": "Ad esempio: incontri con i clienti, consulenze private",
      "personal": "Personale",
      "explore_more_types": "Esplora più tipologie",
      "scheduling_type": "Tipo di pianificazione",
      "scheduling_type_description": "Scegli un tipo di pianificazione per il tuo nuovo calendario"
    },
    "preferences": {
      "preferences": "Preferenze",
      "my_preferences": "La mia preferenza",
      "account_preferences": "Preferenze account",
      "user_preferences": "Preferenze utente",
      "set_preferences": "Imposta le preferenze per il tuo account",
      "scheduling_options": "Opzioni di pianificazione",
      "meeting_location": "Luogo di incontro",
      "zoom": "Zoom",
      "save_preferences": "Salva preferenze",
      "in_app_preferences": "Preferenze in-app",
      "set_start_day": "Imposta le preferenze come il giorno di inizio per l'applicazione",
      "view_options": "Opzioni di visualizzazione",
      "week_starts_on": "La settimana inizia il giorno:",
      "sunday": "Domenica",
      "widget_preferences": "Preferenze widget",
      "set_widget_language": "Imposta le preferenze, come la lingua, per il widget",
      "language_and_region": "Lingua e regione",
      "language": "Lingua",
      "english": "Inglese",
      "time_format": "Formato dell'ora",
      "time_format_value": "5,625",
      "monday": "lunedì",
      "custom_meeting_location": "Posizione personalizzata della riunione"
    },
    "availability": {
      "availability": "Disponibilità",
      "my_availability": "La mia disponibilità",
      "account_availability": "Disponibilità dell'account",
      "timezone": "Fuso orario",
      "working_hours": "Orario di lavoro",
      "set_recurring_hours": "Imposta le tue ore di lavoro ricorrenti per incontri",
      "sunday": "Domenica",
      "monday": "Lunedì",
      "tuesday": "Martedì",
      "wednesday": "Mercoledì",
      "thursday": "giovedì",
      "friday": "venerdì",
      "saturday": "Sabato",
      "widget_preferences": "Preferenze Widget",
      "set_widget_language": "Imposta le preferenze come la lingua per il widget",
      "language_and_region": "Lingua e regione",
      "language": "Lingua",
      "english": "inglese"
    },
    "connections": {
      "connections": "Collegamenti",
      "conferencing": "Videoconferenza",
      "my_connections": "Le mie connessioni",
      "account_connections": "Connessioni Account",
      "connection_preferences": "Preferenze di connessione",
      "manage_calendars_conferencing_channels": "Gestire calendari e canali di videoconferenza",
      "main_integration_calendar": "Calendario principale di integrazione",
      "event_integration_calendar": "Gli eventi creati in un calendario di sistema di cui fai parte verranno anche creati in questo calendario integrato",
      "2way_sync": "Sincronizzazione bidirezionale: creazione di eventi di sistema dagli eventi prenotati nel calendario integrato principale",
      "calendars": "Calendari",
      "google": "Google",
      "outlook": "Prospettiva",
      "check_for_conflicts": "Verifica eventuali conflitti",
      "choose_accounts_to_connect": "Scegli gli account che desideri connettere",
      "payments": "Pagamenti",
      "stripe": "Riga",
      "ads": "Annunci pubblicitari",
      "local_service_ads": "Annunci di servizio locale",
      "fire_workflow": "Attivatori di flusso di lavoro per i eventi prenotati tramite sincronizzazione bidirezionale",
      "connect_google_outlook": "Per favore, connetti il tuo account Google o Outlook per poter selezionare il calendario principale",
      "execute_automation": "Esegui automazione",
      "save": "Salva",
      "lsa": "Annunci di Servizi Locali"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Seleziona un contatto",
    "start_time": "Orario di inizio",
    "end_time": "Fine dei tempi",
    "search_by_name_email_phone_or_company": "Cerca per nome, email, telefono o azienda",
    "add_new": "Aggiungi nuovo",
    "pick_from_available_contacts": "O scegli tra i contatti disponibili",
    "book_appointment": "Prenota appuntamento",
    "calendar": "Calendario",
    "repeat": "Ripeti",
    "date_and_time": "Data e ora",
    "close": "Chiudere",
    "team_member": "Membro del team",
    "recurring_booking": "Prenotazione ricorrente",
    "one_slot_booking": "Una prenotazione a singolo slot",
    "event_type": "Tipo di evento",
    "occurrences_booked": "Le occorrenze saranno registrate",
    "repeats": "Si ripete",
    "booking_details": "Dettagli della prenotazione",
    "showing_slots_in_this_timezone": "Mostrando slot in questo fuso orario:",
    "update_contact_tz": "Aggiorna anche il fuso orario del contatto a",
    "date": "Appuntamento",
    "slot": "Slot",
    "calendar_default": "Calendario predefinito",
    "custom": "personalizzato",
    "meeting_notes": "(es) Appunti di riunione per il nostro prossimo appuntamento",
    "out_of_office": "(es) Fuori ufficio per la settimana!",
    "standard": "Predefinito",
    "appointment_title": "Titolo dell'appuntamento",
    "event_title": "Titolo dell'evento",
    "appt_with_bob": "(es) Appuntamento con Bob",
    "vacation": "(es) Vacanza",
    "show_notes": "Note dello spettacolo",
    "hide_notes": "Nascondi Note",
    "additional_preferences": "Preferenze aggiuntive",
    "meeting_location": "Luogo dell'incontro",
    "appointment_status": "Stato appuntamento",
    "appt_description": "Descrizione dell'appuntamento",
    "recurring_details": "Dettagli ricorrenti",
    "event_description": "Descrizione dell'evento",
    "in_contact_timezone": "Fuso orario di contatto",
    "vacation_message": "Vai in vacanza? Ti prendi del tempo libero? Blocca del tempo sul tuo calendario per evitare che i clienti prenotino appuntamenti (gli appuntamenti già prenotati rimarranno comunque sul tuo calendario)",
    "contact": "Contatto",
    "delete": "Elimina",
    "cancel": "Annulla",
    "back": "Indietro",
    "pick_available_contacts": "Oppure scegli tra i contatti disponibili",
    "no_contacts_found": "Nessun contatto trovato",
    "found": "trovato",
    "select_one": "Selezionare uno",
    "tz_tooltip": "Questo ha importanza solo se stai utilizzando Valori Personalizzati; come ad esempio appointment_start_time, appointment_end_time, appointment_timezone ecc ... nella tua comunicazione in uscita con i tuoi clienti o clienti. E ciò che fa semplicemente è convertire i suddetti dettagli nel loro orario locale",
    "meeting_location_tooltip": "La posizione dell'incontro verrà impostata come configurata nel calendario",
    "appt_meeting_location_tooltip": "L'utente può impostare la posizione dell'incontro specifica per l'appuntamento",
    "gmeet_tooltip": "Per favore assicurati che il membro del team rispettivo abbia impostato un calendario Google come calendario principale",
    "zoom_tooltip": "Assicurati che il membro del team rispettivo abbia integrato Zoom",
    "enter_meeting_location": "Inserisci il luogo dell'incontro",
    "save_appointment": "Salva appuntamento",
    "save_event": "Salva evento",
    "search_contacts": "Cerca per nome, email, telefono o azienda",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Calendario",
    "appointment_owner": "Proprietario dell'appuntamento",
    "source": "Fonte",
    "booked_by": "Prenotato da",
    "view_activity_log": "Visualizza registro attività",
    "status": "Stato",
    "cancel_appointment": "Annulla appuntamento",
    "google_event": "Evento Google",
    "outlook_event": "Evento Outlook",
    "blocked_slot": "Slot bloccato",
    "appointment": "Appuntamento",
    "confirmed": "Confermato",
    "unconfirmed": "Non confermato",
    "cancelled": "Annullato",
    "showed": "Mostrato",
    "no_show": "assenteismo",
    "invalid": "Non valido"
  },
  "appointment_list": {
    "group_all": "TUTTO",
    "group_primary": "PRIMARIO",
    "group_user": "UTENTI",
    "group_team": "GRUPPI",
    "group_calendar": "CALENDARI",
    "all_appointments": "Tutti gli appuntamenti",
    "my_calendar": "Il mio calendario",
    "status_all": "Tutti",
    "status_confirmed": "Confermato",
    "status_new": "Nuovo (azione richiesta)",
    "status_show": "mostrato",
    "status_no_show": "Assenteista",
    "status_cancelled": "Annullato",
    "status_invalid": "Invalido",
    "filter_date_added_desc": "Data Aggiunta (Discendente)",
    "filter_start_added_desc": "Data di inizio (discendente)",
    "filter_start_added_asc": "Inizio data in ordine crescente a partire da oggi",
    "button_new_appointment": "Nuova nomina",
    "name": "Nome",
    "title": "Titolo",
    "requested_time": "Tempo richiesto",
    "date_added": "Data di aggiunta",
    "calendar": "Calendario",
    "appointment_owner": "Proprietario dell'appuntamento",
    "notes": "Appunti",
    "status": "Stato",
    "action_view_detail": "Visualizza dettagli",
    "action_view_notes": "Visualizza note",
    "action_edit": "Modifica",
    "action_delete": "Elimina",
    "action_reschedule": "Riprogrammare",
    "page_previous": "Precedente",
    "page_next": "Prossimo",
    "appointment_notes": "Note sull'appuntamento",
    "close": "Chiudere",
    "confirm_delete_header": "Elimina appuntamento",
    "confirm_delete_message": "Sei sicuro di voler cancellare questo appuntamento?",
    "confirm_delete_button": "Elimina",
    "confirm_update_header": "Aggiorna stato appuntamento",
    "confirm_update_confirmed_message": "Sei sicuro di voler contrassegnare questo appuntamento come confermato?",
    "confirm_update_invalid_message": "Sei sicuro di voler contrassegnare questo appuntamento come non valido?",
    "confirm_update_showed_message": "Sei sicuro di voler contrassegnare questo appuntamento come mostrato?",
    "confirm_update_noshow_message": "Sei sicuro di voler contrassegnare questo appuntamento come non presentato?",
    "confirm_update_cancelled_message": "Sei sicuro di voler contrassegnare questo appuntamento come annullato?",
    "confirm_update_button": "Aggiornamento",
    "disable_menu_warning": "Attualmente, non hai il permesso di visualizzare o modificare i dettagli dell'appuntamento, poiché il contatto associato non ti è assegnato. Ti preghiamo di contattare il tuo amministratore per ulteriore assistenza",
    "no_contact_warning": "Non è possibile accedere alle informazioni di contatto al momento. Questo potrebbe essere dovuto al fatto che il contatto non è assegnato a te. Per assistenza, contatta il tuo amministratore",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Riprogrammato"
  },
  "create_calendar": {
    "calendar_name": "Nome del calendario",
    "calendar_logo": "Logo del calendario",
    "select_team_member": "Seleziona i membri del team",
    "eg_outbound_reach": "(Ad esempio) Raggiungimento verso l'esterno",
    "description": "Descrizione",
    "amount": "Quantità",
    "description_placeholder": "Scrivi descrizione",
    "meeting_duration": "Durata dell'incontro",
    "seats_per_class": "Posti per classe",
    "group": "Gruppo",
    "custom_url": "URL personalizzato",
    "meeting_invite_title": "Titolo invito riunione",
    "event_color": "Colore dell'evento",
    "availability_type": "Tipo disponibilità",
    "deposit_amount": "Importo del deposito",
    "collect_deposit_amount": "Accettare il pagamento parziale",
    "percentage": "Percentuale",
    "total_amount": "Importo totale",
    "reschedule_preference": "Preferenza di riassegnazione",
    "reschedule_preference_subtext": "Se un contatto riprogramma un appuntamento:",
    "reassign_through_round_robin": "Assegnare nuovamente tramite round robin",
    "keep_same_assinged_user": "Mantieni lo stesso proprietario di appuntamento",
    "new_appointment_preference": "Preferenza di Nuovo Appuntamento",
    "new_appointment_preference_text": "Prenota sempre con l'utente assegnato al contatto",
    "new_appointment_preference_subtext": "Prenotare nuovi appuntamenti con l'utente assegnato al contatto anziché utilizzare la pianificazione round-robin"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Suggerimento rapido",
      "timezone_change_tip": "I fusi orari possono essere modificati in qualsiasi momento nelle preferenze del fuso orario",
      "save": "Salva",
      "close": "Chiudi",
      "meeting_details": "Dettagli dell'incontro",
      "availability": "Disponibilità",
      "forms_and_payment": "Moduli e pagamento",
      "notifications_and_additional_options": "Notifiche e opzioni aggiuntive",
      "connections": "Collegamenti",
      "customizations": "Personalizzazioni",
      "create": "Creare",
      "edit": "Modifica"
    },
    "meeting_details": {
      "meeting_location": "Luogo dell'incontro",
      "enter_meeting_location": "Inserisci la località della riunione",
      "click_to_upload": "Clicca per caricare",
      "square": "Piazza",
      "circle": "Cerchio",
      "remove_logo": "Rimuovi logo",
      "meeting_invite_title_tooltip": "Questo è il titolo per l'evento nel calendario che appare in Google Calendar, Outlook, Apple Calendar, ecc",
      "event_color_tooltip": "Scegli un colore per questo evento di prenotazione. I colori vengono sincronizzati con gli eventi in Google Calendari",
      "enter_a_name": "Per favore, inserisci un nome",
      "enter_an_event_title": "Inserisci un titolo per l'evento, per favore",
      "high_priority": "Alta Priorità",
      "medium_priority": "Priorità Media",
      "low_priority": "Bassa Priorità",
      "custom": "Su misura",
      "zoom_not_added": "L'utente non ha ancora collegato il suo account Zoom",
      "opt_avail": "Ottimizza per l'affidabilità",
      "opt_equal": "Ottimizza per una distribuzione equa",
      "team_members": "Seleziona membri del team",
      "team_member": "Seleziona membro del team",
      "meeting_location_required": "È richiesta una posizione per l'incontro",
      "team_member_disabled_recurring_tooltip": "Le riunioni ricorrenti permettono solo un membro del team. Per aggiungere più membri del team, si prega di disabilitare le riunioni ricorrenti.",
      "team_member_disabled_class_booking_tooltip": "Solo un membro del team può essere aggiunto al calendario delle prenotazioni di una classe",
      "enable_contact_assigned_to_setting": "Per abilitare questa impostazione, il Modulo deve essere il primo nell'ordine dei widget. Si prega di riordinare l'ordine dei widget dalla scheda Moduli e Pagamenti",
      "reschedule_preference_setting_tooltip": "Scegli a chi assegnare l'appuntamento quando un contatto riprogramma dalla widget di prenotazione",
      "appointment_preference_tooltip": "Scegli se i nuovi appuntamenti devono essere sempre prenotati con l'utente assegnato al contatto. Se un contatto ha un utente assegnato, quello sarà privilegiato; altrimenti, sarà una assegnazione round-robin. Suggerimento: Disattiva 'Consenti Selezione Staff' per evitare che chi prenota possa cambiare il membro dello staff sul widget di prenotazione",
      "add_location": "Aggiungi Posizione",
      "multiple_locations_neo": "Sono supportate solo dalla Neo Widget con il Modulo Predefinito",
      "make_owner": "Rendi proprietario",
      "only_one_team_member": "È consentito un solo membro del team quando vengono aggiunte più sedi",
      "only_one_team_member_personal_calendar": "Solo un membro del team è autorizzato per la prenotazione personale"
    },
    "availability": {
      "my_availability": "La mia disponibilità",
      "set_availability": "Imposta la tua disponibilità per il calendario qui",
      "standard": "Standard",
      "custom": "su misura",
      "to": "A",
      "copy_to_all": "Copia per tutti",
      "add_time": "Aggiungi tempo",
      "recurring_meeting": "Incontro ricorrente",
      "recurring_info": "Solo un utente per calendario per prenotazione ricorrente",
      "repeat": "ripeti",
      "times_to_repeat": "Volte da ripetere",
      "slots_unavailable": "Se le slot non sono disponibili?",
      "overlap_status": "Stato dell'appuntamento per slot sovrapposti",
      "meeting_interval": "Intervallo tra le riunioni",
      "meeting_duration": "Durata dell'incontro",
      "minimum_scheduling": "Preavviso minimo di programmazione",
      "date_range": "Intervallo di date",
      "maximum_bookings_per_day": "Massimo prenotazioni al giorno",
      "maximum_bookings_per_slot": "Numero massimo di prenotazioni per intervallo di tempo",
      "maximum_bookings_per_slot_per_user": "Massimo prenotazioni per slot (per utente)",
      "seats_per_class": "Posti per classe",
      "pre_buffer_time": "Tempo di pre-buffering",
      "post_buffer_time": "Tempo di buffer dopo la spedizione",
      "pre_buffer_tooltip": "Il Pre-Buffer è il tempo aggiuntivo che può essere aggiunto prima di un appuntamento, permettendo di avere tempo extra per prepararsi prima di qualsiasi riunione",
      "post_buffer_tooltip": "Il Post-Buffer è il tempo aggiuntivo che può essere aggiunto dopo un appuntamento, permettendo di avere un tempo extra per concludere",
      "buffer_time": "Tempo di attesa",
      "daily": "Quotidiano",
      "weekly": "Settimanale",
      "monthly": "Mensile",
      "skip_booking": "Salta la prenotazione delle fasce orarie non disponibili",
      "continue_booking": "Continua la prenotazione",
      "book_next_available": "Prenota il prossimo slot disponibile",
      "confirmed": "Confermato",
      "unconfirmed": "Non confermato",
      "enter_valid_hours": "Per favore inserisci un orario valido di apertura",
      "enter_valid_avail": "Inserisci un orario di disponibilità personalizzato valido, per favore",
      "availability_type_tooltip": "Scegli Disponibilità Standard per prenotazioni regolari offerte settimanalmente. Scegli Personalizzata per prenotazioni disponibili solo in date specifiche",
      "meeting_interval_tooltip": "L'intervallo di incontro riflette la quantità di tempo tra gli slot di prenotazione che verranno mostrati nel calendario. Per un evento di 30 minuti che dovrebbe essere disponibile all'inizio di ogni ora, l'intervallo sarebbe di 1 ora. Per un evento di 30 minuti che dovrebbe essere disponibile ogni 30 minuti, l'intervallo sarebbe di 30 minuti",
      "date_range_tooltip": "Gli eventi possono essere pianificati nei prossimi n giorni",
      "maximum_bookings_per_day_tooltip": "Numero massimo di appuntamenti consentiti al giorno su questo calendario",
      "maximum_bookings_per_slot_tooltip": "Massimo numero di appuntamenti consentiti per slot per l'utente (Se il numero di appuntamenti supera il limite per lo slot dato per l'utente, allora passa al prossimo utente nella coda round-robin o segna lo slot come non disponibile per la prenotazione del potenziale cliente)",
      "add_days": "Aggiungi giorni",
      "month_on_day": "Mensile il giorno",
      "month_on_last_day": "Mensile all'ultimo giorno",
      "day": "Giorno",
      "week": "Settimana",
      "month": "Mese",
      "mins": "Minuti",
      "hours": "Ore",
      "days": "Giorni",
      "weeks": "Settimane",
      "months": "Mesi",
      "meeting_interval_error": "L'intervallo tra le riunioni deve essere compreso tra 5 minuti e 12 ore",
      "meeting_duration_error": "La durata dell'incontro deve essere compresa tra 1 minuto e 12 ore",
      "buffer_time_error": "Il tempo di buffer deve essere inferiore a 12 ore",
      "subTitle": "Scegli la data per impostare gli orari specifici",
      "heading_1": "Ore disponibili settimanali",
      "tooltip_1": "Imposta il tuo programma di disponibilità settimanale. Queste sono le tue ore standard che verranno applicate regolarmente su base settimanale",
      "heading_2": "Quando sei disponibile?",
      "unavailable_label": "Non disponibile",
      "tooltip_2": "Regola il tuo programma per date specifiche. Sovrascrivi le tue tradizionali ore settimanali indicando disponibilità o indisponibilità per le date selezionate",
      "date_specific_level": "Aggiungi ore specifiche per una data",
      "specific_hours_button": "Ore specifiche della data",
      "overlap_error": "Gli slot temporali non possono sovrapporsi",
      "apply": "Applicare",
      "copy_times_to": "Copia tempi a",
      "no_date_specific_heading": "Nessuna data o orario specifico aggiunto",
      "no_date_specific_description": "Puoi aggiungere/rimuovere date e orari specifici alla tua disponibilità",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "Per includere un'ora specifica per una data, assicurati di disattivare le riunioni ricorrenti",
      "recurring_disable_event_calendar_conditions": "Gli incontri ricorrenti sono disabilitati. Per attivarli, assicurarsi che il pagamento sia disattivato e non siano aggiunte ore specifiche per date",
      "service_interval": "Intervallo di servizio",
      "service_interval_tooltip": "L'intervallo di incontro definisce con quale frequenza compaiono gli slot di prenotazione nel calendario. Ad esempio, per un evento della durata di 30 minuti, un intervallo di 1 ora significa che gli slot appaiono ogni ora. Un intervallo di 30 minuti significa che gli slot appaiono ogni 30 minuti",
      "service_duration_tooltip": "Questo definisce la durata del tuo appuntamento, stabilendo quanto tempo durerà",
      "minimum_scheduling_tooltip": "Questo imposta il tempo minimo necessario per prenotare un appuntamento tramite il widget del calendario. Ad esempio, se impostato a 4 ore, gli appuntamenti non possono essere prenotati entro le 4 ore dall'ora di inizio; le prenotazioni devono essere effettuate almeno 4 ore in anticipo",
      "service_duration": "Service duration",
      "look_busy_title": "Fai finta di essere impegnato",
      "look_busy_percentage_description": "Nascondere il numero di posti disponibili del x%",
      "look_busy_helper_text": "Nascondere una percentuale dei tuoi slot di appuntamenti disponibili sul widget di prenotazione per sembrare più occupato e molto richiesto",
      "maximum_bookings_per_slot_tooltip_personal": "Numero massimo di appuntamenti consentito per slot per l'utente"
    },
    "forms_payment": {
      "forms": "Forme",
      "forms_sub": "Imposta le preferenze del modulo di programmazione dei post",
      "select_form": "Seleziona modulo",
      "sticky_contacts": "Pre-impostare campi (contatti fissi)",
      "confirmation_page": "Pagina di conferma",
      "default": "Predefinito",
      "redirect_url": "URL di reindirizzamento",
      "thank_you": "Messaggio di ringraziamento",
      "enter_url": "Inserisci l'URL",
      "pixel_id": "ID pixel di Facebook (opzionale)",
      "auto_confirm": "Conferma automatica delle nuove riunioni sul calendario",
      "configure_payment": "Configurare le opzioni di pagamento",
      "accept_payments": "Accettare pagamenti",
      "test": "Test",
      "live": "Vivere",
      "enable_guests": "Aggiungi ospiti",
      "add_guest_tooltip": "Aggiungi ospite permetterà al partecipante di aggiungere più partecipanti alla stessa appuntamento",
      "add_guest_same_form_check": "Inviare lo stesso modulo per raccogliere informazioni tramite posta a tutti gli ospiti",
      "payment_tooltip": "Il pagamento sarebbe preso in considerazione solo per il partecipante principale e non per gli ospiti",
      "default_form": "Predefinito (Nome, Cognome, Email, Telefono, Note)",
      "select_form_tooltip": "Se desideri raccogliere maggiori informazioni rispetto alle classiche Nome, Cognome, Email e Telefono da coloro che prenotano su questo calendario, crea un modulo personalizzato in Siti > Moduli > Generatore e seleziona il modulo tramite la tendina qui sotto",
      "confirmation_page_tooltip": "Una volta che qualcuno effettua una prenotazione, puoi scegliere di mostrargli un messaggio personalizzato sulla stessa pagina (impostazione predefinita) o reindirizzarlo a una pagina di tua scelta (reindirizzamento)",
      "add_guest_collect_email": "Con Nome e email",
      "add_guest_count_only": "Conta solo",
      "custom_email_notification": "Invia Modulo Personalizzato agli Ospiti",
      "deposit_amount_error": "L'importo del deposito deve essere inferiore all'importo totale",
      "deposit_percentage_error": "Il deposito percentuale deve essere inferiore al 100%",
      "partial_payment_tooltip": "Abilita i pagamenti parziali per raccogliere l'importo del deposito dai clienti durante il processo di prenotazione dell'appuntamento",
      "partial_payment_enable_error": "L'importo totale inserito deve essere maggiore di 0 per consentire pagamenti parziali",
      "payment_disable_text_integration": "Integrare un provider di pagamento sotto Pagamenti > Integrazioni per iniziare ad accettare pagamenti",
      "payment_disable_text_recurring": "L'opzione di accettazione dei pagamenti non è disponibile per gli incontri ricorrenti. Disattiva la ricorrenza per abilitare i pagamenti",
      "date_and_time_selector": "Selettore di data e ora",
      "form_selector": "Modulo",
      "order_step": "Passo",
      "widget_order_label": "Ordine widget",
      "widget_order_tool_tip": "Riorganizza l'ordine del 'Selettore Data e Ora' e del 'Modulo' sul widget del calendario. Trascina e riordina semplicemente secondo le tue preferenze",
      "same_user_assignment_tooltip": "Per abilitare questa impostazione, il modulo deve essere il primo nel widget. Riorganizza il widget posizionando il modulo per attivare questa impostazione",
      "widget_re_order_tooltip": "La riorganizzazione è disponibile solo con lo stile widget Neo. Passa a Neo per abilitare la riorganizzazione",
      "widget_re_order_disabled_tooltip": "Per riordinare l'ordine dei widget, si prega di disabilitare 'Preferenza nuova appuntamento (Prenota sempre con l'utente assegnato al contatto)' dalla sezione Impostazioni Avanzate di Dettagli Riunione > Membri del Team",
      "enable_same_user_assignment": "Assegnare i contatti ai rispettivi membri del team del calendario ogni volta che viene prenotato un appuntamento"
    },
    "notifications": {
      "notifications": "Notifiche e opzioni aggiuntive",
      "notifications_sub": "Configurare notifica e opzioni aggiuntive",
      "select_notification_type": "Scegli il tipo di notifica",
      "notification_person": "Chi dovrebbe ricevere questa notifica?",
      "contact": "Contatto",
      "assigned_user": "Utente assegnato",
      "emails": "Emails",
      "alert_email_address": "Indirizzo email di allerta",
      "ack_email": "email di conferma",
      "send_google_invites": "Consenti a Google Calendar di inviare inviti o aggiornamenti via email agli invitati",
      "send_google_outlook_invites": "Consenti a Google / Outlook Calendar di inviare email di invito e di aggiornamento agli invitati",
      "assign_user_to_contact": "Assegna i contatti ai rispettivi membri del team del calendario ogni volta che viene prenotato un appuntamento",
      "skip_assigning_user_to_contact": "Ignora l'assegnazione del contatto se il contatto ha già un utente assegnato",
      "guest_label_count": "Numero di ospiti",
      "guest_count_error": "Il numero degli ospiti deve essere compreso tra 1 e 100",
      "allow_cancellation": "Consenti la cancellazione della riunione",
      "allow_reschedule": "Consenti la riorganizzazione dell'incontro",
      "allow_cancellation_tooltip": "Quando attivato, verrà aggiunto un link di cancellazione nella sezione delle note aggiuntive e incluso nell'invito del calendario. Questo link scadrà come configurato prima dell'orario di inizio della riunione, impedendo al prenotante di cancellare la riunione",
      "allow_reschedule_tooltip": "Quando attivato, un collegamento di riappuntamento verrà aggiunto alla sezione delle note aggiuntive e incluso nell'invito al calendario. Questo collegamento scadrà come configurato prima dell'orario di inizio della riunione, impedendo alla persona che ha prenotato di riappuntare la riunione",
      "cancellation_link_expiry": "Il link di cancellazione scadrà",
      "reschedule_link_expiry": "Il link di riprogrammazione scadrà",
      "before_meeting": "prima dell'incontro",
      "cancellation_and_reschedule_policy": "Politica di cancellazione e cambio prenotazione:"
    },
    "connections": {
      "connections_heading": "Sincronizzazione calendario esterno",
      "connections_sub": "Sincronizza gli eventi con il calendario esterno",
      "link_to_calendar": "Link al calendario",
      "sync_option": "opzione di sincronizzazione",
      "execute_automation": "Eseguire automazione",
      "fire_workflow": "Attivazione flusso di lavoro per eventi prenotati tramite sincronizzazione bidirezionale",
      "reserve_with_google": "Prenota con Google",
      "connect_lsa": "Collegati al tuo account pubblicitario servizi locali",
      "reserve_error": "Attualmente, la prenotazione tramite Google è supportata solo per \"Servizi domestici\", \"Immobiliare\" e \"Servizi legali\" (fornitori di servizi locali) nel settore negli Stati Uniti",
      "one_way": "Senso unico",
      "two_way": "Due vie",
      "smart": "Intelligente",
      "confirm_remove": "Sei sicuro di voler rimuovere il calendario di Google?",
      "read_only_access": "Non hai accesso in scrittura a questo calendario. Seleziona un calendario diverso, per favore",
      "reserve_with_google_desc": "Questo servizio di calendario sarà inserito in riserva con Google solo in caso di integrazione riuscita",
      "check_integration": "Controlla il tuo stato di integrazione"
    },
    "extras": {
      "extras": "Personalizzazioni",
      "extras_sub": "Imposta lo stile dei widget e altre preferenze",
      "calendar_widget_style": "Stile del widget del calendario",
      "neo": "Neo",
      "classic": "Classico",
      "insert_custom_code": "Inserisci codice personalizzato",
      "show_seats_per_slot": "Mostra i posti per slot",
      "allow_reschedule_meeting": "Consenti di riprogrammare l'incontro",
      "allow_cancelling_meeting": "Consentire di annullare l'incontro",
      "additional_notes": "Note aggiuntive",
      "cancellation_and_reschedule": "Cancellazione e riprogrammazione:",
      "allow_staff": "Consenti la selezione del personale durante la prenotazione",
      "code_block_tooltip": "I terzi script all'interno del blocco HTML possono fornire funzionalità potenti, ma portano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere i contenuti di questo script prima di salvarlo nel calendario"
    }
  },
  "add_guest": {
    "guest_label": "Ospite(s)",
    "search_placeholder": "Cerca dai contatti",
    "add_new": "Aggiungi nuovo",
    "save": "Salva",
    "close": "Chiudi",
    "name": "Nome",
    "phone": "Telefono",
    "email": "Email"
  },
  "languages": {
    "english": "Inglese",
    "dutch": "Olandese",
    "french": "Francese",
    "german": "Tedesco",
    "hungarian": "Ungherese",
    "italian": "Italiano",
    "polish": "Polacco",
    "portuguese_brazil": "Portoghese (Brasile)",
    "portuguese_portugal": "Portoghese (Portogallo)",
    "spanish": "Spagnolo",
    "danish": "Danese"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Dettagli del menu di servizio",
    "select_groups_header": "Seleziona servizi",
    "arrange_groups_header": "Organizza i tuoi servizi",
    "new_service_menu": "Nuovo menu del servizio",
    "service_menu_sub_title": "Crea un menu di servizio personalizzato per i tuoi clienti con pochi clic",
    "quick_tip": "Puoi aggiungere gruppi al menu del servizio",
    "service_menu_name": "Nome del menu di servizio",
    "service_menu_description": "Descrizione",
    "service_menu_slug": "URL del servizio",
    "select_groups_calendars": "Seleziona gruppi e calendari",
    "select_groups_calendars_desc": "Aggiungi servizi al tuo menu dei servizi",
    "select_all": "Seleziona tutto",
    "arrange_groups": "Organizza l'ordine dei servizi",
    "arrange_groups_desc": "Determinare la sequenza dei servizi nel menu dei servizi",
    "arrange_services_quicktip": "Per riorganizzare i calendari all'interno di un gruppo, vai su Gruppi > Opzioni > Riorganizza Calendari",
    "enable_add_guest_desc": "Permette ai clienti di includere ospiti aggiuntivi durante la prenotazione",
    "enable_multiple_service_desc": "Consente ai clienti di prenotare più servizi in un unico appuntamento",
    "enable_staff_selection_desc": "Permette ai clienti di selezionare i membri dello staff preferiti al momento della prenotazione",
    "enable_add_guest": "Permettere l'aggiunta di ospiti",
    "enable_multiple_service": "Consenti la selezione di più servizi",
    "enable_staff_selection": "Abilita Selezione Staff",
    "additional_options": "Opzioni aggiuntive",
    "code_block_tooltip": "Gli script di terze parti all'interno del blocco HTML possono offrire funzionalità potenti, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel menu del servizio.",
    "custom_code_placeholder": "Per favore, inserisci il codice personalizzato qui",
    "insert_custom_code": "Inserisci codice personalizzato",
    "no_service": "Nessun servizio trovato",
    "no_service_desc": "Nessun servizio trovato. Crea servizi e aggiungili ai gruppi per creare un menù dei servizi",
    "learn_more": "Scopri di più",
    "no_service_selected": "Nessun servizio selezionato",
    "create_service_calendars": "Crea calendari di servizio",
    "alert_description": "Per creare un menu di servizio, è necessario creare calendari di servizio e aggiungerli ai gruppi",
    "dont_show_again": "Non mostrare di nuovo",
    "service_name_required": "È richiesto il nome del menu di servizio",
    "slug_already_taken": "Questa lumaca è già occupata",
    "slug_required": "Slug è richiesto",
    "please_enter_name": "Si prega di inserire un nome",
    "service_name_placeholder": "Menu principale",
    "consent_label": "casella di consensox",
    "consent_input_placeholder": "Aggiungi testo di consenso",
    "consent_tooltip": "Attiva questa opzione per ottenere il consenso dai contatti quando forniscono il loro numero di telefono durante il processo di prenotazione degli appuntamenti"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personalizza widget calendario",
    "works_with_neo_widget": "Funziona solo con il widget Neo",
    "customise_widget_desc": "Personalizza l'aspetto del widget: colore primario, colore di sfondo e testo del pulsante",
    "learn_more": "Scopri di più",
    "primary_settings": "Impostazioni principali",
    "primary_settings_desc": "Configura il colore principale, il colore di sfondo e altre preferenze del widget per personalizzare il tuo widget del calendario",
    "primary_color": "Colore primario",
    "primary_color_desc": "Definisci il colore per i pulsanti, le date, gli slot di tempo e le azioni",
    "background_color": "Colore di sfondo",
    "background_color_desc": "Imposta il colore di sfondo che funge da sfondo sia per il selettore data-ora che per il modulo",
    "button_text": "Testo del pulsante",
    "button_text_desc": "Personalizza il testo visualizzato sul pulsante durante la prenotazione di un appuntamento",
    "calendar_title": "Titolo del calendario",
    "calendar_title_desc": "Il nome del tuo calendario",
    "calendar_description": "Descrizione del calendario",
    "calendar_description_desc": "La descrizione inserita",
    "calendar_details": "Dettagli del calendario",
    "calendar_details_desc": "Include informazioni come durata, data e ora, dettagli ricorrenti e fuso orario",
    "reset_to_default": "Ripristina predefiniti",
    "preview_widget": "Widget di anteprima"
  }
}
