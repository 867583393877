<script setup lang="ts">
import { CalendarService } from '@/class/services/CalendarService'
import Location from '@/models/location'
import User from '@/models/user'
import AppState, { fetchLocationDetails, fetchUserDetails } from '@/states/app'
import {
  UITabs,
  UITabPane,
  UIContentWrap,
  UIForm,
  UISelect,
  UIFormItem,
  UIInput,
  UISpin,
  UIButton,
} from '@gohighlevel/ghl-ui'
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const customMeetingLocationTypes = ['address', 'phone', 'custom']
const meetingLocation = {
  address: '{{location.full_address}}',
  phone: '{{location.phone}}',
  custom: '',
}
const formValue = ref({
  meetingLocationType: 'custom',
  meetingLocation: '',
  tempMeetingLocation: ''
})

const isSaving = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const meetingLocationTypes = ref([
  {
    label: t('appointment_modal.custom'),
    value: 'custom',
    key: 'custom',
  },
  {
    label: 'Zoom',
    value: 'true',
    key: 'zoom',
  },
  {
    label: 'Google Meet',
    value: 'meet',
    key: 'meet',
  },
  {
    label: 'Phone',
    value: 'phone',
    key: 'phone',
  },
  {
    label: 'Full Address',
    value: 'address',
    key: 'address',
  },
])

const loadData = async () => {
  if (!AppState.user?.id || !AppState.locationId) {
    return
  }

  isLoading.value = true
  await fetchUserDetails(AppState.user?.id)
  isLoading.value = false

  const {
    data: { choices },
  } = await CalendarService.getAvailableMeetingLocationChoices(
    AppState.locationId
  )

  meetingLocationTypes.value = meetingLocationTypes.value.map(x => {
    return {
      label: x.label,
      value: x.value,
      key: x.key,
      disabled: customMeetingLocationTypes.includes(x.key)
        ? false
        : !choices[x.key],
    }
  })
}

const changeMeetingLocation = value => {
  if (value === 'custom') {
    formValue.value.meetingLocation = formValue.value.tempMeetingLocation
  } else if (customMeetingLocationTypes.includes(value)) {
    formValue.value.meetingLocation = meetingLocation[value]
  }
}

onMounted(async () => {
  await loadData()
})

watch(
  () => AppState.user,
  async () => {
    await loadData()
  }
)

watch(
  () => AppState.locationId,
  async () => {
    await loadData()
  }
)

const userDetail = computed(() => {
  return AppState?.loggedInUserDetail as User
})

watch(
  () => userDetail.value,
  () => {
    const locationWiseMeetingLocation =
      userDetail.value?.locationWiseMeetingLocation
    const locationWiseZoomAdded = userDetail.value?.locationWiseZoomAdded

    formValue.value.meetingLocation = Object.keys(locationWiseMeetingLocation)
      .length
      ? locationWiseMeetingLocation[AppState?.locationId]
      : ''

    const meetingLocationType = Object.keys(locationWiseZoomAdded).length
      ? String(locationWiseZoomAdded[AppState?.locationId])
      : 'false'

    formValue.value.meetingLocationType =
      meetingLocationType === 'false' ? 'custom' : meetingLocationType

    if (meetingLocationType === 'false') {
      formValue.value.tempMeetingLocation = formValue.value.meetingLocation
    }
  }
)

const savePreferences = async () => {
  isSaving.value = true
  const { data } = await axios.put(`/users/${AppState.user?.id}`, {
    locationWiseZoomAdded: {
      ...userDetail.value?.locationWiseZoomAdded,
      [AppState.locationId]:
        formValue.value.meetingLocationType === 'custom'
          ? 'false'
          : formValue.value.meetingLocationType,
    },
    locationWiseMeetingLocation: {
      ...userDetail.value?.locationWiseMeetingLocation,
      [AppState.locationId]: formValue.value.meetingLocation,
    },
  })

  await CalendarService.applyDefaultMeetingLocation(AppState.locationId)

  isSaving.value = false
  // toast('Changes Saved!')
}
</script>
<template>
  <div class="max-w-4xl">
    <div class="flex flex-col">
      <div class="mb-4 space-y-1">
        <div class="text-lg font-medium text-gray-900">
          {{ $t('settings.preferences.user_preferences') }}
        </div>
        <div class="text-sm text-gray-500">
          {{ $t('settings.preferences.set_preferences') }}
        </div>
      </div>
      <div
        class="grid grid-cols-3 items-baseline space-x-20 border-t-2 border-b-2 border-gray-200 py-6"
      >
        <div class="">
          {{ $t('settings.preferences.scheduling_options') }}
        </div>
        <div class="flex flex-col">
          <UISpin v-if="isLoading" :show="true"> </UISpin>
          <UIForm v-else id="" style="width: 400px">
            <UIFormItem :label="$t('settings.preferences.meeting_location')">
              <UISelect
                id=""
                v-model:value="formValue.meetingLocationType"
                :options="meetingLocationTypes"
                @update:value="value => changeMeetingLocation(value)"
              >
              </UISelect>
            </UIFormItem>

            <UIFormItem
              v-if="
                customMeetingLocationTypes.includes(
                  formValue.meetingLocationType
                )
              "
              :label="$t('settings.preferences.custom_meeting_location')"
            >
              <UIInput
                id="appointment-title-input"
                v-model="formValue.meetingLocation"
                :placeholder="$t('settings.preferences.meeting_location')"
              />
            </UIFormItem>
          </UIForm>
        </div>
      </div>
    </div>

    <UIButton
      id=""
      class="float-right mt-6 mb-2"
      :loading="isSaving"
      type="primary"
      @click="savePreferences"
    >
      {{ $t('settings.preferences.save_preferences') }}
    </UIButton>
  </div>
</template>
