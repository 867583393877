{
  "settings": {
    "calendar": {
      "calendar_settings": "Paramètre",
      "group": "Groupe",
      "manage_your_calendars_and_groups": "Gérer vos calendriers et vos groupes",
      "groups": "Groupes",
      "create_group": "Créer un groupe",
      "create_calendar": "Créer un calendrier",
      "calendar_name": "Nom du calendrier",
      "group_duration": "Durée du groupe",
      "calendar_type": "Type de calendrier",
      "action": "Action",
      "round_robin": "Rotation",
      "event": "événement",
      "group_name": "Nom du groupe",
      "calendars": "Calendriers",
      "date_updated": "Date de mise à jour",
      "status": "Statut",
      "action_dropdown": "Menu déroulant d'action",
      "edit": "Modifier",
      "duplicate": "Dupliquer",
      "copy_embed_code": "Copier le code d'intégration",
      "copy_permanent_link": "Copier le lien permanent",
      "copy_new_slug_link": "Copier le lien de planification",
      "copy_scheduling_link": "Copier le lien de planification (obsolète)",
      "deactivate_calendar": "Désactiver le calendrier",
      "move_to_group": "Déplacer vers le groupe",
      "delete_calendar": "Supprimer le calendrier",
      "delete_group": "Supprimer",
      "delete_this_group": "Supprimer ce groupe",
      "activate_calendar": "Activer le calendrier",
      "duration": "Durée (min)",
      "all": "Tous",
      "draft": "Brouillon",
      "active": "Actif",
      "rearrange_calendars": "Réorganiser les calendriers",
      "deactivate_all_calendars_in_group": "Désactiver tous les calendriers du groupe",
      "activate_all_calendars_in_group": "Activer tous les calendriers du groupe",
      "no_groups_found": "Votre recherche n'a donné aucun résultat pour les groupes de calendrier.",
      "no_groups_in_location": "Commencez à utiliser le groupe en créant un nouveau groupe de calendrier.",
      "no_calendars_found": "Commencez à utiliser le calendrier en créant un nouveau calendrier.",
      "no_calendars_found_search": "Votre recherche n'a donné aucun résultat pour les calendriers.",
      "delete_calendar_message": "Vous êtes sur le point de supprimer le calendrier. Lorsque vous appuyez sur \"supprimer\", cela supprimera également tous ses rendez-vous.",
      "delete_calendar_message_title": "Supprimer ce calendrier ?",
      "no_calendars_found_title": "Aucun calendrier trouvé",
      "no_groups_in_location_title": "Aucun groupe trouvé",
      "delete_group_confirmation_title": "Supprimer ce groupe ?",
      "delete_group_confirmation": "Êtes-vous sûr de vouloir supprimer le groupe sélectionné ?",
      "delete_group_confirmation_checkbox_title": "Supprimer les calendriers et rendez-vous associés",
      "delete_group_confirmation_checkbox_description": "Sélectionner cette option supprimera également les calendriers de ce groupe, ainsi que tous les rendez-vous dans ces calendriers",
      "group_status_message_title": "{status} ce groupe ?",
      "calendar_status_message_title": "{status} ce calendrier ?",
      "calendar_status_message": "Vous êtes sur le point de {status} le calendrier",
      "group_status_message": "Vous êtes sur le point de {status} le groupe",
      "select_calendar_type": "Sélectionner le type de calendrier",
      "add_new_calendar_group": "Ajouter un nouveau groupe de calendriers",
      "new_calendar": "Nouveau calendrier",
      "calendar_group_description": "Ajouter des groupes de calendriers pour regrouper plusieurs calendriers.",
      "group_description": "Description du groupe",
      "calendar_url": "URL du calendrier",
      "coming_soon": "À venir",
      "select": "Sélectionner",
      "15mins": "15mins",
      "cancel": "Annuler",
      "save": "Enregistrer",
      "create": "Créer",
      "select_calendar_group": "Sélectionner le groupe de calendrier",
      "move_calendar_confirmation": "Veuillez noter que le lien hérité cessera de fonctionner une fois que vous aurez procédé au changement. Veuillez confirmer en sélectionnant la case à cocher.",
      "new_service_menu": "Nouveau menu de services",
      "service_menus": "Menu du services",
      "manage_your_calendars_groups_and_service_menus": "Gérez vos calendriers, groupes et menu de services",
      "deactivate_service_menu": "Désactiver le menu de service",
      "delete_service_menu": "Supprimer ce menu de service",
      "delete_service_menu_confirmation": "Êtes-vous certain(e) de vouloir supprimer ce menu de services ?",
      "service_menu_status_message_title": "{status} ce menu de services?",
      "service_menu_status_message": "Vous êtes sur le point de {statut} Menu des Services",
      "no_service_menus_in_location_title": "Créez votre menu de services personnalisé",
      "add_new_service_menu": "Ajouter un nouveau menu de service",
      "no_service_menus_in_location": "Commencez par créer un nouveau menu de services et y ajouter des groupes",
      "no_service_menu_found": "Aucun menu de service trouvé",
      "no_service_menu_found_desc": "Votre recherche ne correspond à aucun menu de service",
      "rooms": "Pièces",
      "equipments": "Équipements",
      "share_otl": "Partagez votre disponibilité à chaque fois avec un lien unique qui expire après une réservation, assurant un accès contrôlé",
      "generate_link": "Générer un nouveau lien",
      "share_schedule_link": "Le lien de planification est déterminé par le slug. Modifiez le slug et le lien de planification s'adapte automatiquement à la modification",
      "share_permanent_link": "Idéal pour entonnoirs, redirections de site web ou publicités, le lien permanent reste constant, sans être affecté par les modifications de slug",
      "copy": "Copie",
      "share_embed_code": "Placez ce code dans votre HTML à l'endroit où vous voulez que votre widget {embedType} apparaisse",
      "scheduling_link": "Lien d'ordonnancement",
      "permanent_link": "Lien permanent",
      "one_time_link": "Lien unique",
      "embed_code": "Code intégré",
      "loading": "Chargement en cours...",
      "round_robin_booking": "Tournoi toutes rondes",
      "round_robin_description": "Répartit les rendez-vous entre les membres de l'équipe de manière rotative",
      "round_robin_example": "Par exemple : Appels de vente, sessions d'intégration",
      "unassigned_booking": "Calendrier d'événements",
      "unassigned_description": "Pour planifier des événements physiques sans association hôte",
      "unassigned_example": "Par exemple : Conférences, séminaires publics, expositions",
      "class_booking": "Réservation de classe",
      "class_booking_description": "Un animateur rencontre plusieurs participants",
      "class_booking_example": "Par exemple: Webinaires, formation de groupe, cours en ligne",
      "collective_booking": "Réservation collective",
      "collective_booking_description": "Plusieurs hôtes rencontrent un participant",
      "collective_booking_example": "Par exemple : entrevues en groupe, évaluations en comité",
      "service_booking": "Réservation de services",
      "service_booking_description": "Planification sans couture pour les entreprises de services",
      "service_booking_example": "Par exemple : rendez-vous au spa, services de réparation, consultations",
      "personal_booking": "Réservation personnelle",
      "personal_booking_description": "Planifie des réunions individuelles avec un membre spécifique de l'équipe",
      "personal_booking_example": "Par exemple : réunions avec les clients, consultations privées",
      "personal": "Personnel",
      "explore_more_types": "Explorer plus de types",
      "scheduling_type": "Type de planification",
      "scheduling_type_description": "Choisissez un type de planification pour votre nouveau calendrier"
    },
    "preferences": {
      "preferences": "Préférences",
      "my_preferences": "Mes préférences",
      "account_preferences": "Préférences du compte",
      "user_preferences": "Préférences de l'utilisateur",
      "set_preferences": "Définissez vos préférences pour votre compte.",
      "scheduling_options": "Options de planification",
      "meeting_location": "Lieu de la réunion",
      "zoom": "Zoom",
      "save_preferences": "Enregistrer les préférences",
      "in_app_preferences": "Préférences de l'application",
      "set_start_day": "Définir les préférences telles que le jour de départ pour l'application",
      "view_options": "Options d'affichage",
      "week_starts_on": "Le jour de départ de la semaine",
      "sunday": "Dimanche",
      "widget_preferences": "Préférences du widget",
      "set_widget_language": "Définir les préférences telles que la langue pour le widget",
      "language_and_region": "Langue et région",
      "language": "Langue",
      "english": "Anglais",
      "time_format": "Format de l'heure",
      "time_format_value": "13:30",
      "monday": "Lundi",
      "custom_meeting_location": "Emplacement personnalisé de la réunion"
    },
    "availability": {
      "availability": "Disponibilité",
      "my_availability": "Ma disponibilité",
      "account_availability": "Disponibilité du compte",
      "timezone": "Fuseau horaire",
      "working_hours": "Heures de travail",
      "set_recurring_hours": "Définir vos heures récurrentes de travail pour les réunions",
      "sunday": "Dimanche",
      "monday": "Lundi",
      "tuesday": "Mardi",
      "wednesday": "Mercredi",
      "thursday": "Jeudi",
      "friday": "Vendredi",
      "saturday": "Samedi",
      "widget_preferences": "Préférences du widget",
      "set_widget_language": "Définir les préférences telles que la langue pour le widget",
      "language_and_region": "Langue et région",
      "language": "Langue",
      "english": "Anglais",
      "copy_to_all": "Copier pour tous",
      "save_availability": "Enregistrer la disponibilité",
      "add_time": "Ajouter du temps",
      "select_time": "Sélectionner l'heure"
    },
    "connections": {
      "connections": "Connexions",
      "conferencing": "Vidéoconférence",
      "my_connections": "Mes connexions",
      "account_connections": "Connexions du compte",
      "connection_preferences": "Préférences de connexion",
      "manage_calendars_conferencing_channels": "Gérer les calendriers et les canaux de vidéoconférence",
      "main_integration_calendar": "Calendrier d'intégration principal",
      "event_integration_calendar": "Les événements créés sur un calendrier système dont vous faites partie seront également créés sur ce calendrier intégré",
      "2way_sync": "Synchronisation bidirectionnelle : Créer des événements système à partir des événements réservés sur le calendrier principal intégré",
      "calendars": "Calendriers",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Vérifier les conflits",
      "choose_accounts_to_connect": "Choisissez les comptes que vous souhaitez connecter",
      "payments": "Paiements",
      "stripe": "Stripe",
      "ads": "Publicités",
      "local_service_ads": "Publicités de service local",
      "fire_workflow": "Activer des déclencheurs de flux de travail pour les événements réservés via une synchronisation bidirectionnelle",
      "connect_google_outlook": "Veuillez connecter votre compte Google ou Outlook afin de pouvoir sélectionner le calendrier principal.",
      "execute_automation": "Exécuter l'automatisation",
      "save": "Enregistrer",
      "lsa": "Publicités de services locaux",
      "connect": "Connecter",
      "no_write_access": "Vous n'avez pas les droits d'écriture sur ce calendrier",
      "connection_remove_confirmation": "Vous êtes sur le point de supprimer cette intégration",
      "active": "Actif"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Sélectionner un contact",
    "start_time": "Heure de début",
    "unassigned": "Non attribué",
    "end_time": "Heure de fin",
    "search_by_name_email_phone_or_company": "Rechercher par nom, courriel, téléphone ou entreprise",
    "add_new": "Ajouter un nouveau",
    "pick_from_available_contacts": "Ou choisir parmi les contacts disponibles",
    "book_appointment": "Prendre un rendez-vous",
    "calendar": "Calendrier",
    "repeat": "Répéter",
    "date_and_time": "Date et heure",
    "close": "Fermer",
    "team_member": "Membre de l'équipe",
    "recurring_booking": "Réservation récurrente",
    "one_slot_booking": "Réservation d'un créneau horaire",
    "event_type": "Type d'événement",
    "occurrences_booked": "les occurrences seront réservées",
    "repeats": "Répétitions",
    "booking_details": "Détails de la réservation",
    "showing_slots_in_this_timezone": "Affichage des créneaux horaires dans ce fuseau horaire :",
    "update_contact_tz": "Mettre à jour le fuseau horaire du contact à",
    "date": "Date",
    "slot": "Créneau horaire",
    "calendar_default": "Calendrier par défaut",
    "custom": "Personnalisé",
    "meeting_notes": "(Ex) Notes de réunion pour notre prochain rendez-vous",
    "out_of_office": "(Ex) Absent pour la semaine !",
    "standard": "Défaut",
    "appointment_title": "Titre du rendez-vous",
    "event_title": "Titre de l'événement",
    "appt_with_bob": "(Ex) Rendez-vous avec Bob",
    "vacation": "(Ex) Vacances",
    "show_notes": "Afficher les notes",
    "hide_notes": "Masquer les notes",
    "additional_preferences": "Préférences supplémentaires",
    "meeting_location": "Lieu de la réunion",
    "appointment_status": "Statut du rendez-vous",
    "appt_description": "Description du rendez-vous",
    "recurring_details": "Détails de la récurrence",
    "event_description": "Description de l'événement",
    "in_contact_timezone": "Dans le fuseau horaire du contact",
    "vacation_message": "Partez-vous en vacances ? Prenez-vous un congé ? Bloquez du temps sur votre calendrier pour empêcher les clients de prendre des rendez-vous (les rendez-vous existants resteront sur votre calendrier).",
    "contact": "Contact",
    "delete": "Supprimer",
    "cancel": "Annuler",
    "back": "Retour",
    "pick_available_contacts": "Ou choisir parmi les contacts disponibles",
    "no_contacts_found": "Aucun contact trouvé",
    "found": "trouvé",
    "select_one": "Sélectionner un",
    "tz_tooltip": "Ceci n'a d'importance que si vous utilisez des valeurs personnalisées telles que appointment_start_time, appointment_end_time, appointment_timezone, etc. dans votre communication sortante avec vos clients ou vos clients. Et ce que cela fait simplement, c'est convertir les détails susmentionnés en heure locale.",
    "meeting_location_tooltip": "Le lieu de la réunion sera défini comme configuré dans le calendrier.",
    "appt_meeting_location_tooltip": "L'utilisateur peut définir un lieu de rendez-vous spécifique au rendez-vous.",
    "gmeet_tooltip": "Veuillez vous assurer que le membre de l'équipe concerné dispose d'un calendrier Google défini comme calendrier principal.",
    "zoom_tooltip": "Veuillez vous assurer que le membre de l'équipe concerné a intégré Zoom.",
    "enter_meeting_location": "Entrez le lieu de la réunion",
    "save_appointment": "Enregistrer le rendez-vous",
    "save_event": "Enregistrer l'événement",
    "search_contacts": "Rechercher par nom, courriel, téléphone ou entreprise",
    "recurring_slots": "{recurringSlotsLength}/{count}",
    "add_blocked_off_time": "Ajouter un temps bloqué",
    "recommended_timezones": "Fuseaux horaires recommandés",
    "all_timezones": "Tous les fuseaux horaires",
    "custom_timezone": "Fuseau horaire personnalisé",
    "system_timezone": "Fuseau horaire du système",
    "contact_timezone": "Fuseau horaire du contact",
    "account_timezone": "Fuseau horaire du compte",
    "an_error_occurred": "Une erreur s'est produite"
  },
  "popup": {
    "calendar": "Calendrier",
    "appointment_owner": "Propriétaire du rendez-vous",
    "source": "Source",
    "booked_by": "Réservé par",
    "view_activity_log": "Afficher le journal des activités",
    "status": "Statut",
    "cancel_appointment": "Annuler le rendez-vous",
    "google_event": "Événement Google",
    "outlook_event": "Événement Outlook",
    "blocked_slot": "Créneau bloqué",
    "appointment": "Rendez-vous",
    "confirmed": "Confirmé",
    "unconfirmed": "Non confirmé",
    "cancelled": "Annulé",
    "showed": "Présenté",
    "no_show": "Absent",
    "invalid": "Non valide",
    "cancel_all": "Tout annuler",
    "more": "Plus",
    "less": "Moins",
    "edit": "Modifier",
    "reschedule_all": "Tout reporter",
    "guests": "Invités",
    "guest_count": "Le nombre d'invités"
  },
  "activity_log": {
    "activity_log": "Journal d'activité",
    "date": "Date",
    "filter_by": "Filtrer par",
    "calendar_update": "Mise à jour du calendrier",
    "start_time_update": "Mise à jour de l'heure de début",
    "end_time_update": "Mise à jour de l'heure de fin",
    "title_update": "Mise à jour du titre",
    "notes_update": "Mise à jour des notes",
    "appointment_status_update": "Mise à jour du statut du rendez-vous",
    "meeting_location_update": "Mise à jour de l'emplacement de la réunion",
    "google_sync_update": "Mise à jour de la synchronisation avec Google",
    "outlook_sync_update": "Mise à jour de la synchronisation avec Outlook",
    "assigned_user_update": "Mise à jour de l'utilisateur assigné",
    "appointment_status": "Statut du rendez-vous",
    "assigned_user": "Utilisateur assigné",
    "uploaded_to": "Téléchargé sur",
    "cancelled": "Annulé",
    "edited": "modifié",
    "other_edits": "Autres modifications",
    "view_details": "Voir les détails",
    "all": "Tout"
  },
  "appointment_list": {
    "group_all": "TOUT",
    "group_primary": "PRIMAIRE",
    "group_user": "UTILISATEURS",
    "group_team": "GROUPES",
    "group_calendar": "CALENDRIERS",
    "all_appointments": "Tous les rendez-vous",
    "my_calendar": "Mon calendrier",
    "status_all": "Tout",
    "status_confirmed": "Confirmé",
    "status_new": "Nouveau (Action requise)",
    "status_show": "Montré",
    "status_no_show": "absent",
    "status_cancelled": "Annulé",
    "status_invalid": "Invalide",
    "filter_date_added_desc": "Date d'ajout (desc)",
    "filter_start_added_desc": "Date de début (Desc)",
    "filter_start_added_asc": "Date de début croissante à partir d'aujourd'hui.",
    "button_new_appointment": "Nouvelle nomination",
    "name": "Nom",
    "title": "Titre",
    "requested_time": "Temps demandé",
    "date_added": "Date d'ajout",
    "calendar": "Calendrier",
    "appointment_owner": "Propriétaire de rendez-vous",
    "notes": "Notes",
    "status": "Statut",
    "action_view_detail": "Voir les détails",
    "action_view_notes": "Afficher les notes",
    "action_edit": "Modifier",
    "action_delete": "Supprimer",
    "action_reschedule": "Reporter",
    "page_previous": "Précédent",
    "page_next": "Suivant",
    "appointment_notes": "Notes de rendez-vous",
    "close": "Fermer",
    "confirm_delete_header": "Supprimer le rendez-vous",
    "confirm_delete_message": "Êtes-vous sûr(e) de vouloir supprimer ce rendez-vous ?",
    "confirm_delete_button": "Supprimer",
    "confirm_update_header": "Mise à jour du statut du rendez-vous",
    "confirm_update_confirmed_message": "Êtes-vous sûr de vouloir marquer ce rendez-vous comme confirmé?",
    "confirm_update_invalid_message": "Êtes-vous sûr(e) de vouloir marquer ce rendez-vous comme invalide ?",
    "confirm_update_showed_message": "Êtes-vous sûr(e) que vous voulez marquer cette rencontre comme étant présente ?",
    "confirm_update_noshow_message": "Êtes-vous certain(e) de vouloir marquer cette rencontre comme un rendez-vous manqué ?",
    "confirm_update_cancelled_message": "Êtes-vous certain(e) de vouloir marquer ce rendez-vous comme annulé ?",
    "confirm_update_button": "Mise à jour",
    "empty_state_title": "Aucun rendez-vous trouvé",
    "empty_state_description": "Aucun rendez-vous trouvé",
    "disable_menu_warning": "Présentement, vous n'avez pas la permission de voir ou de modifier les détails du rendez-vous, car le contact associé ne vous est pas attribué. Veuillez contacter votre administrateur pour obtenir de l'aide supplémentaire",
    "no_contact_warning": "Vous ne pouvez pas accéder aux coordonnées de contact pour le moment. Cela peut être dû au fait que le contact ne vous est pas assigné. Veuillez contacter votre administrateur pour obtenir de l'aide",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Reprogrammé"
  },
  "create_calendar": {
    "calendar_name": "Nom du calendrier",
    "calendar_logo": "Logo du calendrier",
    "select_team_member": "Sélectionner les membres de l'équipe",
    "eg_outbound_reach": "Portée sortante",
    "description": "Description",
    "amount": "Montant",
    "description_placeholder": "Écrire la description",
    "meeting_duration": "Durée de la réunion",
    "seats_per_class": "Nombre de places par classe",
    "group": "Groupe",
    "custom_url": "URL personnalisée",
    "meeting_invite_title": "Titre de l'invitation à la réunion",
    "event_color": "Couleur de l'événement",
    "availability_type": "Type de disponibilité",
    "deposit_amount": "Montant du dépôt",
    "collect_deposit_amount": "Accepter un paiement partiel",
    "percentage": "Pourcentage",
    "total_amount": "Montant total",
    "reschedule_preference": "Préférence de reprogrammation",
    "reschedule_preference_subtext": "Si un contact reporte un rendez-vous:",
    "reassign_through_round_robin": "Réaffecter par le biais de la rotation des affectations",
    "keep_same_assinged_user": "Gardez le même propriétaire de rendez-vous",
    "new_appointment_preference": "Nouvelle préférence de rendez-vous",
    "new_appointment_preference_text": "Réservez toujours avec l'utilisateur assigné au contact",
    "new_appointment_preference_subtext": "Réservez de nouveaux rendez-vous avec l'utilisateur assigné au contact au lieu d'utiliser la répartition en rond"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Astuce rapide",
      "timezone_change_tip": "Les fuseaux horaires peuvent être changés à tout moment dans les préférences des fuseaux horaires",
      "save": "Enregistrer",
      "close": "Fermer",
      "meeting_details": "Détails de la réunion",
      "availability": "Disponibilité",
      "forms_and_payment": "Formulaires et paiement",
      "notifications_and_additional_options": "Notifications et options supplémentaires",
      "connections": "Liens",
      "customizations": "Personnalisations",
      "create": "Créer",
      "edit": "Modifier"
    },
    "meeting_details": {
      "meeting_location": "Lieu de réunion",
      "enter_meeting_location": "Entrer le lieu de la réunion",
      "click_to_upload": "Cliquez pour télécharger",
      "square": "Carré",
      "circle": "Cercle",
      "remove_logo": "Supprimer le logo",
      "meeting_invite_title_tooltip": "Il s'agit du titre de l'événement du calendrier qui s'affiche dans Google Calendar, Outlook, Apple Calendar, etc",
      "event_color_tooltip": "Choisissez une couleur pour cet événement de réservation. Les couleurs sont synchronisées aux événements dans Google Agenda",
      "enter_a_name": "Veuillez entrer un nom",
      "enter_an_event_title": "Veuillez entrer un titre d'événement",
      "high_priority": "Haute priorité",
      "medium_priority": "Moyenne priorité",
      "low_priority": "Faible priorité",
      "custom": "Personnaliser",
      "zoom_not_added": "L'utilisateur n'a pas encore connecté son compte Zoom",
      "opt_avail": "Optimiser pour la disponibilité",
      "opt_equal": "Optimiser pour une distribution équitable",
      "team_members": "Sélectionnez les membres de l'équipe",
      "team_member": "Sélectionnez un membre de l'équipe",
      "meeting_location_required": "L'emplacement de la réunion est requis",
      "team_member_disabled_recurring_tooltip": "Les réunions récurrentes ne permettent qu'à un membre de l'équipe. Pour ajouter plusieurs membres de l'équipe, veuillez désactiver les réunions récurrentes",
      "team_member_disabled_class_booking_tooltip": "Seul un membre de l'équipe peut être ajouté au calendrier de réservation de classe",
      "enable_contact_assigned_to_setting": "Pour activer ce paramètre, le formulaire doit être le premier dans l'ordre des widgets. Veuillez réarranger l'ordre des widgets depuis l'onglet Formulaires & Paiement",
      "reschedule_preference_setting_tooltip": "Sélectionnez à qui le rendez-vous devrait être attribué lorsque qu'un contact reporte sa réservation à partir du widget de réservation",
      "appointment_preference_tooltip": "Choisissez si les nouveaux rendez-vous doivent toujours être réservés avec l'utilisateur assigné au contact. Si un contact a un utilisateur assigné, cet utilisateur sera privilégié; sinon, il s'agira d'une affectation en tourniquet. Conseil : Désactivez la fonction Autoriser la sélection du personnel pour empêcher les réservants de modifier le membre du personnel sur le widget de réservation",
      "add_location": "Ajouter l'emplacement",
      "multiple_locations_neo": "Les emplacements de réunion multiples ne sont pris en charge que par le widget Neo avec le formulaire par défaut",
      "make_owner": "Rendre propriétaire",
      "only_one_team_member": "Seul un membre de l'équipe est autorisé lorsque plusieurs lieux sont ajoutés",
      "only_one_team_member_personal_calendar": "Seul un membre de l'équipe est autorisé pour une réservation personnelle"
    },
    "availability": {
      "my_availability": "Ma disponibilité",
      "set_availability": "Définissez votre disponibilité pour le calendrier ici",
      "standard": "Norme",
      "custom": "Coutume",
      "to": "Au",
      "copy_to_all": "Copier à tous",
      "add_time": "Ajouter du temps",
      "recurring_meeting": "Réunion récurrente",
      "recurring_info": "Un seul utilisateur par calendrier pour réservation récurrente",
      "repeat": "Répéter",
      "times_to_repeat": "Fois à répéter",
      "slots_unavailable": "Si les fentes ne sont pas disponibles?",
      "overlap_status": "Statut des rendez-vous pour les créneaux horaires qui se chevauchent",
      "meeting_interval": "Intervalle de réunion",
      "meeting_duration": "Durée de la réunion",
      "minimum_scheduling": "Préavis de programmation minimal",
      "date_range": "Plage de dates",
      "maximum_bookings_per_day": "Réservations maximales par jour",
      "maximum_bookings_per_slot": "Nombre maximum de réservations par créneau",
      "maximum_bookings_per_slot_per_user": "Nombre maximal de réservations par créneau (par utilisateur)",
      "seats_per_class": "Places par classe",
      "pre_buffer_time": "Temps de pré-mémoire.",
      "post_buffer_time": "Temps tampon avant la publication",
      "pre_buffer_tooltip": "Le \"Pré-Buffer\" est un temps supplémentaire pouvant être ajouté avant un rendez-vous, permettant d'avoir plus de temps pour se préparer avant toute réunion",
      "post_buffer_tooltip": "Le post-buffer est le temps supplémentaire qui peut être ajouté après un rendez-vous, permettant d'avoir plus de temps pour finaliser",
      "buffer_time": "Temps tampon",
      "daily": "quotidien",
      "weekly": "Hebdomadaire",
      "monthly": "Mensuel",
      "skip_booking": "Ignorer la réservation des créneaux non disponibles",
      "continue_booking": "Continuer la réservation",
      "book_next_available": "Réservez le prochain créneau disponible",
      "confirmed": "Confirmé",
      "unconfirmed": "Non confirmé",
      "enter_valid_hours": "Veuillez entrer des heures d'ouverture valides, s'il vous plaît",
      "enter_valid_avail": "Veuillez entrer une disponibilité personnalisée valide s'il vous plaît",
      "availability_type_tooltip": "Choisissez la disponibilité standard pour les réservations régulières offertes chaque semaine. Choisissez personnalisé pour les réservations disponibles uniquement à des dates spécifiques",
      "meeting_interval_tooltip": "L'intervalle de réunion reflète la durée entre les créneaux de réservation qui seront affichés dans le calendrier. Pour un événement de 30 minutes qui doit être disponible en début de chaque heure, l'intervalle serait d'1 heure. Pour un événement de 30 minutes qui doit être disponible toutes les 30 minutes, l'intervalle serait de 30 minutes",
      "date_range_tooltip": "Les événements peuvent être planifiés au cours des n prochains jours",
      "maximum_bookings_per_day_tooltip": "Nombre maximal de rendez-vous permis par jour sur ce calendrier",
      "maximum_bookings_per_slot_tooltip": "Nombre maximal de rendez-vous autorisés par créneau pour l'utilisateur (Si le nombre de rendez-vous dépasse le créneau donné pour l'utilisateur, alors cela passe soit au prochain utilisateur dans la file d'attente en rotation, soit marque le créneau comme non disponible pour le prospect à réserver)",
      "add_days": "Ajouter des jours",
      "month_on_day": "Mensuellement le jour",
      "month_on_last_day": "Mensuel le dernier jour du mois",
      "day": "Jour",
      "week": "semaine",
      "month": "Mois",
      "mins": "Minutes",
      "hours": "Heures",
      "days": "Jours",
      "weeks": "semaines",
      "months": "Mois",
      "meeting_interval_error": "L'intervalle entre les réunions doit être compris entre 5 minutes et 12 heures",
      "meeting_duration_error": "La durée de la réunion doit être comprise entre 1 minute et 12 heures",
      "buffer_time_error": "Le temps tampon doit être inférieur à 12 heures",
      "subTitle": "Choisissez la date pour fixer des heures spécifiques",
      "heading_1": "Heures disponibles hebdomadaires",
      "tooltip_1": "Définissez votre calendrier de disponibilité hebdomadaire. Ce sont vos heures normales qui seront appliquées de manière constante chaque semaine",
      "heading_2": "Quand es-tu disponible?",
      "unavailable_label": "Non disponible",
      "tooltip_2": "Modifiez votre emploi du temps pour des dates spécifiques. Remplacez vos heures hebdomadaires habituelles en indiquant votre disponibilité ou votre indisponibilité pour les dates choisies",
      "date_specific_level": "Ajouter des heures spécifiques à la date",
      "specific_hours_button": "Heures spécifiques pour date précise",
      "overlap_error": "Les créneaux horaires ne peuvent pas se chevaucher",
      "apply": "Appliquer",
      "copy_times_to": "Copier les fois",
      "no_date_specific_heading": "Aucune heure et date spécifiques ajoutées",
      "no_date_specific_description": "Vous pouvez ajouter / supprimer une date et une heure spécifiques dans vos disponibilités",
      "recurring_disabled_conditions_tooltip": "Les réunions récurrentes ne permettent qu'à un membre de l'équipe. Pour ajouter plusieurs membres de l'équipe, veuillez désactiver les réunions récurrentes.",
      "date_specific_hours_disabled_conditions_tooltip": "Pour inclure une heure spécifique à une date, assurez-vous que les réunions récurrentes sont désactivées",
      "recurring_disable_event_calendar_conditions": "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous que le paiement est désactivé et qu'aucune heure spécifique n'est ajoutée",
      "service_interval": "Intervalle de service",
      "service_interval_tooltip": "L'intervalle de réunion détermine à quelle fréquence les créneaux de réservation apparaissent sur le calendrier. Par exemple, pour un événement de 30 minutes, un intervalle d'une heure signifie que les créneaux apparaissent toutes les heures. Un intervalle de 30 minutes signifie que les créneaux apparaissent toutes les 30 minutes",
      "service_duration_tooltip": "Cela fixe la durée de votre rendez-vous, déterminant combien de temps cela durera",
      "minimum_scheduling_tooltip": "Cela définit le délai minimum pour réserver un rendez-vous via le widget du calendrier. Par exemple, si le délai est réglé à 4 heures, il n'est pas possible de réserver un rendez-vous dans les 4 heures précédant son heure de début ; les réservations doivent être effectuées au moins 4 heures à l'avance",
      "service_duration": "Service duration",
      "look_busy_title": "Fais semblant d'être occupé",
      "look_busy_percentage_description": "Cacher le nombre de places disponibles de x%",
      "look_busy_helper_text": "Masquer un pourcentage de vos créneaux horaires disponibles sur le widget de réservation pour avoir l'air plus occupé et en forte demande",
      "maximum_bookings_per_slot_tooltip_personal": "Nombre maximum de rendez-vous autorisés par créneau pour l'utilisateur"
    },
    "forms_payment": {
      "forms": "Formulaires",
      "forms_sub": "Définir les préférences du formulaire de planification des publications.",
      "select_form": "Sélectionnez le formulaire",
      "sticky_contacts": "Pré-remplir les champs (contacts persistants)",
      "confirmation_page": "Page de confirmation",
      "default": "Défaut",
      "redirect_url": "URL de redirection",
      "thank_you": "Message de remerciement",
      "enter_url": "Entrez l'URL",
      "pixel_id": "Identifiant de pixel Facebook (facultatif)",
      "auto_confirm": "Auto-confirmer les nouvelles réunions du calendrier",
      "configure_payment": "Configurer les options de paiement",
      "accept_payments": "Accepter les paiements",
      "test": "Test",
      "live": "Vivre",
      "enable_guests": "Ajouter des invités",
      "add_guest_tooltip": "En ajoutant un invité, votre participant pourra ajouter plusieurs participants au même rendez-vous",
      "add_guest_same_form_check": "Envoyer le même formulaire par courrier pour collecter des informations auprès de tous les invités",
      "payment_tooltip": "Le paiement serait uniquement pris en compte pour le participant principal et non pour les invités",
      "default_form": "Par défaut (Prénom, Nom, Courriel, Téléphone, Notes)",
      "select_form_tooltip": "Si vous souhaitez collecter plus d'informations que le prénom, le nom de famille, l'adresse e-mail et le numéro de téléphone standard de ceux qui réservent sur ce calendrier, créez un formulaire personnalisé dans Sites > Formulaires > Constructeur, puis sélectionnez le formulaire dans le menu déroulant ci-dessous",
      "confirmation_page_tooltip": "Une fois qu'une personne fait une réservation, vous pouvez choisir de lui montrer un message personnalisé sur la même page (par défaut) ou de la rediriger vers une page de votre choix (redirection)",
      "add_guest_collect_email": "add_guest_collect_email",
      "add_guest_count_only": "Comptez seulement",
      "custom_email_notification": "Envoyer un formulaire personnalisé aux invités",
      "deposit_amount_error": "Le montant du dépôt doit être inférieur au montant total",
      "deposit_percentage_error": "Le pourcentage de dépôt doit être inférieur à 100 %",
      "partial_payment_tooltip": "Activer les paiements partiels pour collecter le montant du dépôt des clients lors du processus de réservation de rendez-vous",
      "partial_payment_enable_error": "Le montant total saisi doit être supérieur à 0 pour permettre des paiements partiels",
      "payment_disable_text_integration": "Intégrer un fournisseur de paiement dans Paiements > Intégrations pour commencer à accepter les paiements",
      "payment_disable_text_recurring": "L'option de paiement n'est pas disponible pour les réunions récurrentes. Désactivez la récurrence pour activer les paiements",
      "date_and_time_selector": "Sélecteur de date et d'heure",
      "form_selector": "Formulaire",
      "order_step": "Étape",
      "widget_order_label": "Commande de gadget",
      "widget_order_tool_tip": "Réorganisez l'ordre du 'Sélecteur de date et d'heure' et du 'Formulaire' sur le widget du calendrier. Il suffit de glisser-déposer et de réorganiser selon vos préférences",
      "same_user_assignment_tooltip": "Pour activer ce paramètre, le formulaire doit d'abord être placé en premier sur le widget. Réorganisez le widget pour positionner le formulaire en premier afin d'activer ce paramètre",
      "widget_re_order_tooltip": "La réorganisation est uniquement disponible avec le style de widget Neo. Basculez vers Neo pour activer la réorganisation",
      "widget_re_order_disabled_tooltip": "Pour réorganiser l'ordre des widgets, veuillez désactiver 'Préférence de nouveau rendez-vous (Toujours réserver avec l'utilisateur assigné au contact)' dans la section Détails de la réunion > Membres de l'équipe > Paramètres avancés",
      "enable_same_user_assignment": "Affectez les contacts à leurs membres d'équipe de calendrier respectifs à chaque fois qu'un rendez-vous est réservé"
    },
    "notifications": {
      "notifications": "Notifications et Options supplémentaires",
      "notifications_sub": "Configurer les notifications et options supplémentaires",
      "select_notification_type": "Sélectionnez le type de notification",
      "notification_person": "Qui devrait recevoir cette notification?",
      "contact": "Contact",
      "assigned_user": "Utilisateur assigné",
      "emails": "Courriels",
      "alert_email_address": "Adresse courriel d'alerte",
      "ack_email": "Courriel d'accusé de réception",
      "send_google_invites": "Permettez à Google Agenda d'envoyer des invitations ou des emails de mise à jour aux participants",
      "send_google_outlook_invites": "Autoriser Google / Outlook Calendar à envoyer des invitations et des mises à jour par courriel aux participants",
      "assign_user_to_contact": "Affecter les contacts à leurs membres d'équipe respectifs dans le calendrier chaque fois qu'un rendez-vous est réservé",
      "skip_assigning_user_to_contact": "Ignorer l'attribution du contact si le contact a déjà un utilisateur attribué",
      "email": "Email",
      "guest_label_count": "Nombre d'invités",
      "guest_count_error": "Le nombre d'invités doit être compris entre 1 et 100",
      "allow_cancellation": "Permettre l'annulation de la réunion",
      "allow_reschedule": "Permettre la reprogrammation de la réunion",
      "allow_cancellation_tooltip": "Lorsqu'activé, un lien d'annulation sera ajouté à la section des notes supplémentaires et inclus dans l'invitation au calendrier. Ce lien expirera comme configuré avant l'heure de début de la réunion, empêchant le réservateur d'annuler la réunion",
      "allow_reschedule_tooltip": "Lorsqu'il est activé, un lien de reprogrammation sera ajouté à la section des notes supplémentaires et inclus dans l'invitation au calendrier. Ce lien expirera comme configuré avant l'heure de début de la réunion, empêchant le réservateur de reprogrammer la réunion",
      "cancellation_link_expiry": "Le lien d'annulation expirera",
      "reschedule_link_expiry": "Le lien de reprogrammation expirera",
      "before_meeting": "avant la réunion",
      "cancellation_and_reschedule_policy": "Politique d'annulation et de report :"
    },
    "connections": {
      "connections_heading": "Synchronisation externe du calendrier",
      "connections_sub": "Synchroniser les événements avec un calendrier externe",
      "link_to_calendar": "Lien vers le calendrier",
      "sync_option": "Option de synchronisation",
      "execute_automation": "Exécuter l'automatisation",
      "fire_workflow": "Déclencheurs de flux de travail pour les événements réservés via une synchronisation bidirectionnelle",
      "reserve_with_google": "Réserver avec Google",
      "connect_lsa": "Connectez-vous à votre compte publicitaire de services locaux",
      "reserve_error": "Actuellement, la réservation avec Google est prise en charge uniquement pour les secteurs d'activité suivants : \"Services à domicile\", \"Immobilier\" et \"Services juridiques\" (prestataires de services locaux), aux États-Unis",
      "one_way": "Un sens unique",
      "two_way": "Deux sens",
      "smart": "Intelligent",
      "confirm_remove": "Êtes-vous certain que vous voulez supprimer le calendrier Google ?",
      "read_only_access": "Vous n'avez pas d'accès en écriture à ce calendrier. Veuillez sélectionner un autre calendrier",
      "reserve_with_google_desc": "Ce service de calendrier sera répertorié en réserve avec Google uniquement en cas d'intégration réussie",
      "check_integration": "Vérifiez votre statut d'intégration"
    },
    "extras": {
      "extras": "Personnalisations",
      "extras_sub": "Définir le style du widget et autres préférences",
      "calendar_widget_style": "Style de widget de calendrier",
      "neo": "Néo",
      "classic": "Classique",
      "insert_custom_code": "Insérer du code personnalisé",
      "show_seats_per_slot": "Montrer les places par horaire",
      "allow_reschedule_meeting": "Permettre la reprogrammation de la réunion",
      "allow_cancelling_meeting": "Permettre d'annuler la réunion",
      "additional_notes": "Notes supplémentaires",
      "cancellation_and_reschedule": "Annulation et report :",
      "allow_staff": "Autoriser la sélection du personnel lors de la réservation",
      "code_block_tooltip": "Les scripts tiers à l'intérieur du bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de passer en revue le contenu de ce script avant de l'enregistrer dans le calendrier"
    }
  },
  "add_guest": {
    "guest_label": "Invité(s)",
    "search_placeholder": "Rechercher dans les contacts",
    "add_new": "Ajouter un nouveau",
    "save": "Sauver",
    "close": "Fermé",
    "name": "Nom",
    "phone": "Téléphone",
    "email": "courriel"
  },
  "languages": {
    "english": "Anglais",
    "dutch": "Néerlandais",
    "french": "Français",
    "german": "Allemand",
    "hungarian": "Hongrois",
    "italian": "Italien",
    "polish": "Polonais",
    "portuguese_brazil": "Portugais (Brésil)",
    "portuguese_portugal": "Portugais (Portugal)",
    "spanish": "Espagnol",
    "danish": "Danois"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Détails du menu de services",
    "select_groups_header": "Sélectionner les services",
    "arrange_groups_header": "Organisez vos services",
    "new_service_menu": "Nouveau menu de services",
    "service_menu_sub_title": "Créez un menu de services personnalisé pour vos clients en quelques clics seulement",
    "quick_tip": "Vous pouvez ajouter des groupes au menu de service",
    "service_menu_name": "Nom du menu de service",
    "service_menu_description": "Description",
    "service_menu_slug": "URL de service",
    "select_groups_calendars": "Sélectionnez des groupes et des calendriers",
    "select_groups_calendars_desc": "Ajoutez des services à votre menu de services",
    "select_all": "Sélectionner tout",
    "arrange_groups": "Organiser l'ordre des services",
    "arrange_groups_desc": "Déterminez la séquence des services sur le menu de service",
    "arrange_services_quicktip": "Pour réorganiser les calendriers au sein du groupe, allez dans Groupes > Options > Réorganiser les calendriers",
    "enable_add_guest_desc": "Permet aux clients d'inclure des invités supplémentaires lors de la réservation",
    "enable_multiple_service_desc": "Permet aux clients de réserver plusieurs services lors d'un seul rendez-vous...",
    "enable_staff_selection_desc": "Permet aux clients de choisir les membres du personnel préférés lorsqu'ils effectuent une réservation",
    "enable_add_guest": "Autoriser l'ajout d'invités",
    "enable_multiple_service": "Autoriser la sélection de plusieurs services",
    "enable_staff_selection": "Activer la sélection du personnel",
    "additional_options": "Options supplémentaires",
    "code_block_tooltip": "Les scripts tiers à l'intérieur du bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la vie privée, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de passer en revue le contenu de ce script avant de l'enregistrer dans le menu de service",
    "custom_code_placeholder": "Veuillez entrer le code personnalisé ici",
    "insert_custom_code": "Insérer un code personnalisé",
    "no_service": "Aucun service trouvé",
    "no_service_desc": " Aucun service trouvé. Créez des services et ajoutez-les à des groupes pour créer un menu de services",
    "learn_more": "En savoir plus",
    "no_service_selected": "Aucun service choisi",
    "create_service_calendars": "Créer des calendriers de service",
    "alert_description": "Pour créer un menu de services, vous devez créer des calendriers de services et les ajouter à des groupes",
    "dont_show_again": "Ne pas afficher cela à nouveau",
    "service_name_required": "Le nom du menu de service est requis",
    "slug_already_taken": "Cette limace est déjà prise",
    "slug_required": "Limace est requis",
    "please_enter_name": "Veuillez entrer un nom",
    "service_name_placeholder": "Menu Principal",
    "consent_label": "Case à cocher pour consentement",
    "consent_input_placeholder": "Ajouter un texte de consentement",
    "consent_tooltip": "Activez cette option pour obtenir le consentement des contacts lorsqu'ils fournissent leur numéro de téléphone pendant le processus de réservation de rendez-vous"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personnaliser le widget de calendrier",
    "works_with_neo_widget": "Fonctionne uniquement avec le widget Neo",
    "customise_widget_desc": "Personnaliser l'apparence du widget : couleur principale, couleur de fond et texte du bouton",
    "learn_more": "En savoir plus",
    "primary_settings": "Paramètres de base",
    "primary_settings_desc": "Configurez la couleur principale, la couleur de fond et les autres préférences de widget pour personnaliser votre widget calendrier",
    "primary_color": "Couleur primaire",
    "primary_color_desc": "Définissez la couleur des boutons, des dates, des plages horaires et des actions",
    "background_color": "Couleur de fond",
    "background_color_desc": "Définissez la couleur d'arrière-plan qui sert de toile de fond à la fois au sélecteur de date-heure et au formulaire",
    "button_text": "Texte du bouton",
    "button_text_desc": "Personnalisez le texte affiché sur le bouton lors de la réservation d'un rendez-vous",
    "calendar_title": "Titre du calendrier",
    "calendar_title_desc": "Le nom de votre calendrier",
    "calendar_description": "Description du calendrier",
    "calendar_description_desc": "La description entrée",
    "calendar_details": "Détails du calendrier",
    "calendar_details_desc": "Comprend des renseignements tels que la durée, la date et l'heure, les détails récurrents et le fuseau horaire",
    "reset_to_default": "Réinitialiser aux paramètres par défaut",
    "preview_widget": "Widget de prévisualisation"
  }
}
