import { ICustomValues } from '../types/CustomValues'
const childUserMenu = (menuString: string) => {
  return {
    label: 'Assigned User',
    value: 'assigned_user',
    children: [
      { label: 'Full Name', value: `{{${menuString}.user.name}}` },
      { label: 'First Name', value: `{{${menuString}.user.first_name}}` },
      { label: 'Last Name', value: `{{${menuString}.user.last_name}}` },
      { label: 'Email', value: `{{${menuString}.user.email}}` },
      { label: 'Phone', value: `{{${menuString}.user.phone}}` },
      {
        label: 'Twilio Phone',
        value: `{{${menuString}.user.twilio_phone_number}}`,
      },
    ],
  }
}

const accountOptionWithOwner = {
  label: 'Account',
  value: 'account',
  children: [
    { label: 'Name', value: '{{location.name}}' },
    { label: 'Full Address', value: '{{location.full_address}}' },
    { label: 'City', value: '{{location.city}}' },
    { label: 'State', value: '{{location.state}}' },
    { label: 'Country', value: '{{location.country}}' },
    { label: 'Postal Code', value: '{{location.postal_code}}' },
    { label: 'Email', value: '{{location.email}}' },
    { label: 'Phone', value: '{{location.phone}}' },
  ],
}

const contactOption = {
  label: 'Contact',
  value: 'contact',
  children: [
    { label: 'Full Name', value: '{{contact.name}}' },
    { label: 'First Name', value: '{{contact.first_name}}' },
    { label: 'Last Name', value: '{{contact.last_name}}' },
    { label: 'Email', value: '{{contact.email}}' },
    { label: 'Phone', value: '{{contact.phone}}' },
    { label: 'Full Address', value: '{{contact.full_address}}' },
    { label: 'Address 1', value: '{{contact.address1}}' },
    { label: 'City', value: '{{contact.city}}' },
    { label: 'State', value: '{{contact.state}}' },
    { label: 'Country', value: '{{contact.country}}' },
    { label: 'Postal Code', value: '{{contact.postal_code}}' },
  ],
}

const contactOptionMeetingInviteTitle = {
  label: 'Contact',
  value: 'contact',
  children: [
    { label: 'Full Name', value: '{{contact.name}}' },
    { label: 'First Name', value: '{{contact.first_name}}' },
    { label: 'Last Name', value: '{{contact.last_name}}' },
    { label: 'Email', value: '{{contact.email}}' },
    { label: 'Phone', value: '{{contact.phone}}' },
    { label: 'Full Address', value: '{{contact.full_address}}' },
    { label: 'Address 1', value: '{{contact.address1}}' },
    { label: 'City', value: '{{contact.city}}' },
    { label: 'Country', value: '{{contact.country}}' },
  ],
}

const userOption = {
  label: 'User',
  value: 'user',
  children: [
    { label: 'Full Name', value: '{{user.name}}' },
    { label: 'First Name', value: '{{user.first_name}}' },
    { label: 'Last Name', value: '{{user.last_name}}' },
    { label: 'Email', value: '{{user.email}}' },
    { label: 'Phone', value: '{{user.phone}}' },
    { label: 'Twilio Phone', value: '{{user.twilio_phone_number}}' },
  ],
}

export const calendarNameFieldOptions = [accountOptionWithOwner]

export const meetingInviteTitleFieldOptionsEvent = [
  {
    label: 'Appointment',
    value: 'appointment',
    children: [
      { label: 'Start Date Time', value: '{{appointment.start_time}}' },
      { label: 'Start Date', value: '{{appointment.only_start_date}}' },
      { label: 'Start Time', value: '{{appointment.only_start_time}}' },
      { label: 'End Date Time', value: '{{appointment.end_time}}' },
      { label: 'End Date', value: '{{appointment.only_end_date}}' },
      { label: 'End Time', value: '{{appointment.only_end_time}}' },
      { label: 'Timezone', value: '{{appointment.timezone}}' },
    ],
  },
  contactOptionMeetingInviteTitle,
  accountOptionWithOwner,
]

export const meetingInviteTitleFieldOptionsV3 = [
  {
    label: 'Appointment',
    value: 'appointment',
    children: [
      { label: 'Start Date Time', value: '{{appointment.start_time}}' },
      { label: 'Start Date', value: '{{appointment.only_start_date}}' },
      { label: 'Start Time', value: '{{appointment.only_start_time}}' },
      { label: 'End Date Time', value: '{{appointment.end_time}}' },
      { label: 'End Date', value: '{{appointment.only_end_date}}' },
      { label: 'End Time', value: '{{appointment.only_end_time}}' },
      { label: 'Timezone', value: '{{appointment.timezone}}' },
      {
        ...childUserMenu('appointment'),
      },
    ],
  },
  contactOptionMeetingInviteTitle,
  accountOptionWithOwner,
]

export const meetingLocationFieldOptions = [
  accountOptionWithOwner,
  contactOption,
]

export const meetingLocationFieldOptionsEvent = [
  accountOptionWithOwner,
  contactOption,
]

export const thankyouMessageFieldOptions = [
  {
    label: 'Appointment',
    value: 'appointment',
    children: [
      { label: 'Start Date Time', value: '{{appointment.start_time}}' },
      { label: 'Start Date', value: '{{appointment.only_start_date}}' },
      { label: 'Start Time', value: '{{appointment.only_start_time}}' },
      { label: 'End Date Time', value: '{{appointment.end_time}}' },
      { label: 'End Date', value: '{{appointment.only_end_date}}' },
      { label: 'End Time', value: '{{appointment.only_end_time}}' },
      { label: 'Timezone', value: '{{appointment.timezone}}' },
      { label: 'Meeting Location', value: '{{appointment.meeting_location}}' },
    ],
  },
  accountOptionWithOwner,
]

export const thankyouMessageFieldRecurringOptions = [accountOptionWithOwner]

export const additionalNotesFieldOptions = [
  {
    label: 'Appointment',
    value: 'appointment',
    children: [
      { label: 'Start Date Time', value: '{{appointment.start_time}}' },
      { label: 'Start Date', value: '{{appointment.only_start_date}}' },
      { label: 'Start Time', value: '{{appointment.only_start_time}}' },
      { label: 'End Date Time', value: '{{appointment.end_time}}' },
      { label: 'End Date', value: '{{appointment.only_end_date}}' },
      { label: 'End Time', value: '{{appointment.only_end_time}}' },
      { label: 'Timezone', value: '{{appointment.timezone}}' },
      { label: 'Meeting Location', value: '{{appointment.meeting_location}}' },
    ],
  },
  {
    label: 'Account',
    value: 'account',
    children: [
      { label: 'Name', value: '{{location.name}}' },
      { label: 'Full Address', value: '{{location.full_address}}' },
      { label: 'City', value: '{{location.city}}' },
      { label: 'State', value: '{{location.state}}' },
      { label: 'Country', value: '{{location.country}}' },
      { label: 'Postal Code', value: '{{location.postal_code}}' },
      { label: 'Phone', value: '{{location.phone}}' },
    ],
  },
]

export function handleCustomValueModification(customValues: ICustomValues[]) {
  if (!customValues) {
    return
  }
  const o: {
    label?: string
    value?: string
    children?: { label: string; value: string }[]
  } = {}
  o.label = 'Custom Value'
  o.value = 'customValue'
  o.children = customValues
    .filter(item => item.name && item.value && item.fieldKey)
    .map(item => ({
      label: item.name,
      value: item.fieldKey,
    }))

  return o.children.length > 0 && o
}
