export const validateEmail = str => {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return reg.test(String(str).toLowerCase())
}

const types = ['physical', 'inbound_call', 'outbound_call', 'custom']
export function hasDuplicateKeyValue(locationConf) {
  if (!locationConf) {
    return false
  }
  if (!locationConf.length) {
    return false
  }
  if (!Array.isArray(locationConf)) {
    return false
  }

  const arr = locationConf
    .filter(item => types.includes(item.kind))
    .map(item => {
      return {
        key: item.kind,
        value: item.location,
      }
    })

  const seen = {}
  for (const obj of arr) {
    const objStr = JSON.stringify(obj)
    if (seen[objStr]) {
      return true
    }
    seen[objStr] = true
  }
  return false
}

export function hasEmptyKeys(arr) {
  if (!arr) {
    return false
  }
  if (!arr.length) {
    return false
  }
  if (!Array.isArray(arr)) {
    return false
  }
  if (arr.length === 1) {
    return false
  }

  return arr
    .filter(item => types.includes(item.kind))
    .some(item => {
      return !item.location
    })
}
