{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalender-Einstellungen",
      "group": "Gruppe",
      "manage_your_calendars_and_groups": "Verwalten von Kalendern und Gruppen",
      "groups": "Gruppen",
      "create_group": "Gruppe erstellen",
      "create_calendar": "Kalender erstellen",
      "calendar_name": "Kalender Name",
      "group_duration": "Gruppen-Dauer",
      "calendar_type": "Kalender-Typ",
      "action": "Aktion",
      "round_robin": "Round-Robin",
      "event": "Veranstaltung",
      "group_name": "Gruppenname",
      "calendars": "Kalender",
      "date_updated": "Datum aktualisiert",
      "status": "Status",
      "action_dropdown": "Aktionen-Dropdown",
      "edit": "Bearbeiten",
      "duplicate": "Duplizieren",
      "copy_embed_code": "Einbettungscode kopieren",
      "copy_permanent_link": "Permanenten Link kopieren",
      "copy_new_slug_link": "Terminplanungslink kopieren",
      "copy_scheduling_link": "Terminplanungslink kopieren (Veraltet)",
      "deactivate_calendar": "Kalender deaktivieren",
      "move_to_group": "In Gruppe verschieben",
      "delete_calendar": "Kalender löschen",
      "delete_group": "Löschen",
      "delete_this_group": "Diese Gruppe löschen",
      "activate_calendar": "Kalender aktivieren",
      "duration": "Dauer (Min.)",
      "all": "Alle",
      "draft": "Entwurf",
      "active": "Aktiv",
      "rearrange_calendars": "Kalender neu anordnen",
      "deactivate_all_calendars_in_group": "Deaktivieren aller Kalender in der Gruppe",
      "activate_all_calendars_in_group": "Alle Kalender in der Gruppe aktivieren",
      "no_groups_found": "Ihre Suche hat keine Kalendergruppen gefunden",
      "no_groups_in_location": "Beginnen Sie mit der Verwendung von Gruppen, indem Sie eine neue Kalendergruppe erstellen",
      "no_calendars_found": "Beginnen Sie mit der Verwendung des Kalenders, indem Sie einen neuen Kalender erstellen",
      "no_calendars_found_search": "Ihre Suche ergab keine Übereinstimmung mit Kalendern",
      "delete_calendar_message": "Sie sind dabei, den Kalender zu löschen. Wenn Sie auf Löschen klicken, werden auch alle Termine gelöscht.",
      "delete_calendar_message_title": "Diesen Kalender löschen?",
      "no_calendars_found_title": "Keine Kalender gefunden",
      "no_groups_in_location_title": "Keine Gruppen gefunden",
      "delete_group_confirmation_title": "Diese Gruppe löschen?",
      "delete_group_confirmation": "Sind Sie sicher, dass Sie die ausgewählte Gruppe löschen möchten?",
      "delete_group_confirmation_checkbox_title": "Löschen Sie die zugehörigen Kalender und Termine",
      "delete_group_confirmation_checkbox_description": "Bei Auswahl dieser Option werden auch die Kalender in dieser Gruppe sowie alle Termine in diesen Kalendern gelöscht",
      "group_status_message_title": "Status der Gruppe auf {status} ändern?",
      "calendar_status_message_title": "Status des Kalenders auf {status} ändern?",
      "calendar_status_message": "Sie sind dabei, den Status des Kalenders auf {status} zu ändern",
      "group_status_message": "Sie sind dabei, den Status der Gruppe auf {status} zu ändern",
      "cancel": "Abbrechen",
      "new_service_menu": "Neues Service-Menü",
      "service_menus": "Service-Menü",
      "manage_your_calendars_groups_and_service_menus": "Verwalten Sie Ihre Kalender, Gruppen und Service-Menü",
      "deactivate_service_menu": "Deaktivieren Sie das Service-Menü",
      "delete_service_menu": "Löschen Sie dieses Servicemenü",
      "delete_service_menu_confirmation": "Bist du sicher, dass du dieses Service-Menü löschen möchtest?",
      "service_menu_status_message_title": "{status} dieses Service-Menü?",
      "service_menu_status_message": "Du bist dabei, das {status} des Servicemenüs zu öffnen",
      "no_service_menus_in_location_title": "Erstellen Sie Ihre individuelle Service-Menü",
      "add_new_service_menu": "Neuen Service-Menüpunkt hinzufügen",
      "no_service_menus_in_location": "Beginnen Sie damit, ein neues Servicemenü zu erstellen und Gruppen hinzuzufügen",
      "no_service_menu_found": "Kein Service-Menü gefunden",
      "no_service_menu_found_desc": "Ihre Suche ergab keine Übereinstimmungen mit den Servicemenüs",
      "rooms": "Zimmer",
      "equipments": "Ausrüstung",
      "share_otl": "Teilen Sie Ihre Verfügbarkeit jedes Mal mit einem einzigartigen Link, der nach einer Buchung abläuft, um kontrollierten Zugriff zu gewährleisten",
      "generate_link": "Erzeugen Sie einen neuen Link",
      "share_schedule_link": "Der Terminierungslink wird durch den Slug bestimmt. Passen Sie den Slug an, und der Terminierungslink passt sich automatisch an die Änderung an",
      "share_permanent_link": "Ideal für Trichter, Website-Weiterleitungen oder Anzeigen bleibt der permanente Link konstant und wird nicht von Slug-Änderungen beeinflusst",
      "copy": "Kopie",
      "share_embed_code": "Platzieren Sie diesen Code in Ihrem HTML an der Stelle, an der Sie möchten, dass Ihr {embedType} Widget angezeigt wird",
      "scheduling_link": "Terminplanungslink",
      "permanent_link": "Permanenter Link",
      "one_time_link": "Einmaliger Link",
      "embed_code": "Einbetten-Code",
      "loading": "Laden...",
      "round_robin_booking": "Rundenturnier",
      "round_robin_description": "Verteilt Termine unter Teammitgliedern in einer rotierenden Reihenfolge",
      "round_robin_example": "zum Beispiel: Verkaufsgespräche, Einarbeitungssitzungen",
      "unassigned_booking": "Veranstaltungskalender",
      "unassigned_description": "Für die Planung physischer Veranstaltungen ohne Host-Verband",
      "unassigned_example": "Zum Beispiel: Konferenzen, öffentliche Seminare, Messen",
      "class_booking": "Klassenbuchung",
      "class_booking_description": "Ein Gast trifft auf mehrere Teilnehmer",
      "class_booking_example": "zum Beispiel: Webinare, Gruppentrainings, Online-Kurse",
      "collective_booking": "Gemeinschaftsbuchung",
      "collective_booking_description": "Mehrere Gastgeber treffen sich mit einem Teilnehmer",
      "collective_booking_example": "Zum Beispiel: Gruppeninterviews, Ausschussbewertungen",
      "service_booking": "Servicebuchung",
      "service_booking_description": "Nahtlose Terminplanung für Service-basierte Unternehmen",
      "service_booking_example": "Zum Beispiel: Terminvereinbarungen im Spa, Reparaturdienste, Beratungen",
      "personal_booking": "Persönliche Buchung",
      "personal_booking_description": "Vereinbart Einzelgespräche mit einem bestimmten Teammitglied",
      "personal_booking_example": "Zum Beispiel: Kundentreffen, private Beratungen",
      "personal": "Personal",
      "explore_more_types": "Erkunden Sie weitere Typen",
      "scheduling_type": "Zeitplanart",
      "scheduling_type_description": "Wählen Sie einen Zeitplan-Typ für Ihren neuen Kalender aus"
    },
    "preferences": {
      "preferences": "Präferenzen",
      "my_preferences": "Meine Präferenz",
      "account_preferences": "Konto Präferenz",
      "user_preferences": "Benutzerpräferenzen",
      "set_preferences": "Legen Sie die Präferenzen für Ihr Konto fest",
      "scheduling_options": "Optionen für die Planung",
      "meeting_location": "Ort der Besprechung",
      "zoom": "Zoom",
      "save_preferences": "Präferenzen speichern",
      "in_app_preferences": "In-App-Einstellungen",
      "set_start_day": "Einstellungen wie den Starttag für die App festlegen",
      "view_options": "Optionen anzeigen",
      "week_starts_on": "Die Woche beginnt am",
      "sunday": "Sonntag",
      "widget_preferences": "Widget-Einstellungen",
      "set_widget_language": "Festlegen von Einstellungen wie die Sprache des Widgets",
      "language_and_region": "Sprache und Region",
      "language": "Sprache",
      "english": "Englisch",
      "time_format": "Zeitformat",
      "time_format_value": "Sat Dec 30 1899 13:30:00 GMT+0521 (India Standard Time)",
      "monday": "Montag",
      "custom_meeting_location": "Individueller Terminort"
    },
    "availability": {
      "availability": "Verfügbarkeit",
      "my_availability": "Meine Verfügbarkeit",
      "account_availability": "Verfügbarkeit des Kontos",
      "timezone": "Zeitzone",
      "working_hours": "Geschäftszeiten",
      "set_recurring_hours": "Legen Sie Ihre wiederkehrenden Geschäftszeiten für Termine fest",
      "sunday": "Sonntag",
      "monday": "Montag",
      "tuesday": "Dienstag",
      "wednesday": "Mittwoch",
      "thursday": "Donnerstag",
      "friday": "Freitag",
      "saturday": "Samstag",
      "widget_preferences": "Widget-Einstellungen",
      "set_widget_language": "Einstellungen wie Sprache für das Widget festlegen",
      "language_and_region": "Sprache und Region",
      "language": "Sprache",
      "english": "Englisch"
    },
    "connections": {
      "connections": "Verbindungen",
      "conferencing": "Besprechen / Besprechungen",
      "my_connections": "Meine Verbindungen",
      "account_connections": "Konto-Verbindungen",
      "connection_preferences": "Präferenzen für Verbindungen",
      "manage_calendars_conferencing_channels": "Verwalten von Kalendern und Konferenzkanälen",
      "main_integration_calendar": "Integrierter Hauptkalender",
      "event_integration_calendar": "Ereignisse, die in einem Systemkalender erstellt werden, an dem Sie beteiligt sind, werden auch in diesem integrierten Kalender erstellt",
      "2way_sync": "2-Wege-Synchronisation: Erstelle Systemereignisse aus Ereignissen, die im integrierten Hauptkalender gebucht wurden",
      "calendars": "Kalender",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Auf Konflikte prüfen",
      "choose_accounts_to_connect": "Wählen Sie, welche Konten Sie verbinden möchten",
      "payments": "Zahlungen",
      "stripe": "Stripe",
      "ads": "Anzeigen",
      "local_service_ads": "Lokale Werbeanzeigen für Dienstleistungen",
      "fire_workflow": "Workflow-Auslöser starten für Termine, die über 2-Wege-Synchronisation gebucht wurden",
      "connect_google_outlook": "Bitte verbinden Sie Ihr Google- oder Outlook-Konto, um den primären Kalender auswählen zu können",
      "execute_automation": "Automatisierung ausführen",
      "save": "speichern",
      "lsa": "Lokale Werbeanzeigen für Dienstleistungen"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Kontakt wählen",
    "start_time": "Startzeit",
    "end_time": "Endzeit",
    "search_by_name_email_phone_or_company": "Suche nach Name, E-Mail, Telefon oder Unternehmen",
    "add_new": "Neu hinzufügen",
    "pick_from_available_contacts": "Oder aus vorhandenen Kontakten auswählen",
    "book_appointment": "Termin buchen",
    "calendar": "Kalender",
    "repeat": "Wiederholen",
    "date_and_time": "Datum und Uhrzeit",
    "close": "Schließen",
    "team_member": "Teammitglied",
    "recurring_booking": "Wiederkehrende Buchung",
    "one_slot_booking": "Buchung eines Zeitfensters",
    "event_type": "Ereignis-Typ",
    "occurrences_booked": "Ereignisse werden gebucht",
    "repeats": "Wiederholungen",
    "booking_details": "Details zur Buchung",
    "showing_slots_in_this_timezone": "Anzeigen von Terminen in dieser Zeitzone:",
    "update_contact_tz": "Zeitzone des Kontakts auch aktualisieren auf",
    "date": "Datum",
    "slot": "Zeitfenster",
    "calendar_default": "Kalender Standard",
    "custom": "Benutzerdefiniert",
    "meeting_notes": "(z.B.) Besprechungsnotizen für unseren nächsten Termin",
    "out_of_office": "(z.B.) Abwesenheit für diese Woche!",
    "standard": "Standard",
    "appointment_title": "Titel des Termins",
    "event_title": "Titel des Ereignisses",
    "appt_with_bob": "(z.B.) Verabredung mit Bob",
    "vacation": "(z.B.) Urlaub",
    "show_notes": "Notizen anzeigen",
    "hide_notes": "Notizen ausblenden",
    "additional_preferences": "Zusätzliche Präferenzen",
    "meeting_location": "Ort des Termins",
    "appointment_status": "Status des Termins",
    "appt_description": "Beschreibung des Termins",
    "recurring_details": "Wiederkehrende Details",
    "event_description": "Beschreibung des Ereignisses",
    "in_contact_timezone": "In der Zeitzone des Kontakts",
    "vacation_message": "Fährst du in den Urlaub? Nimmst du dir eine Auszeit? Blockiere die Zeit in deinem Kalender, um zu verhindern, dass Kunden Termine buchen (bestehende Termine bleiben trotzdem in deinem Kalender).",
    "contact": "Kontakt",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "back": "Rücken",
    "pick_available_contacts": "Oder wählen Sie aus vorhandenen Kontakten",
    "no_contacts_found": "Keine Kontakte gefunden",
    "found": "gefunden",
    "select_one": "Wählen Sie einen",
    "tz_tooltip": "Dies ist nur von Bedeutung, wenn Sie benutzerdefinierte Werte, wie z. B. termin_start_zeit termin_end_zeit termin_zeitzone usw., in Ihrer ausgehenden Kommunikation mit Ihren Kunden oder Auftraggebern verwenden. Dabei werden die oben genannten Angaben einfach in die jeweilige Ortszeit umgerechnet",
    "meeting_location_tooltip": "Der Besprechungsort wird entsprechend der Konfiguration im Kalender festgelegt",
    "appt_meeting_location_tooltip": "Der Benutzer kann den Besprechungsort für den jeweiligen Termin festlegen",
    "gmeet_tooltip": "Bitte stellen Sie sicher, dass das jeweilige Teammitglied einen Google-Kalender als primären Kalender eingestellt hat",
    "zoom_tooltip": "Stellen Sie sicher, dass das betreffende Teammitglied Zoom integriert hat",
    "enter_meeting_location": "Besprechungsort eingeben",
    "save_appointment": "Termin speichern",
    "save_event": "Termin speichern",
    "search_contacts": "Suche nach Name, E-Mail, Telefon oder Unternehmen",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Kalender",
    "appointment_owner": "Eigentümer des Termins",
    "source": "Quelle",
    "booked_by": "Gebucht von",
    "view_activity_log": "Aktivitätsprotokoll anzeigen",
    "status": "Status",
    "cancel_appointment": "Termin stornieren",
    "google_event": "Google-Veranstaltung",
    "outlook_event": "Outlook-Termin",
    "blocked_slot": "Blockierter Slot",
    "appointment": "Termin",
    "confirmed": "Bestätigt",
    "unconfirmed": "Unbestätigt",
    "cancelled": "Abgesagt",
    "showed": "Erschienen",
    "no_show": "Nicht erschienen",
    "invalid": "Ungültig"
  },
  "appointment_list": {
    "group_all": "alle",
    "group_primary": "PRIMÄR",
    "group_user": "BENUTZER",
    "group_team": "Gruppen",
    "group_calendar": "Kalender",
    "all_appointments": "Alle Termine",
    "my_calendar": "Mein Kalender",
    "status_all": "Alle",
    "status_confirmed": "bestätigt",
    "status_new": "Neu (Aktion erforderlich)",
    "status_show": "gezeigt",
    "status_no_show": "Nichterscheinen",
    "status_cancelled": "Abgesagt",
    "status_invalid": "ungültig",
    "filter_date_added_desc": "Hinzugefügt am (Absteigend)",
    "filter_start_added_desc": "Startdatum (absteigend)",
    "filter_start_added_asc": "Startdatum aufsteigend ab heute",
    "button_new_appointment": "Neue Ernennung",
    "name": "Name",
    "title": "Titel",
    "requested_time": "Angeforderte Zeit",
    "date_added": "Hinzugefügt am",
    "calendar": "Kalender",
    "appointment_owner": "Termininhaber",
    "notes": "Notizen",
    "status": "Status",
    "action_view_detail": "Details anzeigen",
    "action_view_notes": "Notizen anzeigen",
    "action_edit": "Bearbeiten",
    "action_delete": "Löschen",
    "action_reschedule": "Verschieben",
    "page_previous": "Vorherige",
    "page_next": "Als nächstes",
    "appointment_notes": "Terminnotizen",
    "close": "Schließen",
    "confirm_delete_header": "Termin löschen",
    "confirm_delete_message": "Sind Sie sicher, dass Sie diesen Termin löschen möchten?",
    "confirm_delete_button": "Löschen",
    "confirm_update_header": "Aktualisieren Sie den Terminstatus",
    "confirm_update_confirmed_message": "Sind Sie sicher, dass Sie diesen Termin als bestätigt markieren möchten?",
    "confirm_update_invalid_message": "Sind Sie sicher, dass Sie diesen Termin als ungültig markieren möchten?",
    "confirm_update_showed_message": "Bist du sicher, dass du diesen Termin als erschienen markieren willst?",
    "confirm_update_noshow_message": "Sind Sie sicher, dass Sie diesen Termin als nicht wahrgenommen markieren möchten?",
    "confirm_update_cancelled_message": "Sind Sie sicher, dass Sie diesen Termin als storniert markieren möchten?",
    "confirm_update_button": "Aktualisierung",
    "disable_menu_warning": "Derzeit haben Sie keine Berechtigung, die Termindetails anzuzeigen oder zu bearbeiten, da der zugehörige Kontakt Ihnen nicht zugewiesen ist. Bitte wenden Sie sich zur weiteren Unterstützung an Ihren Administrator",
    "no_contact_warning": "Momentan haben Sie keinen Zugriff auf die Kontaktdaten. Dies kann daran liegen, dass der Kontakt Ihnen nicht zugewiesen ist. Bitte wenden Sie sich für Hilfe an Ihren Administrator",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Umgebucht"
  },
  "create_calendar": {
    "calendar_name": "Kalendername",
    "calendar_logo": "Kalenderlogo",
    "select_team_member": "Wähle Teammitglieder aus",
    "eg_outbound_reach": "(Abgereichte) Ausgehende Erreichung",
    "description": "Beschreibung",
    "amount": "Betrag",
    "description_placeholder": "Beschreibung schreiben",
    "meeting_duration": "Dauer des Treffens",
    "seats_per_class": "Sitze pro Klasse",
    "group": "Gruppe",
    "custom_url": "Benutzerdefinierte URL",
    "meeting_invite_title": "Besprechungseinladungstitel",
    "event_color": "Eventfarbe",
    "availability_type": "Verfügbarkeitstyp",
    "deposit_amount": "Einzahlungsbetrag",
    "collect_deposit_amount": "Akzeptiere Teilzahlung",
    "percentage": "Prozentsatz",
    "total_amount": "Gesamtbetrag",
    "reschedule_preference": "Umbuchungswunsch",
    "reschedule_preference_subtext": "Wenn ein Kontakt einen Termin verschiebt:",
    "reassign_through_round_robin": "Zuweisen durch Round-Robin",
    "keep_same_assinged_user": "Behalten Sie denselben Termininhaber",
    "new_appointment_preference": "Neuer Terminvorzug",
    "new_appointment_preference_text": "Immer mit dem zugewiesenen Benutzer des Kontakts buchen",
    "new_appointment_preference_subtext": "Buchen Sie neue Termine mit dem zugeordneten Benutzer des Kontakts anstelle der Verwendung von Rund-um-die-Uhr-Planung"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Schnell-Tipp",
      "timezone_change_tip": "Zeitzonen können jederzeit unter den Zeitzoneneinstellungen geändert werden",
      "save": "Speichern",
      "close": "Schließen",
      "meeting_details": "Besprechungsdetails",
      "availability": "Verfügbarkeit",
      "forms_and_payment": "Formulare und Zahlung",
      "notifications_and_additional_options": "Benachrichtigungen & Zusätzliche Optionen",
      "connections": "Verbindungen",
      "customizations": "Anpassungen",
      "create": "Erstellen",
      "edit": "Bearbeiten"
    },
    "meeting_details": {
      "meeting_location": "Treffpunkt",
      "enter_meeting_location": "Geben Sie den Treffpunkt ein",
      "click_to_upload": "Klicken Sie, um hochzuladen",
      "square": "Platz",
      "circle": "Kreis",
      "remove_logo": "Logo entfernen",
      "meeting_invite_title_tooltip": "Dies ist der Titel für das Kalenderereignis, das in Google Kalender, Outlook, Apple Kalender usw. angezeigt wird",
      "event_color_tooltip": "Wählen Sie eine Farbe für dieses Buchungsereignis aus. Farben werden mit Ereignissen in Google Kalender synchronisiert",
      "enter_a_name": "Bitte geben Sie einen Namen ein",
      "enter_an_event_title": "Bitte geben Sie einen Veranstaltungstitel ein",
      "high_priority": "Hohe Priorität",
      "medium_priority": "Mittlere Priorität",
      "low_priority": "Niedrige Priorität",
      "custom": "Maßgeschneidert",
      "zoom_not_added": "Der Benutzer hat sein Zoom-Konto noch nicht verbunden",
      "opt_avail": "Optimiere für Verfügbarkeit",
      "opt_equal": "Optimieren für eine gleichmäßige Verteilung",
      "team_members": "Wählen Sie Teammitglieder aus",
      "team_member": "Teammitglied auswählen",
      "meeting_location_required": "Treffpunkt ist erforderlich",
      "team_member_disabled_recurring_tooltip": "Wiederkehrende Besprechungen erlauben nur ein Teammitglied. Um mehrere Teammitglieder hinzuzufügen, deaktivieren Sie bitte wiederkehrende Besprechungen.",
      "team_member_disabled_class_booking_tooltip": "Nur ein Teammitglied kann einem Klassenbuchungskalender hinzugefügt werden",
      "enable_contact_assigned_to_setting": "Um diese Einstellung zu aktivieren, muss das Formular zuerst in der Widget-Reihenfolge sein. Bitte ordnen Sie die Widget-Reihenfolge im Bereich Formulare & Zahlung neu an",
      "reschedule_preference_setting_tooltip": "Wählen Sie aus, wem der Termin zugewiesen werden soll, wenn ein Kontakt die Terminbuchung im Widget verschiebt",
      "appointment_preference_tooltip": "Wählen Sie, ob neue Termine immer mit dem zugewiesenen Benutzer des Kontakts gebucht werden sollen. Wenn ein Kontakt einen zugewiesenen Benutzer hat, wird dieser bevorzugt; ansonsten erfolgt eine Rund-um-die-Uhr-Zuweisung. Tipp: Schalten Sie Personalauswahl erlauben aus, um Buchende daran zu hindern, den Mitarbeiter auf dem Buchungstool zu ändern",
      "add_location": "Standort hinzufügen",
      "multiple_locations_neo": "Mehrere Besprechungsorte werden nur von dem Neo Widget mit dem Standardformular unterstützt",
      "make_owner": "Besitzer machen",
      "only_one_team_member": "Es ist nur ein Teammitglied erlaubt, wenn mehrere Standorte hinzugefügt werden",
      "only_one_team_member_personal_calendar": "Es ist nur einem Teammitglied gestattet, persönliche Buchungen vorzunehmen"
    },
    "availability": {
      "my_availability": "Meine Verfügbarkeit",
      "set_availability": "Stellen Sie hier Ihre Verfügbarkeit für den Kalender ein",
      "standard": "Standard",
      "custom": "Maßgeschneidert",
      "to": "Zu",
      "copy_to_all": "Kopie für alle",
      "add_time": "Zeit hinzufügen",
      "recurring_meeting": "Wiederkehrendes Treffen",
      "recurring_info": "Nur ein Benutzer pro Kalender für wiederkehrende Buchung",
      "repeat": "Wiederholen",
      "times_to_repeat": "Anzahl an Wiederholungen",
      "slots_unavailable": "Wenn Slots nicht verfügbar sind?",
      "overlap_status": "Status des Termins für überlappende Zeitslots",
      "meeting_interval": "Besprechungsintervall",
      "meeting_duration": "Dauer des Termins",
      "minimum_scheduling": "Minimale Vorlaufzeit",
      "date_range": "Datumsbereich",
      "maximum_bookings_per_day": "Maximale Buchungen pro Tag",
      "maximum_bookings_per_slot": "Maximale Buchungen pro Zeitslot",
      "maximum_bookings_per_slot_per_user": "Maximale Buchungen pro Zeitslot (pro Benutzer)",
      "seats_per_class": "Teilnehmer pro Meeting",
      "pre_buffer_time": "Pufferzeit vorher",
      "post_buffer_time": "Pufferzeit danach",
      "pre_buffer_tooltip": "Die Pre-Buffer ist zusätzliche Zeit, die vor einem Termin hinzugefügt werden kann, um extra Zeit zum Vorbereiten vor einem Treffen zu haben",
      "post_buffer_tooltip": "Der Post-Buffer ist zusätzliche Zeit, die nach einem Termin hinzugefügt werden kann, um zusätzliche Zeit zum Abschließen zu ermöglichen",
      "buffer_time": "Pufferzeit",
      "daily": "täglich",
      "weekly": "Wöchentlich",
      "monthly": "Monatlich",
      "skip_booking": "Überspringe Buchung nicht verfügbarer Zeitslots",
      "continue_booking": "Buchung fortfahren",
      "book_next_available": "Buche den nächsten freien Platz",
      "confirmed": "Bestätigt",
      "unconfirmed": "Unbestätigt",
      "enter_valid_hours": "Bitte geben Sie gültige Öffnungszeiten ein",
      "enter_valid_avail": "Bitte geben Sie eine gültige individuelle Verfügbarkeit ein",
      "availability_type_tooltip": "Wählen Sie Standardverfügbarkeit für regelmäßige Buchungen, die wöchentlich angeboten werden. Wählen Sie Benutzerdefiniert für Buchungen, die nur an bestimmten Daten verfügbar sind",
      "meeting_interval_tooltip": "Der Meeting-Intervall spiegelt die Zeitspanne zwischen den Buchungsslots wider, die im Kalender angezeigt werden. Für ein 30-minütiges Meeting, das zu jeder vollen Stunde verfügbar sein soll, beträgt das Intervall 1 Stunde. Für ein 30-minütiges Meeting, das alle 30 Minuten verfügbar sein soll, beträgt das Intervall 30 Minuten",
      "date_range_tooltip": "Veranstaltungen können in den nächsten n Tagen geplant werden",
      "maximum_bookings_per_day_tooltip": "Maximale Anzahl der Termine pro Tag auf diesem Kalender erlaubt",
      "maximum_bookings_per_slot_tooltip": "Maximale Anzahl von Terminen, die pro Zeitfenster für den Benutzer erlaubt ist. Wenn die Anzahl der Termine für das gegebene Zeitfenster für den Benutzer überschritten wird, wechselt es entweder zum nächsten Benutzer in der Round-Robin-Warteschlange oder markiert das Zeitfenster als nicht verfügbar für das Buchen durch Interessenten",
      "add_days": "Tage hinzufügen",
      "month_on_day": "Monatlich am Tag",
      "month_on_last_day": "Monatlich am letzten Tag",
      "day": "Tag",
      "week": "Woche",
      "month": "Monat",
      "mins": "Minuten",
      "hours": "Stunden",
      "days": "Tage",
      "weeks": "Wochen",
      "months": "Monate",
      "meeting_interval_error": "Meeting-Intervall muss zwischen 5 Minuten und 12 Stunden liegen",
      "meeting_duration_error": "Die Dauer des Treffens muss zwischen 1 Minute und 12 Stunden liegen",
      "buffer_time_error": "Die Pufferzeit muss weniger als 12 Stunden betragen",
      "subTitle": "Wählen Sie das Datum, um spezifische Uhrzeiten festzulegen",
      "heading_1": "Wöchentlich verfügbare Stunden",
      "tooltip_1": "Legen Sie Ihren wöchentlichen Verfügbarkeitsplan fest. Dies sind Ihre Standardzeiten, die wöchentlich konsequent angewendet werden",
      "heading_2": "Wann sind Sie verfügbar?",
      "unavailable_label": "Nicht verfügbar",
      "tooltip_2": "Passen Sie Ihren Terminplan für bestimmte Daten an. Überschreiben Sie Ihre regulären wöchentlichen Arbeitsstunden, indem Sie Verfügbarkeit oder Nichtverfügbarkeit für ausgewählte Daten kennzeichnen",
      "date_specific_level": "Fügen Sie datumsbezogene Stunden hinzu",
      "specific_hours_button": "Datumsspezifische Stunden",
      "overlap_error": "Zeitfenster dürfen nicht überlappen",
      "apply": "Anwenden",
      "copy_times_to": "Kopieren Sie die Zeiten nach",
      "no_date_specific_heading": "Keine spezifische Zeit hinzugefügt",
      "no_date_specific_description": "Sie können spezifische Daten und Uhrzeiten zu Ihrer Verfügbarkeit hinzufügen/entfernen",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "Um ein Datum und eine Uhrzeit hinzuzufügen, stellen Sie bitte sicher, dass wiederkehrende Besprechungen deaktiviert sind",
      "recurring_disable_event_calendar_conditions": "Wiederkehrende Treffen sind deaktiviert. Um sie zu aktivieren, stellen Sie sicher, dass die Zahlung ausgeschaltet ist und keine datumspezifischen Stunden hinzugefügt sind",
      "service_interval": "Service intervall",
      "service_interval_tooltip": "Das Treffenintervall legt fest, wie oft Buchungsslots im Kalender angezeigt werden. Zum Beispiel bedeutet ein Intervall von 1 Stunde, dass die Slots alle Stunde angezeigt werden, während ein Intervall von 30 Minuten bedeutet, dass die Slots alle 30 Minuten angezeigt werden",
      "service_duration_tooltip": "Dies legt die Dauer Ihres Termins fest und bestimmt, wie lange er dauern wird",
      "minimum_scheduling_tooltip": "Dies legt die Mindestzeit fest, die erforderlich ist, um über das Kalender-Widget einen Termin zu buchen. Zum Beispiel können Termine bei einer Einstellung von 4 Stunden nicht innerhalb von 4 Stunden ab dem geplanten Beginn gebucht werden; Buchungen müssen mindestens 4 Stunden im Voraus erfolgen",
      "service_duration": "Service duration",
      "look_busy_title": "Sehen Sie beschäftigt aus",
      "look_busy_percentage_description": "Verberge die Anzahl der verfügbaren Slots um x%",
      "look_busy_helper_text": "Verstecken Sie einen Prozentsatz Ihrer verfügbaren Termine im Buchungsfenster, um beschäftigter und begehrt zu erscheinen",
      "maximum_bookings_per_slot_tooltip_personal": "Maximale Anzahl von Terminen pro Slot für den Benutzer zulässig"
    },
    "forms_payment": {
      "forms": "Formen",
      "forms_sub": "Legen Sie die Einstellungen für das Formular zur Terminplanung fest.",
      "select_form": "Auswählen Formular",
      "sticky_contacts": "Felder vorab ausfüllen (gespeicherte Kontakte)",
      "confirmation_page": "Bestätigungsseite",
      "default": "Standard",
      "redirect_url": "Umleitungs-URL",
      "thank_you": "Dankesnachricht",
      "enter_url": "Geben Sie die URL ein",
      "pixel_id": "Facebook Pixel-ID (optional)",
      "auto_confirm": "Automatische Bestätigung neuer Kalendereinladungen",
      "configure_payment": "Konfigurieren Sie Zahlungsoptionen",
      "accept_payments": "Akzeptieren Sie Zahlungen",
      "test": "Test",
      "live": "leben",
      "enable_guests": "Gäste hinzufügen",
      "add_guest_tooltip": "Durch Hinzufügen von Gästen kann Ihr Teilnehmer mehrere Teilnehmer zu demselben Termin hinzufügen",
      "add_guest_same_form_check": "Senden Sie dasselbe Formular per E-Mail an alle Gäste, um Informationen zu sammeln",
      "payment_tooltip": "Die Zahlung wird nur für den Hauptteilnehmer in Betracht gezogen und nicht für die Gäste",
      "default_form": "Standard (Vorname, Nachname, E-Mail, Telefon, Anmerkungen)",
      "select_form_tooltip": "Wenn Sie zusätzliche Informationen als den Standard-Vor- und Nachnamen, die E-Mail-Adresse und Telefonnummer von Personen sammeln möchten, die diesen Kalender nutzen, erstellen Sie ein benutzerdefiniertes Formular in Sites > Forms > Builder und wählen Sie das Formular dann über das Dropdown-Menü unten aus",
      "confirmation_page_tooltip": "Sobald jemand eine Buchung vornimmt, können Sie wählen, ob Sie ihnen eine benutzerdefinierte Nachricht auf derselben Seite anzeigen lassen (Standard) oder sie auf eine von Ihnen gewählte Seite umleiten (Weiterleitung)",
      "add_guest_collect_email": "Mit Name und E-Mail",
      "add_guest_count_only": "Zähle nur",
      "custom_email_notification": "Sende individuelles Formular an Gäste",
      "deposit_amount_error": "Einzahlungsbetrag muss kleiner als Gesamtbetrag sein",
      "deposit_percentage_error": "Einzahlungsprozentsatz muss weniger als 100% betragen",
      "partial_payment_tooltip": "Ermöglichen Sie Teilzahlungen, um die Anzahlungsbeträge von Kunden während des Terminbuchungsprozesses einzuziehen",
      "partial_payment_enable_error": "Der Gesamtbetrag, der eingegeben wird, muss größer als 0 sein, um Teilzahlungen zu ermöglichen",
      "payment_disable_text_integration": "Integrieren Sie einen Zahlungsanbieter unter Zahlungen > Integrationen, um Zahlungen zu akzeptieren",
      "payment_disable_text_recurring": "Die Option zur Zahlungsannahme ist für wiederkehrende Meetings nicht verfügbar. Schalten Sie die Wiederholung aus, um Zahlungen zu aktivieren",
      "date_and_time_selector": "Datum- und Zeitauswahl",
      "form_selector": "Form",
      "order_step": "Schritt",
      "widget_order_label": "Widget-Reihenfolge",
      "widget_order_tool_tip": "Ordnen Sie die Reihenfolge des 'Datums- und Zeit-Auswahlfelds' sowie des 'Formulars' im Kalender-Widget neu an. Ziehen Sie einfach per Drag & Drop entsprechend Ihrer Vorlieben neu an",
      "same_user_assignment_tooltip": "Um diese Einstellung zu aktivieren, muss das Formular zuerst auf dem Widget platziert werden. Ordnen Sie das Widget neu an, um das Formular an die erste Stelle zu setzen und diese Einstellung zu aktivieren",
      "widget_re_order_tooltip": "Die Neuanordnung ist nur mit dem Neo-Widget-Stil verfügbar. Wechseln Sie zu Neo, um die Neuanordnung zu aktivieren",
      "widget_re_order_disabled_tooltip": "Um die Widget-Reihenfolge neu anzuordnen, deaktivieren Sie bitte 'Neue Terminpräferenz (Immer mit dem zugewiesenen Benutzer des Kontakts buchen)' im Abschnitt Sitzungsdaten > Teammitglieder > Erweiterte Einstellungen",
      "enable_same_user_assignment": "Weisen Sie Kontakte bei jeder Terminbuchung ihren jeweiligen Kalender-Teammitgliedern zu"
    },
    "notifications": {
      "notifications": "Benachrichtigungen und zusätzliche Optionen",
      "notifications_sub": "Benachrichtigung und zusätzliche Optionen konfigurieren",
      "select_notification_type": "Wählen Sie die Art der Benachrichtigung aus",
      "notification_person": "Wer sollte diese Benachrichtigung erhalten?",
      "contact": "Kontakt",
      "assigned_user": "Zugewiesener Benutzer",
      "emails": "E-Mails",
      "alert_email_address": "Benachrichtigungs-E-Mail-Adresse",
      "ack_email": "Bestätigungsemail",
      "send_google_invites": "Erlauben Sie Google Kalender, Einladungen oder Aktualisierungs-E-Mails an Teilnehmer zu senden",
      "send_google_outlook_invites": "Erlauben Sie Google / Outlook Kalender, Einladungs- und Aktualisierungs-E-Mails an Teilnehmer zu senden",
      "assign_user_to_contact": "Weisen Sie Kontakte jedes Mal, wenn ein Termin gebucht wird, ihren entsprechenden Kalender-Teammitgliedern zu",
      "skip_assigning_user_to_contact": "Überspringe die Zuweisung des Kontakts, wenn der Kontakt bereits einem Benutzer zugewiesen ist",
      "guest_label_count": "Anzahl der Gäste",
      "guest_count_error": "Die Anzahl der Gäste sollte zwischen 1 und 100 liegen",
      "allow_cancellation": "Erlauben Sie die Stornierung des Treffens",
      "allow_reschedule": "Erlauben Sie das Neuplanen des Meetings",
      "allow_cancellation_tooltip": "Wenn aktiviert, wird ein Stornierungslink dem Abschnitt für zusätzliche Notizen hinzugefügt und in der Kalendereinladung enthalten sein. Dieser Link verfällt wie konfiguriert vor der Beginnzeit des Treffens, um den Buchenden daran zu hindern, das Treffen abzusagen",
      "allow_reschedule_tooltip": "Bei Aktivierung wird ein Verschiebungslink im Abschnitt für zusätzliche Notizen hinzugefügt und in die Kalendereinladung aufgenommen. Dieser Link läuft gemäß Konfiguration vor der geplanten Beginnzeit des Meetings ab, was es dem Buchenden verhindert, das Meeting umzuplanen",
      "cancellation_link_expiry": "Stornierungslink verfällt",
      "reschedule_link_expiry": "Der Terminierungslink wird ablaufen",
      "before_meeting": "vor dem Treffen",
      "cancellation_and_reschedule_policy": "Stornierungs- und Umbuchungsrichtlinie:"
    },
    "connections": {
      "connections_heading": "Externe Kalendersynchronisierung",
      "connections_sub": "Synchronisiere Ereignisse mit externem Kalender",
      "link_to_calendar": "Link zum Kalender",
      "sync_option": "Sync-Option",
      "execute_automation": "Führe Automatisierung aus",
      "fire_workflow": "Fire Workflow Triggers für über die 2-Wege-Synchronisierung gespeicherte Ereignisse",
      "reserve_with_google": "Reserviere mit Google",
      "connect_lsa": "Verbinden Sie sich mit Ihrem lokalen Dienstleistungsanzeigen-Konto",
      "reserve_error": "Derzeit wird \"Reserve with Google\" nur für \"Home Services\", \"Real Estate\" und \"Legal Services\" (örtliche Dienstleistungsanbieter) in den USA unterstützt",
      "one_way": "Eine Richtung",
      "two_way": "Zweirichtung",
      "smart": "klug",
      "confirm_remove": "Sind Sie sicher, dass Sie den Google-Kalender entfernen möchten?",
      "read_only_access": "Sie haben keinen Schreibzugriff auf diesen Kalender. Bitte wählen Sie einen anderen Kalender aus",
      "reserve_with_google_desc": "Dieser Kalenderservice wird nur bei erfolgreicher Integration in Reserve bei Google aufgeführt",
      "check_integration": "Überprüfen Sie Ihren Integrationsstatus"
    },
    "extras": {
      "extras": "Anpassungen",
      "extras_sub": "Stelle Widget-Stil und andere Einstellungen ein",
      "calendar_widget_style": "Kalender-Widget-Design",
      "neo": "Neo",
      "classic": "Klassisch",
      "insert_custom_code": "Fügen Sie benutzerdefinierten Code ein",
      "show_seats_per_slot": "Sitze pro Slot anzeigen",
      "allow_reschedule_meeting": "Termine umplanen erlauben",
      "allow_cancelling_meeting": "Erlaube das Absagen des Meetings",
      "additional_notes": "Zusätzliche Anmerkungen",
      "cancellation_and_reschedule": "Stornierung und Umbuchung:",
      "allow_staff": "Erlaube Personalauswahl während der Buchung",
      "code_block_tooltip": "Drittanbieter-Skripte innerhalb des HTML-Blocks können leistungsstarke Funktionen bieten, bringen jedoch auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten mit sich. Daher empfehlen wir Ihnen, den Inhalt dieses Skripts vor dem Speichern in den Kalender zu überprüfen"
    }
  },
  "add_guest": {
    "guest_label": "Gast/Gäste",
    "search_placeholder": "Suche aus Kontakten",
    "add_new": "Neu(e) hinzufügen",
    "save": "Speichern",
    "close": "Schließen",
    "name": "Name",
    "phone": "Telefon",
    "email": "Email"
  },
  "languages": {
    "english": "Englisch",
    "dutch": "Niederländisch",
    "french": "Französisch",
    "german": "Deutsch",
    "hungarian": "Ungarisch",
    "italian": "Italienisch",
    "polish": "Polnisch",
    "portuguese_brazil": "Portugiesisch (Brasilien)",
    "portuguese_portugal": "Portugiesisch (Portugal)",
    "spanish": "Spanisch",
    "danish": "Dänisch"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Service-Menü Details",
    "select_groups_header": "Auswahl von Dienstleistungen",
    "arrange_groups_header": "Organisieren Sie Ihre Dienstleistungen",
    "new_service_menu": "Neues Service-Menü",
    "service_menu_sub_title": "Erstellen Sie in nur wenigen Klicks eine personalisierte Service-Menükarte für Ihre Kunden",
    "quick_tip": "Sie können Gruppen zum Servicemenü hinzufügen",
    "service_menu_name": "Service-Menüname",
    "service_menu_description": "Beschreibung",
    "service_menu_slug": "Service-URL",
    "select_groups_calendars": "Wählen Sie Gruppen und Kalender aus",
    "select_groups_calendars_desc": "Fügen Sie Dienstleistungen zu Ihrer Servicekarte hinzu",
    "select_all": "Alle wählen",
    "arrange_groups": "Reihenfolge der Dienstleistungen organisieren",
    "arrange_groups_desc": "Bestimmen Sie die Reihenfolge der Dienste im Service-Menü",
    "arrange_services_quicktip": "Um Kalender innerhalb einer Gruppe neu anzuordnen, gehen Sie zu Gruppen > Optionen > Kalender neu anordnen",
    "enable_add_guest_desc": "Ermöglicht Kunden, zusätzliche Gäste bei der Buchung hinzuzufügen",
    "enable_multiple_service_desc": "Ermöglicht Kunden, mehrere Dienstleistungen zu einem Termin zu buchen",
    "enable_staff_selection_desc": "Ermöglicht Kunden, bei einer Buchung bevorzugtes Personal auszuwählen",
    "enable_add_guest": "Erlaube das Hinzufügen von Gästen",
    "enable_multiple_service": "Erlaube mehrfache Dienstauswahl",
    "enable_staff_selection": "Aktiviere Mitarbeiterauswahl",
    "additional_options": "Zusätzliche Optionen",
    "code_block_tooltip": "Drittanbieter-Skripte im HTML-Block können leistungsstarke Funktionen bieten, bringen aber auch Risiken für Privatsphäre, Sicherheit, Leistung und Seitenverhalten mit sich. Aus diesem Grund empfehlen wir Ihnen, den Inhalt dieses Skripts vor dem Speichern im Service-Menü zu überprüfen.",
    "custom_code_placeholder": "Bitte hier benutzerdefinierten Code eingeben",
    "insert_custom_code": "Fügen Sie benutzerdefinierten Code ein",
    "no_service": "Kein Dienst gefunden",
    "no_service_desc": "Keine Dienste gefunden. Erstellen Sie Dienste und fügen Sie sie Gruppen hinzu, um ein Service-Menü zu erstellen",
    "learn_more": "Erfahre mehr",
    "no_service_selected": "Kein Dienst ausgewählt",
    "create_service_calendars": "Service-Kalender erstellen",
    "alert_description": "Um ein Service-Menü zu erstellen, müssen Sie Servikalender erstellen und sie zu Gruppen hinzufügen",
    "dont_show_again": "Nicht erneut anzeigen",
    "service_name_required": "Der Name des Service-Menüs ist erforderlich",
    "slug_already_taken": "Diese Schnecke ist bereits vergeben",
    "slug_required": "Schnecke ist erforderlich",
    "please_enter_name": "Bitte geben Sie einen Namen ein",
    "service_name_placeholder": "Hauptmenü",
    "consent_label": "Zustimmungs-Auswahlkästchen",
    "consent_input_placeholder": "Fügen Sie den Zustimmungstext hinzu",
    "consent_tooltip": "Aktivieren Sie diese Option, um die Einwilligung der Kontakte einzuholen, wenn sie während des Terminbuchungsprozesses ihre Telefonnummer angeben"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Kalenderwidget anpassen",
    "works_with_neo_widget": "Funktioniert nur mit Neo-Widget",
    "customise_widget_desc": "Widget-Aussehen anpassen: Primärfarbe, Hintergrundfarbe und Schaltflächentext",
    "learn_more": "Für weitere Informationen",
    "primary_settings": "Primäre Einstellungen",
    "primary_settings_desc": "Konfigurieren Sie die Primärfarbe, Hintergrundfarbe und andere Widget-Einstellungen, um Ihren Kalender-Widget anzupassen",
    "primary_color": "Primärfarbe",
    "primary_color_desc": "Definieren Sie die Farbe für Schaltflächen, Daten, Zeitslots und Aktionen",
    "background_color": "Hintergrundfarbe",
    "background_color_desc": "Setzen Sie die Hintergrundfarbe, die als Hintergrund für sowohl den Datum-Zeit-Auswähler als auch das Formular dient",
    "button_text": "Schaltflächentext",
    "button_text_desc": "Passen Sie den Text an, der auf der Schaltfläche angezeigt wird, wenn Sie einen Termin buchen",
    "calendar_title": "Kalendertitel",
    "calendar_title_desc": "Der Name deines Kalenders",
    "calendar_description": "Kalenderbeschreibung",
    "calendar_description_desc": "Die eingegebene Beschreibung",
    "calendar_details": "Kalenderdetails",
    "calendar_details_desc": "Enthält Informationen wie Dauer, Datum & Uhrzeit, wiederkehrende Details und Zeitzone",
    "reset_to_default": "Zurücksetzen auf Voreinstellungen",
    "preview_widget": "Vorschau-Widget"
  }
}
