{
  "settings": {
    "calendar": {
      "calendar_settings": "Ajustes de calendario",
      "select_calendar_type": "Selecciona el tipo de calendario",
      "add_new_calendar_group": "Añadir nuevo grupo de calendario",
      "calendar_group_description": "Agregue grupos de calendarios para agrupar varios calendarios.",
      "group": "Grupo",
      "manage_your_calendars_and_groups": "Gestiona tus calendarios y grupos",
      "groups": "Grupos",
      "create_group": "Crear un Grupo",
      "create_calendar": "Crear un Calendario",
      "calendar_name": "Nombre del Calendario",
      "group_duration": "Duracion del grupo",
      "calendar_type": "Tipo de calendario",
      "action": "Accion",
      "round_robin": "Rotativo",
      "event": "Evento",
      "group_name": "Nombre del Grupo",
      "group_description": "Descripcion del Grupo",
      "calendar_url": "URL del Calendario",
      "calendars": "Calendarios",
      "date_updated": "Fecha Actualizado",
      "status": "Estado",
      "action_dropdown": "Menú desplegable de acción",
      "edit": "Editar",
      "duplicate": "Duplicar",
      "copy_embed_code": "Copiar Codigo De Insercion",
      "copy_permanent_link": "Copiar Link Permanente",
      "copy_new_slug_link": "Copiar Link De Agenda De Citas",
      "copy_scheduling_link": "Copiar Link De Agenda De Citas (Obsoleto)",
      "deactivate_calendar": "Desactivar Calendario",
      "move_to_group": "Mover a Grupo",
      "delete_calendar": "Borrar Calendario",
      "delete_group": "Borrar",
      "delete_this_group": "Borrar este grupo",
      "activate_calendar": "Activar Calendario",
      "duration": "Duración (mins)",
      "all": "Todo",
      "draft": "Borrador",
      "active": "Activo",
      "rearrange_calendars": "Organizar Calendarios",
      "deactivate_all_calendars_in_group": "Desactivar todos los calendarios en el grupo",
      "activate_all_calendars_in_group": "Activar todos los calendarios en el grupo",
      "no_groups_found": "No se encontraron grupos de calendario que coincidan con tu búsqueda.",
      "no_groups_in_location": "Comienza a usar grupos creando un nuevo grupo de calendario.",
      "no_calendars_found": "Comienza a usar grupos creando un nuevo calendario.",
      "no_calendars_found_search": "No se encontraron calendarios que coincidan con tu búsqueda.",
      "delete_calendar_message": "Estás a punto de eliminar el calendario. Al hacer clic en eliminar, también se eliminarán todas sus citas.",
      "delete_calendar_message_title": "Borrar este calendario?",
      "no_calendars_found_title": "No se encontraron calendarios.",
      "no_groups_in_location_title": "No se encontraron grupos.",
      "delete_group_confirmation_title": "Borrar este grupo?",
      "delete_group_confirmation": "¿Estás seguro de que deseas eliminar el grupo seleccionado?",
      "delete_group_confirmation_checkbox_title": "Eliminar calendarios y citas asociadas",
      "delete_group_confirmation_checkbox_description": "Seleccionar esta opción también eliminará los calendarios en este grupo, junto con todas las citas en esos calendarios",
      "group_status_message_title": "{status} este Grupo?",
      "calendar_status_message_title": "{status} este Calendario?",
      "calendar_status_message": "Estas a punto de {status} Calendario",
      "group_status_message": "Estas a punto de {status} Grupo",
      "new_calendar": "Nuevo Calendario",
      "coming_soon": "Próximamente",
      "select": "Seleccionar",
      "15mins": "15 minutos",
      "cancel": "Cancelar",
      "save": "Guardar",
      "create": "Crear",
      "select_calendar_group": "Seleccionar grupo de calendario",
      "move_calendar_confirmation": "¿Estás seguro de que deseas mover este calendario a otro grupo?",
      "new_service_menu": "Nuevo Menú de Servicio",
      "service_menus": "Menú de Servicio",
      "manage_your_calendars_groups_and_service_menus": "Administra tus calendarios, grupos y menú de servicios",
      "deactivate_service_menu": "Desactivar menú de servicio",
      "delete_service_menu": "Borrar este menú de servicios",
      "delete_service_menu_confirmation": "¿Estás seguro de que quieres eliminar este menú de servicios?",
      "service_menu_status_message_title": "{status} este Menú de Servicios?",
      "service_menu_status_message": "Estás a punto de {state} Menú de Servicio",
      "no_service_menus_in_location_title": "Crea tu menú de servicios personalizado",
      "add_new_service_menu": "Agregar nuevo menú de servicios",
      "no_service_menus_in_location": "Comienza creando un nuevo menú de servicios y añadiendo Grupos a él",
      "no_service_menu_found": "No se encontró menú de servicio",
      "no_service_menu_found_desc": "Tu búsqueda no coincide con ningún menú de servicios",
      "rooms": "Habitaciones",
      "equipments": "Equipos",
      "share_otl": "Comparte tu disponibilidad en todo momento con un enlace único que caduca después de una reserva, garantizando un acceso controlado",
      "generate_link": "Generar nuevo enlace",
      "share_schedule_link": "El enlace de programación se determina por el slug. Ajusta el slug, y el enlace de programación se adapta automáticamente a la modificación",
      "share_permanent_link": "Ideal para embudos, redirecciones de sitios web o anuncios, el enlace permanente permanece constante, sin verse afectado por cambios en el slug",
      "copy": "Copiar",
      "share_embed_code": "Coloca este código en tu HTML donde quieras que aparezca tu widget de {embedType}",
      "scheduling_link": "Enlace de programación",
      "permanent_link": "Enlace permanente",
      "one_time_link": "Enlace de una sola vez",
      "embed_code": "Código de inserción",
      "loading": "Cargando...",
      "round_robin_booking": "Ronda Robin",
      "round_robin_description": "Distribuye las citas entre los miembros del equipo en un orden rotativo",
      "round_robin_example": "Por ejemplo: llamadas de ventas, sesiones de incorporación",
      "unassigned_booking": "Calendario de eventos",
      "unassigned_description": "Para programar eventos físicos sin asociación de anfitrión",
      "unassigned_example": "Por ejemplo: Conferencias, seminarios públicos, exposiciones",
      "class_booking": "Reserva de clases",
      "class_booking_description": "Un anfitrión se reúne con varios participantes",
      "class_booking_example": "Por ejemplo: Seminarios web, formaciones en grupo, clases en línea",
      "collective_booking": "Reserva colectiva",
      "collective_booking_description": "Varios anfitriones se reúnen con un participante",
      "collective_booking_example": "Por ejemplo: entrevistas grupales, revisiones de comités",
      "service_booking": "Reserva de servicio",
      "service_booking_description": "SProgramación sin interrupciones para empresas basadas en servicios",
      "service_booking_example": "Por ejemplo: citas de spa, servicios de reparación, consultas",
      "personal_booking": "Reserva Personal",
      "personal_booking_description": "Programa reuniones individuales con un miembro específico del equipo",
      "personal_booking_example": "Por ejemplo: Reuniones con clientes, consultas privadas",
      "personal": "Personal",
      "explore_more_types": "Explorar más tipos",
      "scheduling_type": "Tipo de programación",
      "scheduling_type_description": "Elija un tipo de programación para su nuevo calendario"
    },
    "preferences": {
      "preferences": "Preferencias",
      "my_preferences": "Mis Preferencias",
      "account_preferences": "Prefencias de la Cuenta",
      "user_preferences": "Preferencias de usuario",
      "set_preferences": "Establece tus preferencias para tu cuenta.",
      "scheduling_options": "Opciones de programación para citas.",
      "meeting_location": "Lugar de cita",
      "zoom": "Zoom",
      "save_preferences": "Guardar Preferencias",
      "in_app_preferences": "Prefencias de la aplicacion",
      "set_start_day": "Establecer preferencias como día de inicio para la app.",
      "view_options": "Ver Opciones",
      "week_starts_on": "La semana empieza el",
      "sunday": "Domingo",
      "widget_preferences": "Preferencias de Widget",
      "set_widget_language": "Establecer preferencias como idioma para el widget.",
      "language_and_region": "Lenguaje y Region",
      "language": "Lenguaje",
      "english": "Ingles",
      "time_format": "Format de hora",
      "time_format_value": "1:30 PM",
      "monday": "Lunes",
      "custom_meeting_location": "Ubicación personalizada de la cita"
    },
    "availability": {
      "availability": "Disponibilidad",
      "my_availability": "Mi Disponibilidad",
      "account_availability": "Disponibilidad de la Cuenta",
      "timezone": "Zona Horaria",
      "working_hours": "Horario Laboral",
      "set_recurring_hours": "Establecer las horas laborales recurrentes para las reuniones",
      "sunday": "Domingo",
      "monday": "Lunes",
      "tuesday": "Martes",
      "wednesday": "Miércoles",
      "thursday": "Jueves",
      "friday": "Viernes",
      "saturday": "Sábado",
      "widget_preferences": "Preferencias del Widget",
      "set_widget_language": "Establecer las preferencias del idioma del Widget",
      "language_and_region": "Idioma y Región",
      "language": "Idioma",
      "english": "Inglés",
      "copy_to_all": "Copiar a todos",
      "save_availability": "Guardar Disponibilidad",
      "add_time": "Agregar Hora",
      "select_time": "Seleccionar Hora"
    },
    "connections": {
      "connections": "Conexiones",
      "connect": "Conectar",
      "conferencing": "Conferencias",
      "my_connections": "Mis Conexiones",
      "account_connections": "Conexiones de la Cuenta",
      "connection_preferences": "Preferencias de Conexion",
      "manage_calendars_conferencing_channels": "Administrar calendarios y canales de conferencias",
      "main_integration_calendar": "Integracion de Calendario Principal",
      "event_integration_calendar": "Los eventos creados en un calendario del sistema del cual eres parte también se crearán en este calendario integrado",
      "2way_sync": "Sincronización bidireccional: crear eventos del sistema a partir de eventos reservados en el calendario integrado principal",
      "calendars": "Calendarios",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Chequear conflictos",
      "choose_accounts_to_connect": "Escoge que cuenta deseas conectar",
      "payments": "Pagos",
      "stripe": "Stripe",
      "ads": "Anuncios",
      "local_service_ads": "Anuncios de Servicios Locales",
      "fire_workflow": "Ejecutar automatizaciones para citas reservadas a través de sincronización bidireccional",
      "connect_google_outlook": "Por favor, conecta tu cuenta de Google o Outlook para poder escoger el calendario primario",
      "execute_automation": "Ejecutar automatización",
      "save": "Guardar",
      "lsa": "Anuncios de Servicios Locales",
      "no_write_access": "No tienes acceso de escritura",
      "connection_remove_confirmation": "¿Estás seguro de que deseas eliminar esta conexión?",
      "active": "Activo"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Selecciona un contacto",
    "start_time": "Hora de inicio",
    "end_time": "Hora de finalizacion",
    "unassigned": "Sin asignar",
    "search_by_name_email_phone_or_company": "Buscar por nombre, correo, numero de telefono, o compañia",
    "add_new": "Agregar Nuevo",
    "pick_from_available_contacts": "O selecciona de los contactos disponibles",
    "book_appointment": "Agendar Cita",
    "add_blocked_off_time": "Agregar tiempo bloqueado",
    "recommended_timezones": "Zonas horarias recomendadas",
    "all_timezones": "Todas las zonas horarias",
    "calendar": "Calendario",
    "repeat": "Repetir",
    "date_and_time": "Fecha y Hora",
    "close": "Cerrar",
    "team_member": "Miembro del equipo",
    "recurring_booking": "Cita Recurrente",
    "one_slot_booking": "Cita de una sola vacante",
    "event_type": "Tipo de evento",
    "occurrences_booked": "citas serán agendadas",
    "repeats": "Se repite",
    "booking_details": "Detalles de la Cita",
    "showing_slots_in_this_timezone": "Mostrando vacantes en esta zona horaria:",
    "update_contact_tz": "Tambien actualiza la zona horaria del contacto",
    "date": "Fecha",
    "slot": "Vacante",
    "calendar_default": "Calendario Primario",
    "custom": "Personalizado",
    "meeting_notes": "(ej) Notas de tu siguiente cita",
    "out_of_office": "(ej) Estaré fuera de la oficina por la semana!",
    "standard": "Por defecto",
    "appointment_title": "Titulo de Cita",
    "event_title": "Titulo del Evento",
    "appt_with_bob": "(ej) Cita con Bob",
    "vacation": "(ej) Vacaciones",
    "show_notes": "Mostrar notas",
    "hide_notes": "Ocultar notas",
    "additional_preferences": "Preferencias adicionales",
    "meeting_location": "Ubicación de la cita",
    "appointment_status": "Estado de la cita",
    "appt_description": "Descripcion de la cita",
    "recurring_details": "Detalles Recurrentes",
    "event_description": "Descripción del evento",
    "in_contact_timezone": "En la zona horaria del contacto.",
    "vacation_message": "¿Te vas de vacaciones? ¿Tomando un tiempo libre? Bloquea las vacantes en tu calendario para evitar que los clientes programen citas. Las citas existentes aún permanecerán en tu calendario.",
    "contact": "Contacto",
    "delete": "Eliminar",
    "cancel": "Cancelar",
    "back": "Atras",
    "pick_available_contacts": "Ó selecciona de los contactos disponibles",
    "no_contacts_found": "Contactos no encontrados",
    "found": "Encontrado",
    "select_one": "Selecciona Uno",
    "tz_tooltip": "Esto solo importa si estás utilizando valores personalizados, como appointment_start_time (hora de inicio de la cita), appointment_end_time (hora de finalización de la cita), appointment_timezone (zona horaria de la cita), etc., en tu comunicación saliente con tus clientes. Lo que simplemente hace es convertir los detalles mencionados anteriormente en su hora local.",
    "meeting_location_tooltip": "La ubicación de la cita se configurará según lo establecido en el calendario.",
    "appt_meeting_location_tooltip": "El usuario puede establecer la ubicación de la reunión específica para la cita.",
    "gmeet_tooltip": "Por favor, asegúrese de que el miembro del equipo correspondiente tenga un calendario de Google establecido como su calendario principal",
    "zoom_tooltip": "Por favor, asegúrese de que el miembro del equipo correspondiente tenga Zoom integrado",
    "enter_meeting_location": "Ingresar ubicación de la cita",
    "save_appointment": "Agendar Cita",
    "save_event": "Agendar Cita",
    "search_contacts": "Buscar por nombre, email, numero de telefono o compañia",
    "recurring_slots": "{recurringSlotsLength}/{count}",
    "custom_timezone": "Zona horaria personalizada",
    "system_timezone": "Zona horaria del sistema",
    "contact_timezone": "Zona horaria del contacto",
    "account_timezone": "Zona horaria de la cuenta",
    "an_error_occurred": "Ocurrió un error"
  },
  "popup": {
    "calendar": "Calendario",
    "appointment_owner": "Propietario de la cita",
    "source": "Fuente",
    "booked_by": "Agendada por",
    "view_activity_log": "Ver Registro De Actividad",
    "status": "Estado",
    "cancel_appointment": "Cancelar Cita",
    "cancel_all": "Cancelar todo",
    "google_event": "Evento de Google",
    "outlook_event": "Evento de Outook",
    "blocked_slot": "Vacante Bloqueada",
    "appointment": "Cita",
    "confirmed": "Confirmado",
    "unconfirmed": "No confirmado",
    "cancelled": "Cancelado",
    "showed": "Asistió",
    "no_show": "Ausencia",
    "invalid": "Invalido",
    "more": "Mas",
    "less": "Menos",
    "edit": "Editar",
    "reschedule_all": "Reagendar todo",
    "guests": "Huéspedes",
    "guest_count": "Cuenta de invitados"
  },
  "activity_log": {
    "activity_log": "Registro de actividad",
    "date": "Fecha",
    "filter_by": "Filtrar por",
    "calendar_update": "Actualización de calendario",
    "start_time_update": "Actualización de hora de inicio",
    "end_time_update": "Actualización de hora de finalización",
    "title_update": "Actualización de título",
    "notes_update": "Actualización de notas",
    "appointment_status_update": "Actualización de estado de cita",
    "meeting_location_update": "Actualización de ubicación de reunión",
    "google_sync_update": "Actualización de sincronización con Google",
    "outlook_sync_update": "Actualización de sincronización con Outlook",
    "assigned_user_update": "Actualización de usuario asignado",
    "appointment_status": "Estado de cita",
    "assigned_user": "Usuario asignado",
    "uploaded_to": "Subido a",
    "cancelled": "Cancelado",
    "edited": "editado",
    "other_edits": "Otras ediciones",
    "view_details": "Ver detalles",
    "all": "Todo"
  },
  "appointment_list": {
    "group_all": "TODO",
    "group_primary": "PRIMARIO",
    "group_user": "USUARIOS",
    "group_team": "GRUPOS",
    "group_calendar": "CALENDARIOS",
    "all_appointments": "Todas las citas",
    "my_calendar": "Mi calendario",
    "status_all": "Todos",
    "status_confirmed": "Confirmado",
    "status_new": "Nuevo (Acción requerida)",
    "status_show": "mostró",
    "status_no_show": "sin presentarse",
    "status_cancelled": "Cancelado",
    "status_invalid": "Inválido",
    "filter_date_added_desc": "Fecha agregada (descendente)",
    "filter_start_added_desc": "Fecha de inicio (Desc)",
    "filter_start_added_asc": "Fecha de inicio ascendente a partir de hoy",
    "button_new_appointment": "Nuevo nombramiento",
    "name": "Nombre",
    "title": "Título",
    "requested_time": "Tiempo Solicitado",
    "date_added": "Fecha añadida",
    "calendar": "Calendario",
    "appointment_owner": "Propietario de cita",
    "notes": "Notas.",
    "status": "Estado.",
    "action_view_detail": "Ver detalles",
    "action_view_notes": "Ver notas",
    "action_edit": "Editar.",
    "action_delete": "Borrar",
    "action_reschedule": "Reprogramar",
    "page_previous": "Anterior",
    "page_next": "Siguiente",
    "appointment_notes": "Notas de Citas",
    "close": "Cerrar",
    "confirm_delete_header": "Eliminar cita",
    "confirm_delete_message": "¿Estás seguro de que quieres eliminar esta cita?",
    "confirm_delete_button": "Borrar",
    "confirm_update_header": "Actualizar estado de la cita.",
    "confirm_update_confirmed_message": "¿Estás seguro de que quieres marcar esta cita como confirmada?",
    "confirm_update_invalid_message": "¿Estás seguro de que quieres marcar esta cita como inválida?",
    "confirm_update_showed_message": "¿Estás seguro de que quieres marcar esta cita como presentada?",
    "confirm_update_noshow_message": "¿Estás seguro/a de que deseas marcar esta cita como 'no asistió'?",
    "confirm_update_cancelled_message": "¿Estás seguro de que quieres marcar esta cita como cancelada?",
    "confirm_update_button": "Actualización",
    "empty_state_title": "No se encontraron citas",
    "empty_state_description": "No se encontraron citas",
    "disable_menu_warning": "Actualmente, no tienes permiso para ver o editar los detalles de la cita, ya que el contacto asociado no está asignado a ti. Por favor, ponte en contacto con tu administrador para obtener más ayuda",
    "no_contact_warning": "YNo puedes acceder a la información de contacto en este momento. Esto podría ser porque el contacto no está asignado a ti. Por favor, comunícate con tu administrador para obtener ayuda",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Reprogramado"
  },
  "create_calendar": {
    "calendar_name": "Nombre del calendario",
    "calendar_logo": "Logotipo del calendario",
    "select_team_member": "Seleccionar miembros del equipo",
    "eg_outbound_reach": "(eg) Alcance saliente",
    "description": "Descripción",
    "amount": "Monto",
    "description_placeholder": "Escribir descripción",
    "meeting_duration": "Duración de la reunión",
    "seats_per_class": "Asientos por clase",
    "group": "Grupo",
    "custom_url": "URL personalizado",
    "meeting_invite_title": "Título de la invitación a la reunión",
    "event_color": "Color del evento",
    "availability_type": "Tipo de disponibilidad",
    "deposit_amount": "Cantidad del depósito",
    "collect_deposit_amount": "Aceptar pago parcial",
    "percentage": "Porcentaje",
    "total_amount": "Cantidad total",
    "reschedule_preference": "Preferencia de reprogramación",
    "reschedule_preference_subtext": "Si un contacto reprograma una cita:",
    "reassign_through_round_robin": "Reasignar a través de round robin",
    "keep_same_assinged_user": "Mantén el mismo dueño de cita",
    "new_appointment_preference": "Nueva Preferencia de Cita",
    "new_appointment_preference_text": "Siempre reservar con el usuario asignado al contacto",
    "new_appointment_preference_subtext": "Reservar nuevas citas con el usuario asignado al contacto en lugar de utilizar una programación en ronda"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Consejo rápido",
      "timezone_change_tip": "Las zonas horarias se pueden cambiar en cualquier momento en las preferencias de zona horaria",
      "save": "Guardar",
      "close": "Cerrar",
      "meeting_details": "Detalles de la reunión",
      "availability": "Disponibilidad",
      "forms_and_payment": "Formularios y Pagos",
      "notifications_and_additional_options": "Notificaciones y Opciones Adicionales",
      "connections": "Conexiones",
      "customizations": "Personalizaciones",
      "create": "Crear",
      "edit": "Editar"
    },
    "meeting_details": {
      "meeting_location": "Ubicación de la reunión",
      "enter_meeting_location": "Ingresar ubicación de la reunión",
      "click_to_upload": "Haz clic para cargar",
      "square": "Cuadrado",
      "circle": "Círculo",
      "remove_logo": "Quitar logo",
      "meeting_invite_title_tooltip": "Este es el título para el evento del calendario que se muestra en Google Calendar, Outlook, Apple Calendar, etc",
      "event_color_tooltip": "Elige un color para este evento de reserva. Los colores se sincronizan con los eventos en los calendarios de Google",
      "enter_a_name": "Por favor, ingresa un nombre",
      "enter_an_event_title": "Por favor, ingrese un título de evento",
      "high_priority": "Alta Prioridad",
      "medium_priority": "Prioridad Media",
      "low_priority": "Baja prioridad",
      "custom": "Personalizado",
      "zoom_not_added": "El usuario aún no ha conectado su cuenta de Zoom",
      "opt_avail": "Optimizar para disponibilidad",
      "opt_equal": "Optimizar para una distribución equitativa",
      "team_members": "Seleccionar miembros del equipo",
      "team_member": "Seleccionar miembro del equipo",
      "meeting_location_required": "Se requiere lugar de reunión",
      "team_member_disabled_recurring_tooltip": "Las reuniones recurrentes solo permiten a un miembro del equipo. Para agregar múltiples miembros del equipo, por favor desactiva las reuniones recurrentes.",
      "team_member_disabled_class_booking_tooltip": "Solo un miembro del equipo puede ser añadido al calendario de reservas de clases",
      "enable_contact_assigned_to_setting": "Para habilitar esta configuración, el Formulario debe ser el primero en el orden de los widgets. Por favor, reorganiza el orden de los widgets en la pestaña Formularios y Pagos",
      "reschedule_preference_setting_tooltip": "Elige a quién se asignará la cita cuando un contacto reprograma desde el widget de reservas",
      "appointment_preference_tooltip": "Elige si las nuevas citas siempre deben reservarse con el usuario asignado al contacto. Si un contacto tiene un usuario asignado, ese usuario tendrá preferencia; de lo contrario, se asignará de manera equitativa. Consejo: Desactiva 'Permitir Selección de Personal' para evitar que los reservadores cambien al miembro del personal en el widget de reserva",
      "add_location": "Agregar ubicación",
      "multiple_locations_neo": "Las ubicaciones de reunión múltiples solo son compatibles con el Widget Neo con el Formulario Predeterminado",
      "make_owner": "Hacer propietario",
      "only_one_team_member": "Solo se permite un miembro del equipo cuando se agregan múltiples ubicaciones",
      "only_one_team_member_personal_calendar": "Solo se permite un miembro del equipo para hacer reservas personales"
    },
    "availability": {
      "my_availability": "Mi disponibilidad",
      "set_availability": "Establezca su disponibilidad para el calendario aquí",
      "standard": "Estándar",
      "custom": "Personalizado",
      "to": "A",
      "copy_to_all": "Copia a todos",
      "add_time": "Agregar tiempo",
      "recurring_meeting": "Reunión periódica",
      "recurring_info": "Solo un usuario por calendario para reservas recurrentes",
      "repeat": "Repetir",
      "times_to_repeat": "Veces a repetir",
      "slots_unavailable": "¿Si no hay disponibles tragamonedas?",
      "overlap_status": "Estado de cita para intervalos superpuestos",
      "meeting_interval": "Intervalo de reunión",
      "meeting_duration": "Duración de la reunión",
      "minimum_scheduling": "Aviso mínimo de programación",
      "date_range": "Rango de fechas",
      "maximum_bookings_per_day": "Máximo número de reservas por día",
      "maximum_bookings_per_slot": "Máximo de reservas por franja horaria",
      "maximum_bookings_per_slot_per_user": "Máximo de reservas por horario (por usuario)",
      "seats_per_class": "Asientos por clase",
      "pre_buffer_time": "Tiempo de prealmacenamiento",
      "post_buffer_time": "Tiempo de amortiguación después de publicar",
      "pre_buffer_tooltip": "El Pre-Buffer es tiempo adicional que se puede agregar antes de una cita, permitiendo tener tiempo extra para prepararse antes de cualquier reunión",
      "post_buffer_tooltip": "El post-buffer es tiempo adicional que se puede agregar después de una cita, permitiendo más tiempo para concluir",
      "buffer_time": "Tiempo de amortiguación",
      "daily": "Diario",
      "weekly": "Semanal",
      "monthly": "Mensualmente",
      "skip_booking": "Ignorar reservas en horarios no disponibles",
      "continue_booking": "Continuar reserva",
      "book_next_available": "Reservar el próximo horario disponible",
      "confirmed": "Confirmado",
      "unconfirmed": "Sin confirmar",
      "enter_valid_hours": "Por favor, ingresa horarios de apertura válidos",
      "enter_valid_avail": "Por favor, ingrese una disponibilidad personalizada válida",
      "availability_type_tooltip": "Elige Disponibilidad Estándar para reservas regulares ofrecidas semanalmente. Elige Personalizado para reservas solo disponibles en fechas específicas",
      "meeting_interval_tooltip": "El intervalo de reunión refleja la cantidad de tiempo entre los espacios de reserva que se mostrarán en el calendario. Para un evento de 30 minutos que debería estar disponible al comienzo de cada hora, el intervalo sería de 1 hora. Para un evento de 30 minutos que debería estar disponible cada 30 minutos, el intervalo sería de 30 minutos",
      "date_range_tooltip": "Los eventos se pueden programar durante los próximos n días",
      "maximum_bookings_per_day_tooltip": "Número máximo de citas permitidas por día en este calendario",
      "maximum_bookings_per_slot_tooltip": "Número máximo de citas permitidas por franja horaria para el usuario (Si el número de citas excede la cantidad permitida para la franja horaria dada por el usuario, entonces se cambia al siguiente usuario en la cola circular o se marca la franja horaria como no disponible para que el prospecto la reserve)",
      "add_days": "Agregar días",
      "month_on_day": "Mensualmente el día",
      "month_on_last_day": "Mensualmente el último día",
      "day": "Día",
      "week": "Semana",
      "month": "mes",
      "mins": "Minutos",
      "hours": "Horas",
      "days": "Días",
      "weeks": "Semanas",
      "months": "Meses",
      "meeting_interval_error": "El intervalo de la reunión debe ser entre 5 minutos y 12 horas",
      "meeting_duration_error": "La duración de la reunión debe estar entre 1 minuto y 12 horas",
      "buffer_time_error": "El tiempo de amortiguación debe ser inferior a 12 horas",
      "subTitle": "Elija la fecha para establecer horarios específicos",
      "heading_1": "Horas semanales disponibles",
      "tooltip_1": "Establezca su horario de disponibilidad semanal. Estas son las horas estándar que se aplicarán de manera consistente cada semana",
      "heading_2": "¿Cuándo estás disponible?",
      "unavailable_label": "No disponible",
      "tooltip_2": "Ajusta tu horario para fechas específicas. Sobrescribe tus horas regulares semanales marcando disponibilidad o indisponibilidad para fechas seleccionadas",
      "date_specific_level": "Agregar horas específicas de fecha",
      "specific_hours_button": "Horas específicas de fecha",
      "overlap_error": "Los intervalos de tiempo no pueden solaparse",
      "apply": "Aplicar",
      "copy_times_to": "Copiar veces a",
      "no_date_specific_heading": "Sin fecha ni hora específica agregada",
      "no_date_specific_description": "Puede agregar/quitar una fecha y hora específica a su disponibilidad",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "Para incluir una hora específica para una fecha en particular, asegúrate de desactivar las reuniones recurrentes",
      "recurring_disable_event_calendar_conditions": "Las reuniones recurrentes están desactivadas. Para activarlas, asegúrate de que el pago esté desactivado y no se hayan añadido horas específicas de fecha",
      "service_interval": "Intervalo de servicio",
      "service_interval_tooltip": "El intervalo de reunión establece con qué frecuencia aparecen los espacios para reservar en el calendario. Por ejemplo, para un evento de 30 minutos, un intervalo de 1 hora significa que los espacios aparecen cada hora. Un intervalo de 30 minutos significa que los espacios aparecen cada 30 minutos",
      "service_duration_tooltip": "Esto establece la duración de tu cita, determinando cuánto tiempo durará",
      "minimum_scheduling_tooltip": "Esto establece el tiempo mínimo requerido para reservar una cita a través del widget de calendario. Por ejemplo, si se establece en 4 horas, las citas no pueden ser reservadas dentro de las 4 horas previas a su horario de inicio; las reservas deben ser realizadas al menos con 4 horas de anticipación",
      "service_duration": "Service duration",
      "look_busy_title": "Parecer ocupado",
      "look_busy_percentage_description": "Ocultar el número de espacios disponibles en un x%",
      "look_busy_helper_text": "Ocultar un porcentaje de tus franjas horarias disponibles en el widget de reserva para parecer más ocupado y en alta demanda",
      "maximum_bookings_per_slot_tooltip_personal": "Número máximo de citas permitidas por ranura para el usuario"
    },
    "forms_payment": {
      "forms": "Formularios",
      "forms_sub": "Establecer preferencias del formulario de programación de publicaciones",
      "select_form": "Seleccione formulario",
      "sticky_contacts": "Pre-cargar campos (contactos almacenados)",
      "confirmation_page": "Página de confirmación",
      "default": "Defecto",
      "redirect_url": "URL de redireccionamiento",
      "thank_you": "Mensaje de agradecimiento",
      "enter_url": "Ingresa la URL",
      "pixel_id": "ID del píxel de Facebook (opcional)",
      "auto_confirm": "Auto-confirmar nuevas reuniones de calendario",
      "configure_payment": "Configurar opciones de pago",
      "accept_payments": "Aceptar pagos",
      "test": "Prueba",
      "live": "Vivir",
      "enable_guests": "Agregar Invitados",
      "add_guest_tooltip": "Añadir invitado permitirá que tu asistente añada varios participantes a la misma cita",
      "add_guest_same_form_check": "Enviar el mismo formulario para recopilar información por correo a todos los invitados",
      "payment_tooltip": "El pago solo se consideraría para el asistente principal y no para los invitados",
      "default_form": "Predeterminado (Nombre, Apellido, Email, Teléfono, Notas)",
      "select_form_tooltip": "Si deseas recopilar más información que el estándar de Nombre, Apellido, Correo Electrónico y Teléfono de aquellos que reservan en este calendario, crea un formulario personalizado en Sitios > Formularios > Generador y luego selecciona el formulario en el menú desplegable a continuación",
      "confirmation_page_tooltip": "Una vez que alguien hace una reserva, puedes elegir mostrarle un mensaje personalizado en la misma página (por defecto) o redirigirlo a una página de tu elección (redirección)",
      "add_guest_collect_email": "Con nombre y correo electrónico",
      "add_guest_count_only": "Contar solo",
      "custom_email_notification": "Enviar formulario personalizado a los invitados",
      "deposit_amount_error": "El monto del depósito debe ser menor que el monto total",
      "deposit_percentage_error": "El porcentaje de depósito debe ser inferior al 100%",
      "partial_payment_tooltip": "Permitir pagos parciales para recolectar el monto del depósito de los clientes durante el proceso de reserva de citas",
      "partial_payment_enable_error": "El monto total ingresado debe ser mayor que 0 para habilitar los pagos parciales",
      "payment_disable_text_integration": "Integra un proveedor de pagos en Pagos > Integraciones para empezar a aceptar pagos",
      "payment_disable_text_recurring": "La opción de aceptar pagos no está disponible para reuniones recurrentes. Desactiva la recurrencia para habilitar los pagos",
      "date_and_time_selector": "Selector de fecha y hora",
      "form_selector": "Formulario",
      "order_step": "Paso",
      "widget_order_label": "Orden de widget",
      "widget_order_tool_tip": "Reorganiza el orden del 'Selector de Fecha y Hora' y del 'Formulario' en el widget del calendario. Simplemente arrástralos y reordénalos según tu preferencia",
      "same_user_assignment_tooltip": "Para habilitar esta configuración, el Formulario debe estar en primer lugar en el widget. Reorganiza el widget para colocar el Formulario primero y activar esta configuración",
      "widget_re_order_tooltip": "La reorganización solo está disponible con el estilo de widget Neo. Cambia a Neo para habilitar la reorganización",
      "widget_re_order_disabled_tooltip": "Para reordenar el orden de los widgets, por favor desactive 'Preferencia de nueva cita (Siempre reservar con el usuario asignado del contacto)' en Detalles de la reunión > Miembros del equipo > Sección de Configuración Avanzada",
      "enable_same_user_assignment": "Asignar contactos a sus respectivos miembros del equipo de calendario cada vez que se reserva una cita"
    },
    "notifications": {
      "notifications": "Notificaciones y Opciones adicionales",
      "notifications_sub": "Configurar notificación y opciones adicionales",
      "select_notification_type": "Seleccione el tipo de notificación",
      "notification_person": "¿Quién debería recibir esta notificación?",
      "contact": "Contacto",
      "assigned_user": "Usuario asignado",
      "emails": "correos electrónicos",
      "alert_email_address": "Dirección de correo electrónico de alerta",
      "ack_email": "Correo de confirmación",
      "send_google_invites": "Permitir que Google Calendar envíe invitaciones o actualizaciones por correo electrónico a los participantes",
      "send_google_outlook_invites": "Permitir que Google / Outlook Calendar envíe invitaciones y correos electrónicos de actualización a los asistentes",
      "assign_user_to_contact": "Asignar a los contactos a sus respectivos miembros del equipo de calendario cada vez que se reserve una cita",
      "skip_assigning_user_to_contact": "Omitir asignación de contacto si el contacto ya tiene un usuario asignado",
      "guest_label_count": "Cantidad de invitado(s)",
      "guest_count_error": "La cuenta de invitados debe estar entre 1 y 100",
      "allow_cancellation": "Permitir la cancelación de la reunión",
      "allow_reschedule": "Permitir reprogramación de reunión",
      "allow_cancellation_tooltip": "Cuando esté habilitado, se agregará un enlace de cancelación a la sección de notas adicionales e incluido en la invitación al calendario. Este enlace expirará según la configuración antes de la hora de inicio de la reunión, impidiendo que el reservante cancele la reunión",
      "allow_reschedule_tooltip": "Cuando esté habilitado, se agregará un enlace de reprogramación a la sección de notas adicionales y se incluirá en la invitación del calendario. Este enlace caducará según la configuración antes de la hora de inicio de la reunión, evitando que el reservante vuelva a programar la reunión",
      "cancellation_link_expiry": "El enlace de cancelación caducará",
      "reschedule_link_expiry": "El enlace de reprogramación caducará",
      "before_meeting": "antes de la reunión",
      "cancellation_and_reschedule_policy": "Política de cancelación y reprogramación:"
    },
    "connections": {
      "connections_heading": "Sincronización de calendario externo",
      "connections_sub": "Sincronizar eventos con calendario externo",
      "link_to_calendar": "Enlace al calendario",
      "sync_option": "Opción de sincronización",
      "execute_automation": "Ejecutar automatización",
      "fire_workflow": "Disparadores de Flujo de Trabajo de Incendios para Eventos reservados a través de sincronización bidireccional",
      "reserve_with_google": "Reservar con Google",
      "connect_lsa": "Conéctate con tu cuenta de Anuncios de servicios locales",
      "reserve_error": "Actualmente, la función de Reservas con Google solo es compatible con los proveedores de servicios locales de los sectores de \"Servicios para el Hogar\", \"Bienes Raíces\" y \"Servicios Legales\" en Estados Unidos",
      "one_way": "Un solo sentido",
      "two_way": "De dos vías",
      "smart": "Inteligente",
      "confirm_remove": "¿Estás seguro de que quieres eliminar Google Calendar?",
      "read_only_access": "No tienes acceso de escritura a este calendario. Por favor, selecciona un calendario diferente",
      "reserve_with_google_desc": "Este servicio de calendario se incluirá en reserva solo con Google después de una integración exitosa",
      "check_integration": "Verifica tu estado de integración"
    },
    "extras": {
      "extras": "Personalizaciones",
      "extras_sub": "Establecer estilo de widget y otras preferencias",
      "calendar_widget_style": "Estilo de widget de calendario",
      "neo": "Neo",
      "classic": "Clásico",
      "insert_custom_code": "Insertar código personalizado",
      "show_seats_per_slot": "Mostrar asientos por ranura",
      "allow_reschedule_meeting": "Permite reprogramar la reunión",
      "allow_cancelling_meeting": "Permitir cancelar la reunión",
      "additional_notes": "Notas adicionales",
      "cancellation_and_reschedule": "Cancelación y reprogramación:",
      "allow_staff": "Permitir la selección de personal durante la reserva",
      "code_block_tooltip": "Los scripts de terceros dentro del bloque de HTML pueden proporcionar una funcionalidad potente, pero también conllevan riesgos para la privacidad, seguridad, rendimiento y comportamiento de la página. Por lo tanto, recomendamos que revises el contenido de este script antes de guardarlo en el calendario"
    }
  },
  "add_guest": {
    "guest_label": "Huésped(s)",
    "search_placeholder": "Buscar desde contactos",
    "add_new": "Añadir nuevo",
    "save": "Guardar",
    "close": "Cerrar",
    "name": "Nombre",
    "phone": "Teléfono",
    "email": "Correo electrónico"
  },
  "languages": {
    "english": "Inglés",
    "dutch": "Holandés",
    "french": "Francés",
    "german": "Alemán",
    "hungarian": "Húngaro",
    "italian": "Italiano",
    "polish": "Polaco",
    "portuguese_brazil": "Portugués (Brasil)",
    "portuguese_portugal": "Portugués (Portugal)",
    "spanish": "Español",
    "danish": "Danés"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Detalles del menú de servicios",
    "select_groups_header": "Seleccionar servicios",
    "arrange_groups_header": "Organiza tus servicios",
    "new_service_menu": "Nuevo Menú de Servicio",
    "service_menu_sub_title": "Crea un menú de servicios personalizados para tus clientes en tan solo unos clics",
    "quick_tip": "Puedes agregar grupos al menú de servicio",
    "service_menu_name": "nombre del menú de servicios",
    "service_menu_description": "Descripción",
    "service_menu_slug": "URL de servicio",
    "select_groups_calendars": "Seleccionar grupos y calendarios",
    "select_groups_calendars_desc": "Agrega servicios a tu menú de servicios",
    "select_all": "Seleccionar todo",
    "arrange_groups": "Organizar orden de servicios",
    "arrange_groups_desc": "Determinar la secuencia de servicios en el menú de servicios",
    "arrange_services_quicktip": "Para reorganizar los calendarios dentro del grupo, ve a Grupos > Opciones > Reorganizar calendarios",
    "enable_add_guest_desc": "Permite a los clientes incluir huéspedes adicionales al hacer una reserva",
    "enable_multiple_service_desc": "Permite a los clientes reservar varios servicios en una sola cita",
    "enable_staff_selection_desc": "Permite a los clientes seleccionar miembros del personal preferidos al realizar una reserva",
    "enable_add_guest": "Permitir añadir invitados",
    "enable_multiple_service": "Permite seleccionar varios servicios",
    "enable_staff_selection": "Habilitar selección de personal",
    "additional_options": "Opciones adicionales",
    "code_block_tooltip": "Los scripts de terceros dentro del bloque HTML pueden proporcionar funcionalidades potentes, pero también pueden suponer riesgos para la privacidad, seguridad, rendimiento y comportamiento de la página. Por lo tanto, recomendamos que revise el contenido de este script antes de guardarlo en el menú de servicios",
    "custom_code_placeholder": "Por favor, ingrese código personalizado aquí",
    "insert_custom_code": "Insertar código personalizado",
    "no_service": "No se encontró servicio",
    "no_service_desc": "No se encontraron servicios. Crea servicios y añádelos a grupos para crear un menú de servicios",
    "learn_more": "Aprende más",
    "no_service_selected": "No se ha seleccionado ningún servicio",
    "create_service_calendars": "Crear calendarios de servicio",
    "alert_description": " Para crear un menú de servicios, debes crear calendarios de servicios y añadirlos a grupos",
    "dont_show_again": "No mostrar esto de nuevo",
    "service_name_required": "Se requiere el nombre del menú de servicio",
    "slug_already_taken": "Esta babosa ya está tomada",
    "slug_required": "Se requiere el uso de babosa",
    "please_enter_name": "Por favor, ingresa un nombre",
    "service_name_placeholder": "Menú principal",
    "consent_label": "Casilla de consentimiento",
    "consent_input_placeholder": "Agregar texto de consentimiento",
    "consent_tooltip": "Activa esta opción para obtener el consentimiento de los contactos cuando proporcionen su número de teléfono durante el proceso de reserva de citas"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personalizar widget de calendario",
    "works_with_neo_widget": "Solo funciona con el widget Neo",
    "customise_widget_desc": "Personalizar apariencia del widget: color principal, color de fondo y texto del botón",
    "learn_more": "Saber más",
    "primary_settings": "Ajustes principales",
    "primary_settings_desc": "Configure el color principal, el color de fondo y otras preferencias del widget para personalizar su widget de calendario",
    "primary_color": "color primario",
    "primary_color_desc": "Define el color para los botones, fechas, franjas horarias y acciones",
    "background_color": "Color de fondo",
    "background_color_desc": "Establecer el color de fondo que sirve como telón de fondo tanto para el selector de fecha y hora como para el formulario",
    "button_text": "Texto del botón",
    "button_text_desc": "Personalizar el texto que se muestra en el botón al reservar una cita",
    "calendar_title": "Título del calendario",
    "calendar_title_desc": "El nombre de tu calendario",
    "calendar_description": "Descripción del calendario",
    "calendar_description_desc": "La descripción ingresada",
    "calendar_details": "Detalles del calendario",
    "calendar_details_desc": "Incluye información como duración, fecha y hora, detalles recurrentes y zona horaria",
    "reset_to_default": "Restaurar a los valores predeterminados",
    "preview_widget": "Widget de vista previa"
  }
}
