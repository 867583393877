{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalenderinstellingen",
      "group": "Groep",
      "manage_your_calendars_and_groups": "Beheer uw kalenders en groepen",
      "groups": "Groepen",
      "create_group": "Groep maken",
      "create_calendar": "Kalender maken",
      "calendar_name": "Kalender naam",
      "group_duration": "Groepsduur",
      "calendar_type": "Kalender type",
      "action": "Actie",
      "round_robin": "Round Robin",
      "event": "Evenement",
      "group_name": "Groepsnaam",
      "calendars": "Kalenders",
      "date_updated": "Datum bijgewerkt",
      "status": "Status",
      "action_dropdown": "Actie dropdown",
      "edit": "Bewerken",
      "duplicate": "Dupliceren",
      "copy_embed_code": "Kopieer insluitcode",
      "copy_permanent_link": "Kopieer permanente link",
      "copy_new_slug_link": "Kopieer planning link",
      "copy_scheduling_link": "Kopieer planning link (Legacy verouderd)",
      "deactivate_calendar": "Kalender deactiveren",
      "move_to_group": "Verplaats naar groep",
      "delete_calendar": "Kalender verwijderen",
      "delete_group": "Verwijderen",
      "delete_this_group": "Verwijder deze groep",
      "activate_calendar": "Activeer kalender",
      "duration": "Duur (minuten)",
      "all": "Alle",
      "draft": "Concept",
      "active": "Actief",
      "rearrange_calendars": "Kalenders herschikken",
      "deactivate_all_calendars_in_group": "Deactiveer alle kalenders in groep",
      "activate_all_calendars_in_group": "Activeer alle kalenders in groep",
      "no_groups_found": "Uw zoekopdracht kwam niet overeen met een kalendergroep.",
      "no_groups_in_location": "Begin met het gebruiken van een groep door een nieuwe kalendergroep te maken.",
      "no_calendars_found": "Begin met het gebruik van de kalender door een nieuwe kalender te maken.",
      "no_calendars_found_search": "Uw zoekopdracht kwam niet overeen met een kalender.",
      "delete_calendar_message": "U staat op het punt om de kalender te verwijderen. Wanneer u op verwijderen klikt, worden ook al de afspraken in deze kalender verwijderd.",
      "delete_calendar_message_title": "Deze kalender verwijderen?",
      "no_calendars_found_title": "Geen kalenders gevonden",
      "no_groups_in_location_title": "Geen groepen gevonden",
      "delete_group_confirmation_title": "Deze groep verwijderen?",
      "delete_group_confirmation": "Ben je zeker dat je de geselecteerde groep wilt verwijderen?",
      "delete_group_confirmation_checkbox_title": "Verwijder gekoppelde agenda's en afspraken",
      "delete_group_confirmation_checkbox_description": "Het selecteren van deze optie zal ook de agenda's in deze groep verwijderen, samen met alle afspraken in die agenda's",
      "group_status_message_title": "{status} deze groep?",
      "calendar_status_message_title": "{status} deze kalender?",
      "calendar_status_message": "U staat op het punt om de kalender {status}.",
      "group_status_message": "U staat op het punt om de groep {status}.",
      "select_calendar_type": "Selecteer Kalender Type",
      "add_new_calendar_group": "Nieuw Kalender Groep Toevioegen",
      "new_calendar": "Nieuw Kalender",
      "calendar_group_description": "Voeg kalender groepen toe aan meerdere agenda's samen.",
      "group_description": "Beschrijving Groep",
      "calendar_url": "Kalender URL",
      "coming_soon": "Binnenkort beschikbaar",
      "select": "Selecteer",
      "15mins": "15min",
      "cancel": "Annuleer",
      "save": "Bewaar",
      "create": "Maak",
      "select_calendar_group": "Selecteer Kalender Groep",
      "move_calendar_confirmation": "Houd er rekening mee dat de verouderde link niet meer werkt zodra u doorgaat met de wijziging. Bevestig door het selectievakje aan te vinken.",
      "new_service_menu": "Nieuw Service Menu",
      "service_menus": "Service Menu",
      "manage_your_calendars_groups_and_service_menus": "Beheer uw agenda's, groepen en dienstenmenu",
      "deactivate_service_menu": "Deactiveer het service menu",
      "delete_service_menu": "Verwijder dit servicemenu",
      "delete_service_menu_confirmation": "Bent u zeker dat u dit service menu wilt verwijderen?",
      "service_menu_status_message_title": "{status} dit Service Menu?",
      "service_menu_status_message": "Je staat op het punt om {status} Service Menu",
      "no_service_menus_in_location_title": "Creëer uw gepersonaliseerde servicemenu",
      "add_new_service_menu": "Voeg nieuw service menu toe",
      "no_service_menus_in_location": "Begin met het maken van een nieuw servicemenu en voeg er Groepen aan toe",
      "no_service_menu_found": "Geen servicemenu gevonden",
      "no_service_menu_found_desc": "Uw zoekopdracht kwam niet overeen met enige service menu's",
      "rooms": "Kamers",
      "equipments": "Uitrustingen",
      "share_otl": "Deel telkens je beschikbaarheid met een unieke link die vervalt na een boeking, zodat er gecontroleerde toegang is",
      "generate_link": "Genereer nieuwe link",
      "share_schedule_link": "De planning link wordt bepaald door de slug. Pas de slug aan en de planning link past zich automatisch aan de wijziging aan",
      "share_permanent_link": "Ideaal voor trechters, website-omleidingen of advertenties, de permanente link blijft constant, onaangetast door slug-wijzigingen",
      "copy": "Kopie",
      "share_embed_code": "Plaats deze code in je HTML waar je wilt dat je {embedType} widget verschijnt",
      "scheduling_link": "Planning Link",
      "permanent_link": "Permanente link",
      "one_time_link": "Eenmalige link",
      "embed_code": "Insluitcode",
      "loading": "Laden...",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Verdeelt afspraken onder teamleden op een roterende volgorde",
      "round_robin_example": "Bijvoorbeeld: Verkoopsgesprekken, introductiesessies",
      "unassigned_booking": "Evenementenkalender",
      "unassigned_description": "Voor het plannen van fysieke evenementen zonder gastvereniging",
      "unassigned_example": "Bijvoorbeeld: Conferenties, openbare seminars, exposities",
      "class_booking": "Klas reservering",
      "class_booking_description": "Eén host ontmoet meerdere deelnemers",
      "class_booking_example": "Bijvoorbeeld: Webinars, groepstraining, online lessen",
      "collective_booking": "Collectieve boeking",
      "collective_booking_description": "Meerdere gastheren ontmoeten één deelnemer",
      "collective_booking_example": "Bijvoorbeeld: Panelgesprekken, commissiebeoordelingen",
      "service_booking": "Serviceboeking",
      "service_booking_description": "Naadloze planning voor servicegerichte bedrijven",
      "service_booking_example": "Bijvoorbeeld: Spa-afspraken, reparatiediensten, consultaties",
      "personal_booking": "Persoonlijke reservering",
      "personal_booking_description": "Plant één-op-één afspraken met een specifiek teamlid",
      "personal_booking_example": "Bijvoorbeeld: klantgesprekken, privéconsultaties",
      "personal": "Persoonlijk",
      "explore_more_types": "Ontdek meer soorten",
      "scheduling_type": "Type planning",
      "scheduling_type_description": "Kies een planningssoort voor uw nieuwe kalender"
    },
    "preferences": {
      "preferences": "Voorkeuren",
      "my_preferences": "Mijn voorkeur",
      "account_preferences": "Accountvoorkeur",
      "user_preferences": "Gebruikersvoorkeuren",
      "set_preferences": "Stel uw voorkeuren in voor uw account.",
      "scheduling_options": "Planningsopties",
      "meeting_location": "Afspraak locatie",
      "zoom": "Zoom",
      "save_preferences": "Voorkeuren opslaan",
      "in_app_preferences": "In-app-voorkeuren",
      "set_start_day": "Stel voorkeuren in zoals startdag voor de app",
      "view_options": "Bekijk opties",
      "week_starts_on": "Week begint op",
      "sunday": "Zondag",
      "widget_preferences": "Widgetvoorkeuren",
      "set_widget_language": "Stel voorkeuren in zoals taal voor widget",
      "language_and_region": "Taal en regio",
      "language": "Taal",
      "english": "Engels",
      "time_format": "Tijdformaat",
      "time_format_value": "13:30",
      "monday": "Maandag",
      "custom_meeting_location": "Aangepaste vergaderlocatie"
    },
    "availability": {
      "availability": "Beschikbaarheid",
      "my_availability": "Mijn beschikbaarheid",
      "account_availability": "Accountbeschikbaarheid",
      "timezone": "Tijdzone",
      "working_hours": "Werkuren",
      "set_recurring_hours": "Stel uw terugkerende werkuren in voor de vergadering",
      "sunday": "Zondag",
      "monday": "Maandag",
      "tuesday": "Dinsdag",
      "wednesday": "Woensdag",
      "thursday": "Donderdag",
      "friday": "Vrijdag",
      "saturday": "Zaterdag",
      "widget_preferences": "Widgetvoorkeuren",
      "set_widget_language": "Stel voorkeuren in zoals taal voor widget",
      "language_and_region": "Taal en regio",
      "language": "Taal",
      "english": "Engels",
      "copy_to_all": "Kopieer naar iedereen",
      "save_availability": "Beschikbaarheid opslaan",
      "add_time": "Tijd Toevoegen",
      "select_time": "Selecteer Tijd"
    },
    "connections": {
      "connections": "Verbindingen",
      "conferencing": "Videoconferentie",
      "my_connections": "Mijn verbindingen",
      "account_connections": "Accountverbindingen",
      "connection_preferences": "Verbindingsvoorkeuren",
      "manage_calendars_conferencing_channels": "Beheer kalenders en conferentiekanalen",
      "main_integration_calendar": "Primaire integratiekalender",
      "event_integration_calendar": "Evenementen die op een systeemkalender zijn gemaakt waarvan u deel uitmaakt, worden ook op deze geïntegreerde kalender gemaakt",
      "2way_sync": "2-weg synchronisatie: Maak systeemgebeurtenissen van evenementen die zijn geboekt op de belangrijkste geïntegreerde kalender",
      "calendars": "Kalenders",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Controleren op conflicten",
      "choose_accounts_to_connect": "Kies welke accounts u wilt verbinden",
      "payments": "Betalingen",
      "stripe": "Stripe",
      "ads": "Advertenties",
      "local_service_ads": "Lokale service advertenties",
      "fire_workflow": "Activeer Workflow-triggers voor gebeurtenissen die via 2-weg synchronisatie zijn geboekt",
      "connect_google_outlook": "Sluit uw Google- of Outlook-account aan om een primaire kalender te kunnen kiezen.",
      "execute_automation": "Automatisering uitvoeren",
      "save": "Opslaan",
      "lsa": "Lokale service advertenties",
      "connect": "Connecteer",
      "no_write_access": "U heeft geen schrijf toegang tot deze agenda",
      "connection_remove_confirmation": "U staat op het punt deze integratie te verwijderen",
      "active": "Actief"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Een contact selecteren",
    "start_time": "Starttijd",
    "end_time": "Eindtijd",
    "search_by_name_email_phone_or_company": "Zoeken op naam, e-mail, telefoon of bedrijf",
    "add_new": "Nieuwe toevoegen",
    "pick_from_available_contacts": "Of kies uit beschikbare contacten",
    "book_appointment": "Afspraak boeken",
    "calendar": "Kalender",
    "repeat": "Herhalen",
    "date_and_time": "Datum en tijd",
    "close": "Sluiten",
    "team_member": "Teamlid",
    "recurring_booking": "Terugkerende boeking",
    "one_slot_booking": "Eén slotboeking",
    "event_type": "Type evenement",
    "occurrences_booked": "herhalingen worden geboekt",
    "repeats": "Herhalen",
    "booking_details": "Boekingsdetails",
    "showing_slots_in_this_timezone": "Toont slots in deze tijdzone:",
    "update_contact_tz": "Update ook de tijdzone van het contact naar",
    "date": "Datum",
    "slot": "Slot",
    "calendar_default": "Standaard kalender",
    "custom": "Aangepast",
    "meeting_notes": "(bijv.) Notities voor onze aankomende afspraak",
    "out_of_office": "(bijv.) Afwezig deze week!",
    "standard": "Standaard",
    "appointment_title": "Afspraak titel",
    "event_title": "Evenement titel",
    "appt_with_bob": "(bijv.) Afspraak met Bob",
    "vacation": "(bijv.) Vakantie",
    "show_notes": "Notities tonen",
    "hide_notes": "Notities verbergen",
    "additional_preferences": "Aanvullende voorkeuren",
    "meeting_location": "Locatie van ontmoeting",
    "appointment_status": "Afspraakstatus",
    "appt_description": "Beschrijving afspraak",
    "recurring_details": "Terugkerende details",
    "event_description": "Evenementbeschrijving",
    "in_contact_timezone": "In tijdzone van contactpersoon",
    "vacation_message": "Gaat u op vakantie? Neemt u wat tijd vrij? Blokkeer tijd op uw kalender om te voorkomen dat klanten afspraken boeken. Bestaande afspraken blijven wel op uw kalender staan.",
    "contact": "Contact",
    "delete": "Verwijderen",
    "cancel": "Annuleren",
    "back": "Terug",
    "pick_available_contacts": "Of kies uit beschikbare contacten",
    "no_contacts_found": "Geen contacten gevonden",
    "found": "Gevonden",
    "select_one": "Selecteer een",
    "tz_tooltip": "Dit is alleen relevant als u aangepaste waarden gebruikt; zoals afspraak_start_tijd, afspraak_eind_tijd, afspraak_tijdzone, enz. in uw uitgaande communicatie met uw klanten of klanten. En wat het eenvoudig doet, is de bovengenoemde details converteren naar hun lokale tijd.",
    "meeting_location_tooltip": "De vergaderlocatie wordt ingesteld zoals geconfigureerd in de kalender.",
    "appt_meeting_location_tooltip": "De gebruiker kan de vergaderlocatie specifiek instellen voor de afspraak.",
    "gmeet_tooltip": "Zorg ervoor dat het respectieve teamlid een Google-kalender heeft ingesteld als hun primaire kalender",
    "zoom_tooltip": "Zorg ervoor dat het respectieve teamlid geïntegreerd is met Zoom",
    "enter_meeting_location": "Vul de locatie van de afspraak in",
    "save_appointment": "Afspraak opslaan",
    "save_event": "Evenement opslaan",
    "search_contacts": "Zoeken op naam, e-mail, telefoon of bedrijf",
    "recurring_slots": "{recurringSlotsLength}/{count}",
    "add_blocked_off_time": "Voeg Geblokkeerde Tijd toe",
    "recommended_timezones": "Aanbevolen tijdzones",
    "all_timezones": "Alle tijdzones",
    "account_timezone": "Account tijdzone",
    "an_error_occurred": "Er is een fout opgetreden",
    "contact_timezone": "Contact tijdzone",
    "custom_timezone": "Aangepaste tijdzone",
    "system_timezone": "Systeem tijdzone",
    "unassigned": "Niet-toegewezen"
  },
  "popup": {
    "calendar": "Kalender",
    "appointment_owner": "Afspraakeigenaar",
    "source": "Bron",
    "booked_by": "Geboekt door",
    "view_activity_log": "Activiteitenlogboek bekijken",
    "status": "Status",
    "cancel_appointment": "Afspraak annuleren",
    "google_event": "Google-evenement",
    "outlook_event": "Outlook-evenement",
    "blocked_slot": "Geblokkeerd slot",
    "appointment": "Afspraak",
    "confirmed": "Bevestigd",
    "unconfirmed": "Annuleren",
    "cancelled": "Geannuleerd",
    "showed": "Aanwezig",
    "no_show": "Niet aanwezig",
    "invalid": "Ongeldig",
    "cancel_all": "Alles Annuleren",
    "edit": "Bewerken",
    "less": "Minder",
    "more": "Meer",
    "reschedule_all": "Alles opnieuw inplannen",
    "guests": "Gasten",
    "guest_count": "Gast telt"
  },
  "activity_log": {
    "activity_log": "Activiteitenlogboek",
    "date": "Datum",
    "filter_by": "Filteren op",
    "calendar_update": "Agenda update",
    "start_time_update": "Begintijd update",
    "end_time_update": "Eindtijd update",
    "title_update": "Titel update",
    "notes_update": "Notities update",
    "appointment_status_update": "Afspraakstatus update",
    "meeting_location_update": "Vergaderlocatie update",
    "google_sync_update": "Google-synchronisatie update",
    "outlook_sync_update": "Outlook-synchronisatie update",
    "assigned_user_update": "Toegewezen gebruiker update",
    "appointment_status": "Afspraakstatus",
    "assigned_user": "Toegewezen gebruiker",
    "uploaded_to": "Geüpload naar",
    "cancelled": "Geannuleerd",
    "edited": "bewerkt",
    "other_edits": "Andere bewerkingen",
    "view_details": "Details bekijken",
    "all": "Alle"
  },
  "appointment_list": {
    "group_all": "ALLE",
    "group_primary": "PRIMAIRE",
    "group_user": "GEBRUIKERS",
    "group_team": "GROEPEN",
    "group_calendar": "kalenders",
    "all_appointments": "Alle afspraken",
    "my_calendar": "Mijn agenda.",
    "status_all": "Alle",
    "status_confirmed": "Bevestigd",
    "status_new": "Nieuw (Actie vereist)",
    "status_show": "getoond",
    "status_no_show": "Niet op komen dagen",
    "status_cancelled": "Geannuleerd",
    "status_invalid": "Ongeldig.",
    "filter_date_added_desc": "Datum Toegevoegd (Aflopend)",
    "filter_start_added_desc": "Startdatum (aflopend)",
    "filter_start_added_asc": "Startdatum oplopend vanaf vandaag",
    "button_new_appointment": "Nieuwe afspraak",
    "name": "Naam",
    "title": "Titel",
    "requested_time": "Gevraagde tijd",
    "date_added": "Datum toegevoegd.",
    "calendar": "Kalender",
    "appointment_owner": "Afspraak eigenaar",
    "notes": "Notities",
    "status": "Status",
    "action_view_detail": "Bekijk details",
    "action_view_notes": "Bekijk notities",
    "action_edit": "Bewerken",
    "action_delete": "Verwijderen",
    "action_reschedule": "Herplannen",
    "page_previous": "Vorige",
    "page_next": "Volgende",
    "appointment_notes": "Afspraaknotities",
    "close": "Dichtbij",
    "confirm_delete_header": "Verwijder afspraak",
    "confirm_delete_message": "Weet je zeker dat je deze afspraak wilt verwijderen?",
    "confirm_delete_button": "Verwijderen",
    "confirm_update_header": "Bijwerken afspraakstatus",
    "confirm_update_confirmed_message": "Weet u zeker dat u deze afspraak als bevestigd wilt markeren?",
    "confirm_update_invalid_message": "Weet u zeker dat u deze afspraak als ongeldig wilt markeren?",
    "confirm_update_showed_message": "Ben je er zeker van dat je deze afspraak als getoond wilt markeren?",
    "confirm_update_noshow_message": "Weet je zeker dat je deze afspraak als no-show wilt markeren?",
    "confirm_update_cancelled_message": "Weet u zeker dat u deze afspraak als geannuleerd wilt markeren?",
    "confirm_update_button": "Bijwerken.",
    "empty_state_title": "Geen afspraken gevonden",
    "empty_state_description": "Geen afspraken gevonden",
    "disable_menu_warning": "Op dit moment heb je geen toestemming om de afspraakgegevens te bekijken of bewerken, omdat de bijbehorende contactpersoon niet aan jou is toegewezen. Neem vriendelijk contact op met je beheerder voor verdere ondersteuning.",
    "no_contact_warning": "Je hebt momenteel geen toegang tot contactinformatie. Dit kan komen doordat het contact niet aan jou is toegewezen. Neem alsjeblieft contact op met je beheerder voor hulp",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Herpland"
  },
  "create_calendar": {
    "calendar_name": "Kalender naam",
    "calendar_logo": "Kalenderlogo",
    "select_team_member": "Selecteer teamleden",
    "eg_outbound_reach": "(eg) Uitgaande bereik",
    "description": "Beschrijving",
    "amount": "Bedrag",
    "description_placeholder": "Schrijf omschrijving",
    "meeting_duration": "Vergaderduur",
    "seats_per_class": "Aantal stoelen per klas",
    "group": "Groep",
    "custom_url": "Aangepaste URL",
    "meeting_invite_title": "Uitnodiging voor de vergadering",
    "event_color": "Evenement kleur",
    "availability_type": "Beschikbaarheidstype",
    "deposit_amount": "Stortingsbedrag",
    "collect_deposit_amount": "Accepteer gedeeltelijke betaling",
    "percentage": "Percentage",
    "total_amount": "Totaalbedrag",
    "reschedule_preference": "Voorkeur opnieuw inplannen",
    "reschedule_preference_subtext": "Als een contact een afspraak verplaatst:",
    "reassign_through_round_robin": "Opnieuw toewijzen via round-robin",
    "keep_same_assinged_user": "Houd dezelfde afspraak eigenaar",
    "new_appointment_preference": "Nieuwe afspraakvoorkeur",
    "new_appointment_preference_text": "Altijd boek met de toegewezen gebruiker van het contact",
    "new_appointment_preference_subtext": "Boek nieuwe afspraken met de toegewezen gebruiker van de contactpersoon in plaats van het gebruik van round-robin planning"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Snelle tip",
      "timezone_change_tip": "Tijdzones kunnen op elk moment worden gewijzigd in de voorkeuren voor tijdzones",
      "save": "Opslaan",
      "close": "dichtbij",
      "meeting_details": "Vergadergegevens",
      "availability": "Beschikbaarheid",
      "forms_and_payment": "Formulieren en Betaling",
      "notifications_and_additional_options": "Meldingen en Extra opties",
      "connections": "Connecties",
      "customizations": "Aanpassingen",
      "create": "Maken",
      "edit": "bewerken"
    },
    "meeting_details": {
      "meeting_location": "Vergaderlocatie",
      "enter_meeting_location": "Voer de locatie van de vergadering in",
      "click_to_upload": "Klik om te uploaden",
      "square": "Vierkant",
      "circle": "Cirkel",
      "remove_logo": "Verwijder logo",
      "meeting_invite_title_tooltip": "Dit is de titel voor het agenda-evenement dat wordt weergegeven in Google Agenda, Outlook, Apple Agenda, enz",
      "event_color_tooltip": "Kies een kleur voor dit boekingsevenement. Kleuren worden gesynchroniseerd met gebeurtenissen in Google Agenda",
      "enter_a_name": "Voer alstublieft een naam in",
      "enter_an_event_title": "Voer alstublieft een evenementtitel in",
      "high_priority": "Hoge Prioriteit",
      "medium_priority": "Gemiddelde prioriteit",
      "low_priority": "Lage prioriteit",
      "custom": "Aangepast",
      "zoom_not_added": "De gebruiker heeft zijn Zoom-account nog niet verbonden",
      "opt_avail": "Optimaliseer voor beschikbaarheid",
      "opt_equal": "Optimaliseer voor gelijke verdeling",
      "team_members": "Selecteer teamleden",
      "team_member": "Selecteer teamlid",
      "meeting_location_required": "De vergaderlocatie is vereist",
      "team_member_disabled_recurring_tooltip": "Herhalende vergaderingen staan slechts één teamlid toe. Om meerdere teamleden toe te voegen, schakel alstublieft herhalende vergaderingen uit.",
      "team_member_disabled_class_booking_tooltip": "Slechts één teamlid kan worden toegevoegd aan een klasboekingskalender",
      "enable_contact_assigned_to_setting": "Om deze instelling in te schakelen, moet het formulier als eerste in de widgetvolgorde staan. Pas de widgetvolgorde aan vanuit het tabblad Formulieren & Betalingen",
      "reschedule_preference_setting_tooltip": "Kies aan wie de afspraak moet worden toegewezen wanneer een contact opnieuw plant vanuit de boekingswidget",
      "appointment_preference_tooltip": "Kies of nieuwe afspraken altijd moeten worden geboekt met de toegewezen gebruiker van het contact. Als een contact een toegewezen gebruiker heeft, krijgt deze voorrang; anders zal het een round-robin toewijzing zijn. Tip: Schakel 'Sta Personeelsselectie toe' uit om te voorkomen dat boekers de medewerker op de boekingswidget wijzigen",
      "add_location": "Voeg locatie toe",
      "multiple_locations_neo": "Meerdere vergaderlocaties worden alleen ondersteund door de Neo Widget met het standaardformulier",
      "make_owner": "Maak eigenaar",
      "only_one_team_member": "Slechts één teamlid is toegestaan wanneer er meerdere locaties worden toegevoegd",
      "only_one_team_member_personal_calendar": "Slechts één teamlid is toegestaan voor persoonlijke boeking"
    },
    "availability": {
      "my_availability": "Mijn beschikbaarheid",
      "set_availability": "Stel hier je beschikbaarheid voor de kalender in",
      "standard": "Standaard",
      "custom": "Aangepast",
      "to": "Naar",
      "copy_to_all": "Kopieer naar alles",
      "add_time": "Voeg tijd toe",
      "recurring_meeting": "Terugkerende vergadering",
      "recurring_info": "Slechts één gebruiker per kalender voor terugkerende boekingen",
      "repeat": "Herhalen",
      "times_to_repeat": "Aantal herhalingen",
      "slots_unavailable": "Als er geen slots beschikbaar zijn?",
      "overlap_status": "Afspraakstatus voor overlappende tijdsblokken",
      "meeting_interval": "Vergaderinterval",
      "meeting_duration": "Vergaderduur",
      "minimum_scheduling": "Minimale planning kennisgeving",
      "date_range": "Datumreeks",
      "maximum_bookings_per_day": "Maximale boekingen per dag",
      "maximum_bookings_per_slot": "Maximale boekingen per tijdslot",
      "maximum_bookings_per_slot_per_user": "Maximale boekingen per tijdsperiode (per gebruiker)",
      "seats_per_class": "Zitplaatsen per klas",
      "pre_buffer_time": "Vooraf buffer tijd",
      "post_buffer_time": "Post buffer tijd",
      "pre_buffer_tooltip": "Pre-buffer is extra tijd die kan worden toegevoegd voor een afspraak, waardoor er extra tijd is om je voor te bereiden voordat je een vergadering hebt",
      "post_buffer_tooltip": "Post-Buffer is extra tijd die kan worden toegevoegd na een afspraak, zodat er extra tijd is om af te ronden",
      "buffer_time": "Buffer tijd",
      "daily": "Dagelijks",
      "weekly": "Wekelijks",
      "monthly": "Maandelijks",
      "skip_booking": "Sla het boeken van niet-beschikbare slots over",
      "continue_booking": "Doorgaan met boeken",
      "book_next_available": "Boek volgende beschikbare tijdslot",
      "confirmed": "Bevestigd",
      "unconfirmed": "Onbevestigd",
      "enter_valid_hours": "Voer alstublieft geldige openingstijden in",
      "enter_valid_avail": "Gelieve een geldige aangepaste beschikbaarheid in te voeren",
      "availability_type_tooltip": "Kies Standaard Beschikbaarheid voor reguliere boekingen die wekelijks worden aangeboden. Kies Aangepast voor boekingen die alleen op specifieke data beschikbaar zijn",
      "meeting_interval_tooltip": "De vergadertijdinterval weerspiegelt de hoeveelheid tijd tussen de boekingsblokken die worden getoond in de agenda. Voor een evenement van 30 minuten dat beschikbaar moet zijn aan het begin van elk uur, zou het interval 1 uur zijn. Voor een evenement van 30 minuten dat elke 30 minuten beschikbaar moet zijn, zou het interval 30 minuten zijn",
      "date_range_tooltip": "Evenementen kunnen worden ingepland voor de komende n dagen",
      "maximum_bookings_per_day_tooltip": "Maximaal aantal afspraken toegestaan per dag op deze kalender",
      "maximum_bookings_per_slot_tooltip": "Maximaal aantal afspraken toegestaan per tijdslot voor de gebruiker (Als het aantal afspraken het maximum overschrijdt voor het gegeven tijdslot van de gebruiker, dan schakelt het over naar de volgende gebruiker in de round-robin wachtrij of markeert het tijdslot als niet beschikbaar voor de prospect om te boeken)",
      "add_days": "Voeg dagen toe",
      "month_on_day": "Maandelijks op dag",
      "month_on_last_day": "Elke maand op de laatste dag",
      "day": "Dag",
      "week": "Week",
      "month": "Maand",
      "mins": "Notulen",
      "hours": "Uren",
      "days": "Dagen",
      "weeks": "weken",
      "months": "Maanden",
      "meeting_interval_error": "Het interval voor de bijeenkomst moet tussen de 5 minuten en 12 uur zijn",
      "meeting_duration_error": "De duur van de vergadering moet tussen 1 minuut en 12 uur zijn",
      "buffer_time_error": "Buffer tijd moet minder dan 12 uur zijn",
      "subTitle": "Kies de datum om specifieke uren in te stellen",
      "heading_1": "Wekelijkse beschikbare uren",
      "tooltip_1": "Stel uw wekelijkse beschikbaarheidsschema in. Dit zijn uw standaarduren die consequent op wekelijkse basis zullen worden toegepast",
      "heading_2": "Wanneer ben je beschikbaar?",
      "unavailable_label": "Niet beschikbaar",
      "tooltip_2": "Pas uw schema aan voor specifieke data. Overschrijf uw reguliere wekelijkse uren door beschikbaarheid of niet-beschikbaarheid voor geselecteerde data te markeren",
      "date_specific_level": "Voeg specifieke uren per datum toes",
      "specific_hours_button": "Specifieke datum en tijden",
      "overlap_error": "Tijdslots kunnen niet overlappen",
      "apply": "Toepassen",
      "copy_times_to": "Kopieer tijden naar",
      "no_date_specific_heading": "Geen specifieke tijd toegevoegd",
      "no_date_specific_description": "Je kunt specifieke datum en tijd toevoegen/verwijderen aan je beschikbaarheid",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "Om een datum- en uuropgave toe te voegen, zorg ervoor dat terugkerende vergaderingen zijn uitgeschakeld",
      "recurring_disable_event_calendar_conditions": "Herhalende vergaderingen zijn uitgeschakeld. Om ze te activeren, zorg ervoor dat de betaling is uitgeschakeld en er geen uren zijn toegevoegd voor specifieke data",
      "service_interval": "Service interval",
      "service_interval_tooltip": "Het vergaderinterval bepaalt hoe vaak boekingsmogelijkheden verschijnen op de agenda. Bijvoorbeeld, voor een evenement van 30 minuten betekent een interval van 1 uur dat er boekingsmogelijkheden per uur worden weergegeven. Een interval van 30 minuten betekent dat er elke 30 minuten boekingsmogelijkheden worden weergegeven",
      "service_duration_tooltip": "Dit bepaalt de duur van uw afspraak en hoe lang het zal duren",
      "minimum_scheduling_tooltip": "Dit stelt de minimale tijd in die vereist is om een afspraak te boeken via de kalenderwidget. Bijvoorbeeld, als het is ingesteld op 4 uur, kunnen afspraken niet geboekt worden binnen 4 uur voor aanvang; afspraken moeten minstens 4 uur van tevoren gemaakt worden",
      "service_duration": "Service duration",
      "look_busy_title": "Doe alsof je druk bezig bent",
      "look_busy_percentage_description": "Verberg het aantal beschikbare plekken met x%",
      "look_busy_helper_text": "Verberg een percentage van je beschikbare afspraaktijden op de boekingswidget om drukker en in hoge vraag te lijken",
      "maximum_bookings_per_slot_tooltip_personal": "Maximum aantal afspraken toegestaan per slot voor de gebruiker"
    },
    "forms_payment": {
      "forms": "Formulieren",
      "forms_sub": "Stel voorkeuren voor de formulierplanning in",
      "select_form": "Selecteer formulier",
      "sticky_contacts": "Vooraf invulvelden (sticky-contactpersonen)",
      "confirmation_page": "Bevestigingspagina",
      "default": "Standaard",
      "redirect_url": "Doel-URL",
      "thank_you": "Dankuwel bericht",
      "enter_url": "Voer URL in",
      "pixel_id": "Facebook pixel-ID (optioneel)",
      "auto_confirm": "Automatisch bevestigen van nieuwe agenda-afspraken",
      "configure_payment": "Configureer betalingsmogelijkheden",
      "accept_payments": "Accepteer betalingen",
      "test": "Test",
      "live": "Levend",
      "enable_guests": "Toevoegen van gasten",
      "add_guest_tooltip": "Door gast toe te voegen, kan uw deelnemer meerdere deelnemers toevoegen aan dezelfde afspraak",
      "add_guest_same_form_check": "Stuur hetzelfde formulier via e-mail naar alle gasten om informatie te verzamelen",
      "payment_tooltip": "Betaling wordt alleen overwogen voor de hoofddeelnemer en niet voor de gasten",
      "default_form": "Standaard (Voornaam, Achternaam, E-mail, Telefoon, Opmerkingen)",
      "select_form_tooltip": "Als u meer informatie wilt verzamelen dan de standaard voornaam, achternaam, e-mail en telefoon van degenen die boeken in deze agenda, maak dan een aangepast formulier in Sites > Formulieren > Bouwer en selecteer het formulier vervolgens via het uitklapmenu hieronder",
      "confirmation_page_tooltip": "Zodra iemand een boeking maakt, kunt u ervoor kiezen om ze een aangepaste boodschap op dezelfde pagina te laten zien (Standaard) of ze door te sturen naar een door u gekozen pagina (Doorverwijzen)",
      "add_guest_collect_email": "Met naam en e-mailadres",
      "add_guest_count_only": "Tel alleen",
      "custom_email_notification": "Stuur aangepast formulier naar gasten",
      "deposit_amount_error": "Het stortingsbedrag moet lager zijn dan het totale bedrag",
      "deposit_percentage_error": "Het stortingspercentage moet minder dan 100% zijn",
      "partial_payment_tooltip": "Schakel gedeeltelijke betalingen in om het aanbetalingsbedrag van klanten te innen tijdens het afsprakenboekingsproces",
      "partial_payment_enable_error": "Het totaal ingevoerde bedrag moet groter zijn dan 0 om gedeeltelijke betalingen mogelijk te maken",
      "payment_disable_text_integration": "Integreer een betalingsprovider onder Betalingen > Integraties om betalingen te kunnen accepteren",
      "payment_disable_text_recurring": "De optie om betalingen te accepteren is niet beschikbaar voor terugkerende bijeenkomsten. Schakel de terugkerende instelling uit om betalingen mogelijk te maken",
      "date_and_time_selector": "Datum- en tijdselectiehouder",
      "form_selector": "Formulier",
      "order_step": "Stap",
      "widget_order_label": "Widgetvolgorde",
      "widget_order_tool_tip": "Herschik de volgorde van de 'Datum- en Tijdselectie' en het 'Formulier' op de kalenderwidget. Sleep eenvoudig om opnieuw te ordenen volgens uw voorkeur",
      "same_user_assignment_tooltip": "Om deze instelling in te schakelen, moet het formulier eerst bovenaan de widget staan. Herschik de widget om het formulier als eerste te plaatsen om deze instelling te activeren",
      "widget_re_order_tooltip": "Herschikken is alleen beschikbaar met de Neo-widgetstijl. Schakel over naar Neo om herschikken in te schakelen",
      "widget_re_order_disabled_tooltip": "Om de volgorde van de widget te wijzigen, schakelt u 'Nieuwe afspraakvoorkeur (Altijd boeken bij toegewezen gebruiker van contactpersoon)' uit in het onderdeel Bijeenkomstdetails > Teamleden > Geavanceerde instellingen",
      "enable_same_user_assignment": "Wijs contacten toe aan hun respectieve agenda teamleden telkens wanneer een afspraak wordt geboekt"
    },
    "notifications": {
      "notifications": "Meldingen en aanvullende opties",
      "notifications_sub": "Configureer notificatie- en extra opties",
      "select_notification_type": "Selecteer het type melding",
      "notification_person": "Wie moet deze melding ontvangen?",
      "contact": "Contact",
      "assigned_user": "Toegewezen gebruiker",
      "emails": "E-mails",
      "alert_email_address": "Waarschuwings-e-mailadres",
      "ack_email": "Bevestigingsmail",
      "send_google_invites": "Laat Google Agenda toe om uitnodigingen of updates per e-mail te versturen naar genodigden",
      "send_google_outlook_invites": "Sta Google / Outlook Calendar toe om uitnodigingen en updates te versturen naar de genodigden",
      "assign_user_to_contact": "Elke keer dat er een afspraak wordt gemaakt, wijs de contacten toe aan hun respectievelijke agenda teamleden",
      "skip_assigning_user_to_contact": "Sla het toewijzen van een contact over als het contact al een toegewezen gebruiker heeft",
      "guest_label_count": "Aantal gasten",
      "guest_count_error": "Het aantal gasten moet liggen tussen 1 en 100",
      "allow_cancellation": "Toestaan Annulering van Vergadering",
      "allow_reschedule": "Toestaan van het opnieuw inplannen van de vergadering",
      "allow_cancellation_tooltip": "Wanneer ingeschakeld, wordt er een annuleringslink toegevoegd aan de aanvullende notitiessectie en opgenomen in de afspraakuitnodiging. Deze link zal verlopen zoals geconfigureerd vóór de starttijd van de vergadering, waardoor de boeker de vergadering niet kan annuleren",
      "allow_reschedule_tooltip": "Wanneer ingeschakeld, wordt er een nieuwe planning link toegevoegd aan het gedeelte met aanvullende opmerkingen en opgenomen in de uitnodiging voor de vergadering. Deze link zal verlopen zoals geconfigureerd vóór de starttijd van de vergadering, waardoor de persoon die de vergadering heeft geboekt, niet in staat zal zijn om de vergadering opnieuw in te plannen",
      "cancellation_link_expiry": "Annuleringslink verloopt",
      "reschedule_link_expiry": "De link voor het opnieuw plannen zal verlopen",
      "before_meeting": "voor de vergadering",
      "cancellation_and_reschedule_policy": "Annulerings- en verplaatsingsbeleid:"
    },
    "connections": {
      "connections_heading": "Externe agenda-synchronisatie",
      "connections_sub": "Synchroniseer evenementen met externe agenda",
      "link_to_calendar": "Link naar kalender",
      "sync_option": "Syncoptie",
      "execute_automation": "Uitvoeren van automatisering",
      "fire_workflow": "Vuur Werkstroom Triggers voor evenementen geboekt via 2-weg synchronisatie",
      "reserve_with_google": "Boek via Google",
      "connect_lsa": "Maak verbinding met uw lokale services Ads-account",
      "reserve_error": "Op dit moment wordt Reserve with Google alleen ondersteund voor \"Home Services\", \"Vastgoed\" en \"Juridische Diensten\" (lokale dienstverleners) binnen de Verenigde Staten",
      "one_way": "Eénrichtingsverkeer",
      "two_way": "Tweerichtings",
      "smart": "Slim",
      "confirm_remove": "Ben je zeker dat je Google Agenda wilt verwijderen?",
      "read_only_access": "Je hebt geen schrijftoegang tot deze agenda. Selecteer een andere agenda alstublieft",
      "reserve_with_google_desc": "Deze kalenderdienst zal alleen worden vermeld als reserve bij Google bij succesvolle integratie",
      "check_integration": "Controleer uw integratiestatus"
    },
    "extras": {
      "extras": "Aanpassingen",
      "extras_sub": "Stel widget-stijl en andere voorkeuren in",
      "calendar_widget_style": "Kalenderwidgetstijl",
      "neo": "Neo",
      "classic": "Klassiek",
      "insert_custom_code": "Voeg aangepaste code toe",
      "show_seats_per_slot": "Toon stoelen per timeslot",
      "allow_reschedule_meeting": "Sta het verzetten van de vergadering toe",
      "allow_cancelling_meeting": "Toestaan om de vergadering te annuleren",
      "additional_notes": "Extra notities",
      "cancellation_and_reschedule": "Annulering en herplanning:",
      "allow_staff": "Toestaan van personeelsselectie tijdens het boeken",
      "code_block_tooltip": "Derden scripts binnen het HTML-blok kunnen krachtige functionaliteiten bieden, maar ze brengen ook risico's met zich mee voor privacy, beveiliging, prestaties en paginagedrag. Daarom raden wij aan om de inhoud van dit script te controleren voordat u het opslaat in de kalender"
    }
  },
  "add_guest": {
    "guest_label": "Gast(en)",
    "search_placeholder": "Zoeken tussen de contacten",
    "add_new": "Nieuw Toevoegen",
    "save": "Opslaan",
    "close": "Dichtbij",
    "name": "Naam",
    "phone": "Telefoon",
    "email": "E-mail"
  },
  "languages": {
    "english": "Engels",
    "dutch": "Nederlands",
    "french": "Frans",
    "german": "Duits",
    "hungarian": "Hongaars",
    "italian": "Italiaans",
    "polish": "Pools",
    "portuguese_brazil": "Portugees (Brazilië)",
    "portuguese_portugal": "Portugees (Portugal)",
    "spanish": "Spaans",
    "danish": "Deens"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Details van het servicemenu",
    "select_groups_header": "Selecteer Diensten",
    "arrange_groups_header": "Regel uw diensten",
    "new_service_menu": "Nieuw Service Menu",
    "service_menu_sub_title": "Creëer met slechts een paar klikken een gepersonaliseerd service menu voor uw klanten",
    "quick_tip": "Je kunt groepen toevoegen aan het servicemenu",
    "service_menu_name": "Naam service menu",
    "service_menu_description": "Beschrijving",
    "service_menu_slug": "Service URL",
    "select_groups_calendars": "Selecteer groepen en agenda's",
    "select_groups_calendars_desc": "Voeg diensten toe aan uw service menu",
    "select_all": "Selecteer alles",
    "arrange_groups": "Regel de volgorde van diensten",
    "arrange_groups_desc": "Bepaal de volgorde van diensten op het servicemenu",
    "arrange_services_quicktip": "Om agenda's binnen een groep opnieuw in te delen, ga naar Groepen > Opties > Agenda's opnieuw indelen",
    "enable_add_guest_desc": "Stelt klanten in staat om extra gasten toe te voegen bij het maken van een reservering",
    "enable_multiple_service_desc": "Stelt klanten in staat om meerdere diensten in één afspraak te boeken",
    "enable_staff_selection_desc": "Stelt klanten in staat om voorkeursmedewerkers te selecteren bij het maken van een afspraak",
    "enable_add_guest": "Sta toe toevoegen van gasten",
    "enable_multiple_service": "Sta meerdere serviceselectie toe",
    "enable_staff_selection": "Activeer medewerkersselectie",
    "additional_options": "Extra opties",
    "code_block_tooltip": "Scripts van derden binnen het HTML-blok kunnen krachtige functionaliteit bieden, maar ze brengen ook risico's met zich mee op het gebied van privacy, beveiliging, prestaties en paginagedrag. Daarom raden we je aan om de inhoud van dit script te controleren voordat je het opslaat in het servicemenu.",
    "custom_code_placeholder": "Voer hier alstublieft aangepaste code in",
    "insert_custom_code": "Voeg aangepaste code toe",
    "no_service": "Geen dienst gevonden",
    "no_service_desc": "Geen services gevonden. Maak services aan en voeg ze toe aan groepen om een servicemenu te maken",
    "learn_more": "Leer meer",
    "no_service_selected": "Geen service geselecteerd",
    "create_service_calendars": "Maak dienstkalenders",
    "alert_description": "Om een service-menu te maken, moet je serviceniveaus creëren en deze aan groepen toevoegen",
    "dont_show_again": "Niet opnieuw tonen",
    "service_name_required": "Naam van het servicemenu is vereist",
    "slug_already_taken": "Deze gebruikersnaam is al bezet",
    "slug_required": "Slak is vereist",
    "please_enter_name": "Voer alstublieft een naam in",
    "service_name_placeholder": "Hoofdmenu",
    "consent_label": "Toestemmingsvakje",
    "consent_input_placeholder": "Voeg toestemmingstekst toe",
    "consent_tooltip": "Schakel deze optie in om toestemming van contacten te verkrijgen wanneer zij hun telefoonnummer opgeven tijdens het boekingsproces van een afspraak"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Wijzig de kalender widget",
    "works_with_neo_widget": "Werkt alleen met Neo-widget",
    "customise_widget_desc": "Wijzig uiterlijk van widget: primaire kleur, achtergrondkleur en tekst van de knop aanpassen",
    "learn_more": "Leer Meer",
    "primary_settings": "Primaire instellingen",
    "primary_settings_desc": "Stel de primaire kleur, achtergrondkleur en andere widgetvoorkeuren in om je agenda-widget aan te passen",
    "primary_color": "Primaire kleur",
    "primary_color_desc": "Definieer de kleur voor knoppen, datums, tijdvakken en acties",
    "background_color": "Achtergrondkleur",
    "background_color_desc": "Stel de achtergrondkleur in die dient als achtergrond voor zowel de datum- en tijdselectie als het formulier",
    "button_text": "Knop Tekst",
    "button_text_desc": "Pas de tekst aan die wordt weergegeven op de knop bij het boeken van een afspraak",
    "calendar_title": "Kalendertitel",
    "calendar_title_desc": "De naam van je kalender",
    "calendar_description": "Kalenderbeschrijving",
    "calendar_description_desc": "De ingevoerde beschrijving",
    "calendar_details": "Kalenderdetails",
    "calendar_details_desc": "Bevat informatie zoals duur, datum en tijd, terugkerende details en tijdzone",
    "reset_to_default": "Reset naar standaard",
    "preview_widget": "Voorbeeldwidget"
  }
}
