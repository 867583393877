{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalenderinställningar",
      "group": "Grupp",
      "manage_your_calendars_and_groups": "Hantera dina kalendrar och grupper",
      "groups": "Grupper",
      "create_group": "Skapa grupp",
      "create_calendar": "Skapa kalender",
      "calendar_name": "Kalendernamn",
      "group_duration": "Gruppens varaktighet",
      "calendar_type": "Kalendertyp",
      "action": "Åtgärd",
      "round_robin": "Rund-Robin",
      "event": "Händelse",
      "group_name": "Gruppnamn",
      "calendars": "Kalendrar",
      "date_updated": "Uppdateringsdatum",
      "status": "Status",
      "action_dropdown": "Åtgärdsrullgardinsmeny",
      "edit": "Redigera",
      "duplicate": "dubbelgångare",
      "copy_embed_code": "Kopiera inbäddningskod",
      "copy_permanent_link": "Kopiera permanent länk",
      "copy_new_slug_link": "Kopiera schemalänk",
      "copy_scheduling_link": "Kopiera schemalänk (äldre version föråldrad)",
      "deactivate_calendar": "Inaktivera kalender",
      "move_to_group": "Flytta till grupp",
      "delete_calendar": "Radera kalender",
      "delete_group": "Ta bort",
      "delete_this_group": "Ta bort denna grupp",
      "activate_calendar": "Aktivera kalender",
      "duration": "Längd (min)",
      "all": "Alla",
      "draft": "Utkast",
      "active": "Aktiv",
      "rearrange_calendars": "Omlägg Kalendrar",
      "deactivate_all_calendars_in_group": "Inaktivera alla kalendrar i grupp",
      "activate_all_calendars_in_group": "Aktivera alla kalendrar i gruppen",
      "no_groups_found": "Din sökning matchade inte några kalendergrupper",
      "no_groups_in_location": "Börja använda Grupp genom att skapa en ny kalendergrupp",
      "no_calendars_found": "Börja använda kalender genom att skapa en ny kalender",
      "no_calendars_found_search": "Din sökning matchade inga kalendrar",
      "delete_calendar_message": "Du är på väg att radera kalendern. När du trycker på 'radera' kommer det också att radera alla dess möten",
      "delete_calendar_message_title": "Radera den här kalendern?",
      "no_calendars_found_title": "Ingen kalender hittades",
      "no_groups_in_location_title": "Ingen grupp hittad",
      "delete_group_confirmation_title": "Radera denna grupp?",
      "delete_group_confirmation": "Är du säker på att du vill ta bort den valda gruppen?",
      "delete_group_confirmation_checkbox_title": "Radera anknutna kalendrar och möten",
      "delete_group_confirmation_checkbox_description": "Att välja detta alternativ kommer även att radera kalendrarna i denna grupp, tillsammans med alla inbokade möten i dessa kalendrar",
      "group_status_message_title": "{status} den här gruppen?",
      "calendar_status_message_title": "{status} den här kalendern?",
      "calendar_status_message": "Du kommer att {status} kalendern",
      "group_status_message": "Du är på väg att {status} Grupp",
      "cancel": "Avbryt",
      "new_service_menu": "Nytt tjänstmeny",
      "service_menus": "Servicemeny",
      "manage_your_calendars_groups_and_service_menus": "Hantera dina kalendrar, grupper och tjänstemeny",
      "deactivate_service_menu": "Avaktivera tjänstemenyn",
      "delete_service_menu": "Radera denna meny för tjänster",
      "delete_service_menu_confirmation": "Är du säker på att du vill radera den här tjänstemenyn?",
      "service_menu_status_message_title": "{status} detta tjänstmeny?",
      "service_menu_status_message": "Du är på väg att {status} Service-menyn",
      "no_service_menus_in_location_title": "Skapa din anpassade tjänstemeny",
      "add_new_service_menu": "Lägg till ny tjänstmeny",
      "no_service_menus_in_location": "Börja med att skapa en ny tjänstemeny och lägg till grupper i den",
      "no_service_menu_found": "Inget servicealternativ hittades",
      "no_service_menu_found_desc": "Din sökning matchade inte några tjänstemenyer",
      "rooms": "Rum",
      "equipments": "Utrustning",
      "share_otl": "Dela din tillgänglighet varje gång med en unik länk som löper ut efter en bokning, vilket säkerställer kontrollerad åtkomst",
      "generate_link": "Skapa ny länk",
      "share_schedule_link": "Schemalänken bestäms av slugen. Justera slugen, och schemalänken anpassar automatiskt till förändringen",
      "share_permanent_link": "Perfekt för trattar, webbplatsbyten eller annonser, den permanenta länken förblir konstant, opåverkad av slug-ändringar",
      "copy": "Kopia",
      "share_embed_code": "Placera denna kod i din HTML där du vill att din widget för {embedType} ska visas",
      "scheduling_link": "Planering länk",
      "permanent_link": "Permanent länk",
      "one_time_link": "En gångs länk",
      "embed_code": "Bädda in kod",
      "loading": "Laddar...",
      "round_robin_booking": "Omröstning",
      "round_robin_description": "Fördelar ut möten bland teammedlemmarna i en roterande ordning",
      "round_robin_example": "Till exempel: Försäljningssamtal, onboarding-sessioner",
      "unassigned_booking": "Evenemangskalender",
      "unassigned_description": "För schemaläggning av fysiska evenemang utan värdorganisation",
      "unassigned_example": "Till exempel: Konferenser, offentliga seminarier, utställningar",
      "class_booking": "Klassbokning",
      "class_booking_description": "En värd träffar flera deltagare",
      "class_booking_example": "Till exempel: Webbinarier, gruppträning, onlinekurser",
      "collective_booking": "Kollektiv bokning",
      "collective_booking_description": "Flera värdar träffar en deltagare",
      "collective_booking_example": "Till exempel: Panelintervjuer, kommittégranskningar",
      "service_booking": "Tjänstebokning",
      "service_booking_description": "Smidig schemaläggning för servicebaserade företag",
      "service_booking_example": "Till exempel: Spa-bokningar, reparations tjänster, konsultationer",
      "personal_booking": "Personlig bokning",
      "personal_booking_description": "Schemalägger enskilda möten med en specifik teammedlem",
      "personal_booking_example": "Till exempel: Kundmöten, privata konsultationer",
      "personal": "Personlig",
      "explore_more_types": "Utforska fler typer",
      "scheduling_type": "Tidsschematyp",
      "scheduling_type_description": "Välj en schematyp för din nya kalender"
    },
    "preferences": {
      "preferences": "Preferenser",
      "my_preferences": "Min preferens",
      "account_preferences": "Kontoinställningar",
      "user_preferences": "Användarinställningar",
      "set_preferences": "Ställ in dina preferenser för ditt konto",
      "scheduling_options": "Schemaalternativ",
      "meeting_location": "Mötesplats",
      "zoom": "Zoom",
      "save_preferences": "Spara inställningar",
      "in_app_preferences": "I App-inställningar",
      "set_start_day": "Ställ in dina preferenser, som startdag för appen",
      "view_options": "Visningsalternativ",
      "week_starts_on": "Veckan börjar på",
      "sunday": "söndag",
      "widget_preferences": "Widgetinställningar",
      "set_widget_language": "Ställ in preferenser som språk för widget",
      "language_and_region": "Språk och region",
      "language": "Språk",
      "english": "engelska",
      "time_format": "Tidsformat",
      "time_format_value": "5,625",
      "monday": "måndag",
      "custom_meeting_location": "Anpassad mötesplats"
    },
    "availability": {
      "availability": "Tillgänglighet",
      "my_availability": "Min tillgänglighet",
      "account_availability": "Kontotillgänglighet",
      "timezone": "Tidszon",
      "working_hours": "Arbetstimmar",
      "set_recurring_hours": "Ställ in dina återkommande arbetstider för möten",
      "sunday": "Söndag",
      "monday": "Måndag",
      "tuesday": "tisdag",
      "wednesday": "onsdag",
      "thursday": "torsdag",
      "friday": "fredag",
      "saturday": "Lördag",
      "widget_preferences": "Widgetinställningar",
      "set_widget_language": "Ställ in preferenser som språk för widget",
      "language_and_region": "Språk och region",
      "language": "Språk",
      "english": "Engelska"
    },
    "connections": {
      "connections": "Kopplingar",
      "conferencing": "Videokonferens",
      "my_connections": "Mina kontakter",
      "account_connections": "Kontaktanslutningar",
      "connection_preferences": "Anslutningsinställningar",
      "manage_calendars_conferencing_channels": "Hantera kalendrar och konferenskanaler",
      "main_integration_calendar": "Huvud Integrationskalender",
      "event_integration_calendar": "Händelser som skapas på en systemkalender som du är en del av kommer också att skapas på denna integrerade kalender",
      "2way_sync": "2-vägs synkronisering: Skapa systemhändelser från bokade händelser på den integrerade huvudkalendern",
      "calendars": "Kalendrar",
      "google": "Google",
      "outlook": "Utsikt",
      "check_for_conflicts": "Kontrollera konflikter",
      "choose_accounts_to_connect": "Välj vilka konton du vill koppla ihop",
      "payments": "Betalningar",
      "stripe": "Rand",
      "ads": "Annonser",
      "local_service_ads": "Lokala tjänstannonser",
      "fire_workflow": "Brand Workflow-utlösare för bokade händelser via tvåvägssynkronisering",
      "connect_google_outlook": "Vänligen koppla ditt Google- eller Outlook-konto för att kunna välja primär kalender",
      "execute_automation": "Utför Automation",
      "save": "Spara",
      "lsa": "Lokala tjänsteannonser"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Välj en kontakt",
    "start_time": "Starttid",
    "end_time": "Slutetid",
    "search_by_name_email_phone_or_company": "Sök efter namn, e-postadress, telefon eller företag",
    "add_new": "Lägg till nytt",
    "pick_from_available_contacts": "Eller välj från tillgängliga kontakter",
    "book_appointment": "Boka tid",
    "calendar": "Kalender",
    "repeat": "Upprepa",
    "date_and_time": "Datum och tid",
    "close": "Stänga",
    "team_member": "Lagmedlem",
    "recurring_booking": "Återkommande bokning",
    "one_slot_booking": "Enkel bokning av tid eller plats",
    "event_type": "Evenemangstyp",
    "occurrences_booked": "händelser kommer att bokas",
    "repeats": "Upprepningar",
    "booking_details": "Bokningsdetaljer",
    "showing_slots_in_this_timezone": "Visar tidsluckor i denna tidszon:",
    "update_contact_tz": "Uppdatera även tidszon för kontakt till",
    "date": "Datum",
    "slot": "Slott",
    "calendar_default": "Kalenderstandard",
    "custom": "Sedvanerätt eller skräddarsydd",
    "meeting_notes": "(Mötesanteckningar för vårt kommande möte)",
    "out_of_office": "(Ur) Kontoret är stängt hela veckan!",
    "standard": "Standard",
    "appointment_title": "Titel på utnämning",
    "event_title": "Händelsetitel",
    "appt_with_bob": "Träff med Bob",
    "vacation": "Ledighet",
    "show_notes": "Visa anteckningar",
    "hide_notes": "Dölj anteckningar",
    "additional_preferences": "Ytterligare inställningar",
    "meeting_location": "Mötesplats",
    "appointment_status": "Tidsbokningsstatus",
    "appt_description": "Beskrivning av mötet",
    "recurring_details": "Återkommande detaljer",
    "event_description": "Evenemangsbeskrivning",
    "in_contact_timezone": "I kontaktens tidszon",
    "vacation_message": "Ska du på semester? Tar du ledigt? Blockera tid i din kalender för att hindra kunder från att boka möten - befintliga möten kommer fortfarande att finnas kvar i din kalender",
    "contact": "Kontakt",
    "delete": "Radera",
    "cancel": "Avbryt",
    "back": "Tillbaka",
    "pick_available_contacts": "Eller välj från tillgängliga kontakter",
    "no_contacts_found": "Inga kontakter hittades",
    "found": "hittade",
    "select_one": "Välj en",
    "tz_tooltip": "Detta har betydelse endast om du använder egendefinierade värden, såsom tid för bokningsstart, tid för bokningsslut och tidszon för bokning, vid din utgående kommunikation till dina kunder eller kunder. Och vad det helt enkelt gör är att konvertera ovan nämnda detaljer till deras lokala tid",
    "meeting_location_tooltip": "Mötesplatsen kommer att ställas in enligt kalendern",
    "appt_meeting_location_tooltip": "Användaren kan ange mötesplatsen specifik för mötet",
    "gmeet_tooltip": "Var god se till att det aktuella teammedlemmen har en Google-kalender inställd som deras primära kalender",
    "zoom_tooltip": "Se till att det relevanta teammedlemmen har integrerat Zoom, tack",
    "enter_meeting_location": "Ange mötesplats",
    "save_appointment": "Spara möte",
    "save_event": "Spara händelse",
    "search_contacts": "Sök efter namn, e-post, telefon eller företag",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Kalender",
    "appointment_owner": "Mötesägare",
    "source": "Källa",
    "booked_by": "Bokad av",
    "view_activity_log": "Visa aktivitetslogg",
    "status": "Tillstånd",
    "cancel_appointment": "Avboka möte",
    "google_event": "Google-evenemang",
    "outlook_event": "Outlook-händelse",
    "blocked_slot": "Blockerad spår",
    "appointment": "Möte",
    "confirmed": "Bekräftad",
    "unconfirmed": "Obekräftad",
    "cancelled": "Inställt",
    "showed": "visade",
    "no_show": "uteblivet besök",
    "invalid": "Ogiltig"
  },
  "appointment_list": {
    "group_all": "Alla",
    "group_primary": "GRUNDSKOLA",
    "group_user": "ANVÄNDARE",
    "group_team": "GRUPPER",
    "group_calendar": "Kalendrar",
    "all_appointments": "Alla bokningar",
    "my_calendar": "Min kalender",
    "status_all": "Alla",
    "status_confirmed": "Bekräftad",
    "status_new": "Ny (Åtgärd krävs)",
    "status_show": "Visade",
    "status_no_show": "ingen show",
    "status_cancelled": "Inställt",
    "status_invalid": "Ogiltig",
    "filter_date_added_desc": "Datum tillagt (Fallande)",
    "filter_start_added_desc": "Startdatum (Fallande)",
    "filter_start_added_asc": "Startdatum stigande från idag",
    "button_new_appointment": "Ny Utställning",
    "name": "Namn",
    "title": "Titel",
    "requested_time": "Önskad tid",
    "date_added": "Datum tillagt",
    "calendar": "Kalender",
    "appointment_owner": "Tidsbokningsägare",
    "notes": "anteckningar",
    "status": "Tillstånd or Status",
    "action_view_detail": "Visa detaljer",
    "action_view_notes": "Visa anteckningar",
    "action_edit": "Redigera",
    "action_delete": "Radera",
    "action_reschedule": "Omboka",
    "page_previous": "Föregående",
    "page_next": "Nästa",
    "appointment_notes": "Tidsanteckningar",
    "close": "Stängt",
    "confirm_delete_header": "Radera möte",
    "confirm_delete_message": "Är du säker på att du vill ta bort denna tidpunkt?",
    "confirm_delete_button": "Radera",
    "confirm_update_header": "Uppdatera mötesstatusen",
    "confirm_update_confirmed_message": "Är du säker på att du vill markera den här bokningen som bekräftad?",
    "confirm_update_invalid_message": "Är du säker på att du vill markera den här tiden som ogiltig?",
    "confirm_update_showed_message": "Är du säker på att du vill markera den här tiden som visad?",
    "confirm_update_noshow_message": "Är du säker på att du vill markera den här tiden som utebliven?",
    "confirm_update_cancelled_message": "Är du säker på att du vill markera den här bokningen som inställd?",
    "confirm_update_button": "Uppdatering",
    "disable_menu_warning": "För tillfället har du inte behörighet att visa eller redigera detaljerna för mötet, eftersom den associerade kontakten inte är tilldelad till dig. Vänligen kontakta din administratör för vidare hjälp",
    "no_contact_warning": "Du kan för närvarande inte komma åt kontaktinformationen. Detta kan bero på att kontakten inte är tilldelad dig. Var god kontakta din administratör för hjälp",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Ombokad"
  },
  "create_calendar": {
    "calendar_name": "Kalendernamn",
    "calendar_logo": "Kalenderlogotyp",
    "select_team_member": "Välj teammedlemmar",
    "eg_outbound_reach": "Utåtriktad kontakt",
    "description": "Beskrivning",
    "amount": "Mängd",
    "description_placeholder": "Skriv beskrivning",
    "meeting_duration": "Mötesvaraktighet",
    "seats_per_class": "Platser per klass",
    "group": "Grupp",
    "custom_url": "Anpassad webbadress",
    "meeting_invite_title": "Mötesinbjudanstitel",
    "event_color": "Händelsefärg",
    "availability_type": "Tillgänglighetstyp",
    "deposit_amount": "Insättningsbelopp",
    "collect_deposit_amount": "Acceptera delbetalning",
    "percentage": "Procentandel",
    "total_amount": "Totalt belopp",
    "reschedule_preference": "Omboka preferens",
    "reschedule_preference_subtext": "Om en kontakt ändrar tid för en bokad tid:",
    "reassign_through_round_robin": "Tilldela på nytt genom rundrobin",
    "keep_same_assinged_user": "Behåll samma bokningsägare",
    "new_appointment_preference": "Ny Tidsbokningspreferens",
    "new_appointment_preference_text": "Boka alltid med den tilldelade användaren för kontakten",
    "new_appointment_preference_subtext": "Boka nya avtal med den tilldelade användaren för kontakten istället för att använda round-robin schemaläggning"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Snabbt tips",
      "timezone_change_tip": "Tidszoner kan ändras när som helst under inställningarna för tidszon",
      "save": "Spara",
      "close": "Stänga",
      "meeting_details": "Mötesuppgifter",
      "availability": "Tillgänglighet",
      "forms_and_payment": "Formulär och betalning",
      "notifications_and_additional_options": "Aviseringar och ytterligare alternativ",
      "connections": "Förbindelser",
      "customizations": "Anpassningar",
      "create": "Skapa",
      "edit": "Redigera"
    },
    "meeting_details": {
      "meeting_location": "Mötesplats",
      "enter_meeting_location": "Skriv in mötesplatsen",
      "click_to_upload": "Klicka för att ladda upp",
      "square": "Kvadrat",
      "circle": "Cirkel",
      "remove_logo": "Ta bort logotyp",
      "meeting_invite_title_tooltip": "Detta är titeln för händelsen i kalendern som visas i Google Kalender, Outlook, Apple Kalender, etc",
      "event_color_tooltip": "Välj en färg för denna bokningshändelse. Färgerna synkroniseras med händelser i Google-kalendrar",
      "enter_a_name": "Vänligen ange ett namn",
      "enter_an_event_title": "Ange en händelsetitel, tack",
      "high_priority": "Hög prioritet",
      "medium_priority": "Medelhög prioritet",
      "low_priority": "Låg prioritet",
      "custom": "Anpassad",
      "zoom_not_added": "Användaren har inte anslutit sitt Zoom-konto ännu",
      "opt_avail": "Optimera för tillgänglighet",
      "opt_equal": "Optimera för lika fördelning",
      "team_members": "Välj lagmedlemmar",
      "team_member": "Välj lagmedlem",
      "meeting_location_required": "Mötesplats krävs",
      "team_member_disabled_recurring_tooltip": "Återkommande möten tillåter endast en teammedlem. För att lägga till flera teammedlemmar, vänligen inaktivera återkommande möten.",
      "team_member_disabled_class_booking_tooltip": "Endast en lagmedlem kan läggas till i en klassbokningskalender",
      "enable_contact_assigned_to_setting": "För att aktivera denna inställning måste formuläret vara det första i widget-ordningen. Vänligen omorganisera widget-ordningen från fliken Formulär & Betalning",
      "reschedule_preference_setting_tooltip": "Välj vem utnämningen ska tilldelas när en kontakt bokar om från bokningswidgeten",
      "appointment_preference_tooltip": "Välj om nya bokningar alltid ska göras med kontaktpersonens tilldelade användare. Om en kontakt har en tilldelad användare kommer den användaren ges företräde; annars kommer det att vara en round-robin tilldelning. Tips: Stäng av 'Tillåt personalval' för att förhindra bokare från att ändra personalen på bokningswidgeten",
      "add_location": "Lägg till plats",
      "multiple_locations_neo": "Flera mötesplatser stöds endast av Neo-widgeten med standardformuläret",
      "make_owner": "Gör ägare",
      "only_one_team_member": "Endast ett lagmedlem tillåts för personlig bokning"
    },
    "availability": {
      "my_availability": "Min tillgänglighet",
      "set_availability": "Ange tillgänglighet för kalendern här",
      "standard": "Standard",
      "custom": "Anpassad",
      "to": "Till",
      "copy_to_all": "Kopiera till alla",
      "add_time": "Lägg till tid",
      "recurring_meeting": "Återkommande möte",
      "recurring_info": "Endast en användare per kalender för återkommande bokningar",
      "repeat": "Upprepa",
      "times_to_repeat": "Antal repetitioner",
      "slots_unavailable": "Om det inte finns några lediga platser?",
      "overlap_status": "Status för överlappande tidsluckor vid bokning",
      "meeting_interval": "Mötesintervall",
      "meeting_duration": "Mötestid",
      "minimum_scheduling": "Minimun tid för schemaläggning",
      "date_range": "Datumintervall",
      "maximum_bookings_per_day": "Maximala bokningar per dag",
      "maximum_bookings_per_slot": "Maximala bokningar per tidslucka",
      "maximum_bookings_per_slot_per_user": "Maximalt antal bokningar per tidsintervall (per användare)",
      "seats_per_class": "Säten per klass",
      "pre_buffer_time": "Förhandsbuffertid",
      "post_buffer_time": "Eftersläpt tid för post",
      "pre_buffer_tooltip": "Förbuffer är ytterligare tid som kan läggas till före en tidpunkt, vilket möjliggör extra tid att förbereda sig innan något möte",
      "post_buffer_tooltip": "Post-Buffer är ytterligare tid som kan läggas till efter en bokad tid, vilket ger extra tid för avslutning",
      "buffer_time": "Buffertid",
      "daily": "Daglig",
      "weekly": "Veckovis",
      "monthly": "månadsvis",
      "skip_booking": "Hoppa över bokning för otillgängliga tider",
      "continue_booking": "Fortsätt att boka",
      "book_next_available": "Boka nästa tillgängliga tidslucka",
      "confirmed": "Bekräftad",
      "unconfirmed": "Obekräftad",
      "enter_valid_hours": "Ange giltiga öppettider, tack",
      "enter_valid_avail": "Vänligen ange giltigt anpassat tillgänglighet",
      "availability_type_tooltip": "Välj Standard Tillgänglighet för regelbundna veckobokningar. Välj Anpassad för bokningar som bara är tillgängliga på specifika datum",
      "meeting_interval_tooltip": "Konferensintervall avspeglar den tid som ska visas mellan bokningsslots i kalendern. För ett evenemang på 30 minuter som ska vara tillgängligt i början av varje timme är intervallet 1 timme. För ett evenemang på 30 minuter som ska vara tillgängligt varje 30 minuter är intervallet 30 minuter",
      "date_range_tooltip": "Evenemang kan schemaläggas under de närmaste n dagarna",
      "maximum_bookings_per_day_tooltip": "Maximalt antal bokningar tillåtet per dag på denna kalender",
      "maximum_bookings_per_slot_tooltip": "Maximalt antal möten tillåtna per slot för användaren (Om antalet möten överstiger för den givna sloten för användaren, kommer den antingen att byta till nästa användare i rondrobin-kön eller markera sloten som otillgänglig för prospekten att boka)",
      "add_days": "Lägg till dagar",
      "month_on_day": "Månadsvis på dag",
      "month_on_last_day": "Månatligen på sista dagen",
      "day": "dag",
      "week": "Vecka",
      "month": "Månad",
      "mins": "Minuter",
      "hours": "Timmar",
      "days": "Dagar",
      "weeks": "Veckor",
      "months": "Månader",
      "meeting_interval_error": "Uppgiftsintervall måste vara mellan 5 minuter och 12 timmar",
      "meeting_duration_error": "Mötestiden måste vara mellan 1 minut och 12 timmar",
      "buffer_time_error": "Buffertiden måste vara mindre än 12 timmar",
      "subTitle": "Välj datum för att ange specifika timmar",
      "heading_1": "Veckotimmar tillgängliga",
      "tooltip_1": "Ställ in din veckovisa tillgänglighetsschema. Detta är dina standardtimmar som kommer att tillämpas konsekvent varje vecka",
      "heading_2": "När är du tillgänglig?",
      "unavailable_label": "otillgänglig",
      "tooltip_2": "Justera din tidplan för specifika datum. Åsidosätt dina regelbundna veckotimmar genom att markera tillgänglighet eller otillgänglighet för valda datum",
      "date_specific_level": "Lägg till timmar för specifikt datum",
      "specific_hours_button": "Datum och specifika timmar",
      "overlap_error": "Tidsintervallen kan inte överlappa varandra",
      "apply": "Ansöka",
      "copy_times_to": "Kopiera gånger till",
      "no_date_specific_heading": "Ingen specifik tidpunkt tillagd",
      "no_date_specific_description": "Du kan lägga till/ta bort specifik datum och tid från din tillgänglighet",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "För att inkludera ett datumspecifikt klockslag, se till att återkommande möten är avstängda",
      "recurring_disable_event_calendar_conditions": "Återkommande möten är inaktiverade. För att aktivera dem, se till att betalning är avstängd och inga datum-specifika tider är tillagda",
      "service_interval": "Serviceintervall",
      "service_interval_tooltip": "Mötesintervallet bestämmer hur ofta bokningsluckor visas i kalendern. Till exempel betyder en timmes intervall att luckor visas varje timme för en 30-minuters händelse. En 30-minuters intervall betyder att luckor visas varje 30 minuter",
      "service_duration_tooltip": "Detta bestämmer längden på din tidbokning och avgör hur länge den kommer att vara",
      "minimum_scheduling_tooltip": "Detta anger den minsta tid som krävs för att boka en tid via kalenderwidgeten. Till exempel, om det är inställt på 4 timmar så kan inte tider bokas inom 4 timmar från starttiden; bokningar måste göras minst 4 timmar i förväg",
      "service_duration": "Service duration",
      "look_busy_title": "Se upptagen",
      "look_busy_percentage_description": "Dölj antalet tillgängliga platser med x%",
      "look_busy_helper_text": "Dölj en procentandel av dina tillgängliga tidsplatser på bokningswidgeten för att verka upptagen och efterfrågad",
      "maximum_bookings_per_slot_tooltip_personal": "Högsta antal tillåtna bokningar per tidsintervall för användaren"
    },
    "forms_payment": {
      "forms": "Former",
      "forms_sub": "Ange inställningar för formulär för att schemalägga inlägg",
      "select_form": "Välj formulär",
      "sticky_contacts": "Förifylla fält (klisterkontakter)",
      "confirmation_page": "Bekräftelsesida",
      "default": "Standard",
      "redirect_url": "Omdirigerings-URL",
      "thank_you": "Tackmeddelande",
      "enter_url": "Ange webbadress",
      "pixel_id": "Facebook pixel ID (valfritt)",
      "auto_confirm": "Automatiskt bekräfta nya kalendermöten",
      "configure_payment": "Konfigurera betalningsalternativ",
      "accept_payments": "Acceptera betalningar",
      "test": "Prov",
      "live": "Leva",
      "enable_guests": "Lägg till gäster",
      "add_guest_tooltip": "Lägg till gäst kommer att göra det möjligt för din deltagare att lägga till flera deltagare i samma möte",
      "add_guest_same_form_check": "Skicka samma blankett för att samla information via brev till alla gäster",
      "payment_tooltip": "Endast betalning för huvuddeltagaren skulle beaktas och inte för gästerna",
      "default_form": "Standard (Förnamn, Efternamn, E-post, Telefon, Anteckningar)",
      "select_form_tooltip": "Om du vill samla in mer information än standard förnamn, efternamn, e-post och telefonnummer från de som bokar på denna kalender, skapa en anpassad formulär i Sites > Forms > Builder och välj sedan formuläret via rullgardinsmenyn nedan",
      "confirmation_page_tooltip": "När någon bokar kan du välja att visa dem ett anpassat meddelande på samma sida (standard) eller omdirigera dem till en sida du väljer (omdirigering)",
      "add_guest_collect_email": "Med Namn och email",
      "add_guest_count_only": "Räkna bara",
      "custom_email_notification": "Skicka anpassat formulär till gäster",
      "deposit_amount_error": "Insättningssumman måste vara lägre än det totala beloppet",
      "deposit_percentage_error": "Insättningsprocenten måste vara mindre än 100%",
      "partial_payment_tooltip": "Möjliggör delbetalningar för att samla in depositionsbelopp från kunder under bokningsprocessen för avtalade möten",
      "partial_payment_enable_error": "Det totala beloppet som har angetts måste vara större än 0 för att möjliggöra delbetalningar.",
      "payment_disable_text_integration": "Integrera en betalningsleverantör under Betalningar > Integrationer för att börja ta emot betalningar",
      "payment_disable_text_recurring": "Accepteringsalternativet för betalning är inte tillgängligt för återkommande möten. Stäng av återkommande för att möjliggöra betalningar",
      "date_and_time_selector": "Datum- och tidsväljare",
      "form_selector": "Form",
      "order_step": "Steg",
      "widget_order_label": "Widget ordning",
      "widget_order_tool_tip": "Omarrangera ordningen på 'Datum- och tidväljaren' och 'Formulär' på kalenderwidgeten. Dra helt enkelt och ordna om enligt ditt önskemål",
      "same_user_assignment_tooltip": "För att aktivera detta alternativ måste formuläret vara först i widgeten. Ordna om widgeten så att formuläret placeras först för att aktivera detta alternativ",
      "widget_re_order_tooltip": "Omarrangemang är endast tillgängligt med Neo widget-stilen. Byt till Neo för att aktivera omarrangemang",
      "widget_re_order_disabled_tooltip": "För att omorganisera widgetens ordning, inaktivera 'Ny mötespreferens (Alltid boka med kontaktpersonens tilldelade användare)' från Mötesdetaljer > Teammedlemmar > Avancerade inställningar avsnittet",
      "enable_same_user_assignment": "Tilldela kontakter till sina respektive kalendarteammedlemmar varje gång en tid bokas"
    },
    "notifications": {
      "notifications": "Meddelanden & Ytterligare alternativ",
      "notifications_sub": "Konfigurera avisering och ytterligare alternativ",
      "select_notification_type": "Välj typ av notifiering",
      "notification_person": "Vem ska få denna anmälan?",
      "contact": "Kontakt",
      "assigned_user": "Tilldelad användare",
      "emails": "E-postmeddelanden",
      "alert_email_address": "Larmmejladress",
      "ack_email": "erkännande-email",
      "send_google_invites": "Tillåt Google-kalender att skicka inbjudningar eller uppdateringsmejl till deltagarna",
      "send_google_outlook_invites": "Tillåt Google / Outlook Calendar att skicka inbjudnings- och uppdateringsmejl till deltagare",
      "assign_user_to_contact": "Tilldela kontakter till sina respektive kalenderteammedlemmar varje gång en bokning görs",
      "skip_assigning_user_to_contact": "Hoppa över tilldelning av kontakt om kontakten redan har en tilldelad användare",
      "guest_label_count": "Antal gäster",
      "guest_count_error": "Antalet gäster bör vara mellan 1 och 100",
      "allow_cancellation": "Tillåt avbokning av mötet",
      "allow_reschedule": "Möjliggör ombokning av möte",
      "allow_cancellation_tooltip": "När det är aktiverat, kommer en avbokningslänk att läggas till i avsnittet för tilläggsanteckningar och inkluderas i kalenderinbjudan. Denna länk kommer att gå ut enligt konfigurationen före mötets starttid, vilket förhindrar bokaren från att avboka mötet",
      "allow_reschedule_tooltip": "När den är aktiverad kommer en omplaneringslänk att läggas till i avsnittet för ytterligare anteckningar och inkluderas i kalenderinbjudan. Denna länk kommer att gå ut enligt konfigurationen före mötestiden, vilket förhindrar att bokaren ändrar tidpunkten för mötet",
      "cancellation_link_expiry": "Avbokningslänken kommer att gå ut",
      "reschedule_link_expiry": "Ombokningslänken kommer att gå ut",
      "before_meeting": "innan mötet",
      "cancellation_and_reschedule_policy": "Avboknings- och ombokningspolicy:"
    },
    "connections": {
      "connections_heading": "Extern kalendersynkronisering",
      "connections_sub": "Synkronisera händelser med extern kalender",
      "link_to_calendar": "Länk till kalender",
      "sync_option": "Synkroniseringsalternativ",
      "execute_automation": "Utför automatisering",
      "fire_workflow": "Aktivera arbetsflödessignaleringar för händelser bokade via tvåvägssynkronisering",
      "reserve_with_google": "Boka med Google",
      "connect_lsa": "Anslut till ditt lokala tjänster-annonskonto",
      "reserve_error": "För närvarande stöds bokningar via Google endast för företag inom \"Hemtjänster\", \"Fastigheter\" och \"Juridiska tjänster\" (lokala serviceleverantörer) inom företagsnischen i USA",
      "one_way": "Enkel väg",
      "two_way": "Tvåväg",
      "smart": "Smart",
      "confirm_remove": "Är du säker på att du vill ta bort Google-kalendern?",
      "read_only_access": "Du har inte skrivåtkomst till denna kalender. Vänligen välj en annan kalender",
      "reserve_with_google_desc": "Denna kalendertjänst kommer att listas på reserver hos Google endast vid lyckad integration",
      "check_integration": "Kontrollera din integrationsstatus"
    },
    "extras": {
      "extras": "Anpassningar",
      "extras_sub": "Ange widget-stil och andra inställningar",
      "calendar_widget_style": "Kalenderwidget-stil",
      "neo": "Neo",
      "classic": "Klassisk",
      "insert_custom_code": "Infoga anpassad kod",
      "show_seats_per_slot": "Visa platser per tidslucka",
      "allow_reschedule_meeting": "Tillåt omplanering av mötet",
      "allow_cancelling_meeting": "Tillåt att avboka mötet",
      "additional_notes": "Ytterligare anteckningar",
      "cancellation_and_reschedule": "Avbokning och ombokning:",
      "allow_staff": "Tillåt personalval vid bokning",
      "code_block_tooltip": "Tredjepartsskript inuti HTML-blocket kan ge kraftfull funktionalitet, men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i detta skript innan du sparar det till kalendern"
    }
  },
  "add_guest": {
    "guest_label": "Gäst(er)",
    "search_placeholder": "Sök från kontakter",
    "add_new": "Lägg till nytt",
    "save": "Spara",
    "close": "Stänga",
    "name": "Namn",
    "phone": "Telefon",
    "email": "E-post"
  },
  "languages": {
    "english": "Engelska",
    "dutch": "Holländska",
    "french": "Franska",
    "german": "Tyska",
    "hungarian": "Ungerska",
    "italian": "Italienska",
    "polish": "Polska",
    "portuguese_brazil": "Portugisiska (Brasilien)",
    "portuguese_portugal": "Portugisiska (Portugal)",
    "spanish": "Spanska",
    "danish": "Danska"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Service menyn detaljer",
    "select_groups_header": "Välj tjänster",
    "arrange_groups_header": "Organisera dina tjänster",
    "new_service_menu": "Nytt tjänstmeny",
    "service_menu_sub_title": "Skapa en personlig tjänstemeny för dina kunder på bara några få klick",
    "quick_tip": "Du kan lägga till grupper i tjänstemenyn",
    "service_menu_name": "Service Menynamn",
    "service_menu_description": "Beskrivning",
    "service_menu_slug": "Tjänstens URL",
    "select_groups_calendars": "Välj grupper och kalendrar",
    "select_groups_calendars_desc": "Lägg till tjänster i din tjänstemeny",
    "select_all": "Välj alla",
    "arrange_groups": "Arrangera ordning på tjänsterna",
    "arrange_groups_desc": "Bestäm följden av tjänster på tjänstemenyn",
    "arrange_services_quicktip": "För att omorganisera kalendrar inom en grupp, gå till Grupper > Alternativ > Omorganisera kalendrar",
    "enable_add_guest_desc": "Låter kunderna inkludera extra gäster vid bokning",
    "enable_multiple_service_desc": "Tillåter kunder att boka flera tjänster vid en enda tidpunkt",
    "enable_staff_selection_desc": "Tillåter kunder att välja önskad personal när de bokar",
    "enable_add_guest": "Tillåt tillägg av gäster",
    "enable_multiple_service": "Tillåt val av flera tjänster",
    "enable_staff_selection": "Aktivera personalval",
    "additional_options": "Ytterligare alternativ",
    "code_block_tooltip": "Tredjepartsskript inuti HTML-blocket kan erbjuda kraftfull funktionalitet, men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i detta skript innan du sparar det i tjänstemenyn",
    "custom_code_placeholder": "Var god ange anpassad kod här",
    "insert_custom_code": "Infoga anpassad kod",
    "no_service": "Ingen tjänst hittades",
    "no_service_desc": "Inga tjänster hittades. Skapa tjänster och lägg till dem i grupper för att skapa en tjänstemeny",
    "learn_more": "Läs mer",
    "no_service_selected": "Ingen service vald",
    "create_service_calendars": "Skapa tjänstekalendrar",
    "alert_description": "För att skapa en tjänstemeny behöver du skapa tjänstekalendrar och lägga till dem i grupper",
    "dont_show_again": "Visa inte detta igen",
    "service_name_required": "Service-menynamn krävs",
    "slug_already_taken": "Denna snigel är redan upptagen",
    "slug_required": "Snigel är obligatorisk",
    "please_enter_name": "Var god ange ett namn",
    "service_name_placeholder": "Huvudmeny",
    "consent_label": "Samtyckesruta",
    "consent_input_placeholder": "Lägg till samtyckestext",
    "consent_tooltip": "Aktivera denna inställning för att möjliggöra samtycke från kontakter när de lämnar sitt telefonnummer under bokningsprocessen för avtalade möten"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Anpassa kalenderwidgeten",
    "works_with_neo_widget": "Fungerar endast med Neo-widget",
    "customise_widget_desc": "Anpassa widgetens utseende: primärfärg, bakgrundsfärg och knapptext.",
    "learn_more": "Läs mer",
    "primary_settings": "Primära inställningar",
    "primary_settings_desc": "Konfigurera primärfärg, bakgrundsfärg och andra widgetinställningar för att anpassa din kalenderwidget",
    "primary_color": "Primärfärg",
    "primary_color_desc": "Definiera färgen för knappar, datum, tidsintervall och åtgärder",
    "background_color": "Bakgrundsfärg",
    "background_color_desc": "Ställ in bakgrundsfärgen som fungerar som bakgrund för både datum-tidsväljaren och formuläret",
    "button_text": "Knapp Text",
    "button_text_desc": "Anpassa texten som visas på knappen vid bokning av en tid",
    "calendar_title": "Kalendertitel",
    "calendar_title_desc": "Namnet på din kalender",
    "calendar_description": "kalenderbeskrivning",
    "calendar_description_desc": "Beskrivningen som angavs",
    "calendar_details": "Kalender Detaljer",
    "calendar_details_desc": "Inkluderar information såsom varaktighet, datum och tid, upprepande detaljer och tidszon",
    "reset_to_default": "Återställ till standard",
    "preview_widget": "Förhandsvisningswidget"
  }
}
