<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import router from '@/router'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import 'moment/dist/locale/da'
import 'moment/dist/locale/de'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fi'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/fr-ca'
import 'moment/dist/locale/hr'
import 'moment/dist/locale/hu'
import 'moment/dist/locale/it'
import 'moment/dist/locale/nb'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'
import 'moment/dist/locale/sv'

import Postmate from 'postmate'
import { computed, onMounted } from 'vue'
import AppState, {
  completeResetAppState,
  fetchCalendarLocationSettings,
  fetchRequiredData,
  getCalendarsInLocation,
  getEquipmentsInLocation,
  getRoomsInLocation,
  getServiceMenusInLocation,
  getTeamsInLocation,
  initPendo,
  resetAppState,
  setActivityLogId,
  setApiKey,
  setAppointmentToBeEdited,
  setAuthApiKey,
  setBaseUrl,
  setCalendarPageInvokedData,
  setCompanyId,
  setContactToBeFetched,
  setDisableContactTImezone,
  setEmbedUrl,
  setIsAddGuestsEnabled,
  setIsAppointmentNotesEnabled,
  setIsCustomizeWidgetConfigEnabled,
  setIsDateOverrideEnabled,
  setIsEnabledNeoGroupWidget,
  setIsICloudIntegrationEnabled,
  setIsLoading,
  setIsNewBlockSlot,
  setIsNewCalIntegrationEnabled,
  setIsNewCalendarSetupFlowEnabled,
  setIsNewEvent,
  setIsOrganicBookingEnabled,
  setIsRecurringEnabled,
  setIsTimezoneConfigEnabled,
  setIsTroubleshootToolEnabled,
  setIsUserPartOfLocation,
  setLocale,
  setLocationId,
  setLocationTimezone,
  setMediaFileURI,
  setParentApp,
  setPopoverData,
  setSource,
  setTokenId,
  setUser,
  setWebinarDetail,
  setWhitelabelDomain,
  toggleChangesSaved,
} from './states/app'
import { setSentryMeta } from './util/setupSentry'

function openParentConnection() {
  const handshake = new Postmate.Model({
    changeChildAppRoute(route: any) {
      router.push(route)
    },
    setTokenId: (tokenId: string) => {
      setTokenId(tokenId)
    },
    appointmentToBeEdited: (data: any) => {
      resetAppState(true)
      setSource(data.source)
      setAppointmentToBeEdited(data.eventId)
      setWebinarDetail(data)
      router.push({ name: 'create-appt' })
    },
    navigateToActivityLog: (data: any) => {
      setActivityLogId(data.eventId)
      router.push({ name: 'activity-log' })
    },
    setMediaFile: (file: any) => {
      if (file && file.url) {
        setMediaFileURI(file.url)
      }
    },
    openCalendarEventModal: (data: any) => {
      const editObj = JSON.parse(data)
      setSource(editObj.source)
      resetAppState(true, editObj.isUserCalendar)

      if (editObj.isNewEvent || editObj.isNewBlockSlot) {
        setCalendarPageInvokedData({
          startTime: editObj.startTime,
          endTime: editObj.endTime,
          calendarId: editObj.calendarId,
          isUserCalendar: editObj.isUserCalendar,
          userId: editObj.userId,
          calendarProviderId: editObj.calendarProviderId,
          version: editObj.isV2CalendarSelected ? 2 : 3,
        })
      }

      if (!editObj.isNewEvent && !editObj.isNewBlockSlot) {
        setAppointmentToBeEdited(editObj.eventId)
      }
      if (!editObj.isNewEvent) {
        setIsNewBlockSlot(editObj.isNewBlockSlot)
        router.push({ name: 'create-appt' })
      } else {
        setIsNewEvent(true)
      }
    },
    openCreateApptModal: ({ source }: any) => {
      setSource(source)
      resetAppState(false)
    },
    goToCalendarSettingsPage: () => {
      router.push({ name: 'calendar-settings__default' })
    },
    openPopover: (data: any) => {
      const apptData = JSON.parse(data)
      setPopoverData(apptData)
      resetAppState(false)
      router.push({ name: 'popover' })
    },
    refreshCalendars: async () => {
      setIsLoading(true)
      await fetchCalendarLocationSettings()
      await getCalendarsInLocation()
      await getTeamsInLocation()
      await getServiceMenusInLocation()
      await getRoomsInLocation()
      await getEquipmentsInLocation()
      setIsLoading(false)
    },
    refreshAppointments: () => {
      toggleChangesSaved()
    },
    resetApptModal: () => {
      completeResetAppState()
      router.push({ name: 'select-contact' })
    },
    openContactApptModal: ({ contactId, source }: any) => {
      setSource(source)
      resetAppState(false)
      setContactToBeFetched(contactId)
      router.push({ name: 'create-appt' })
    },
    appointmentList: (data: any) => {
      setLocationId(data.locationId)
      router.push({ name: 'appointments-list' })
    },
  })

  handshake.then((parent: any) => {
    setParentApp(handshake)
    const { model } = parent
    const {
      locationId,
      source,
      tokenId,
      apiKey,
      isUserPartOfLocation,
      authApiKey,
      userId,
      userType,
      userRole,
      timeZone,
      locale,
      isNewCalendarSetupFlowEnabled,
      whitelabelUrl,
      disableContactTimezone,
      baseUrl,
      embedUrl,
      company,
      recurringEventsEnabled,
      isAddGuestsEnabled,
      isEnabledNeoGroupWidget,
      isICloudIntegrationEnabled,
      isCustomizeWidgetConfigEnabled,
      isTimezoneConfigEnabled,
      isAppointmentNotesEnabled,
      isOrganicBookingEnabled,
      isDateOverrideEnabled,
      isNewCalIntegrationEnabled,
      isTroubleshootToolEnabled,
    } = model
    setSentryMeta({
      userId: model.userId,
      companyId: model.company.id,
      locationId: model.locationId,
      locale: model.locale,
    })
    setIsUserPartOfLocation(isUserPartOfLocation)
    setApiKey(apiKey)
    setDisableContactTImezone(disableContactTimezone)
    setBaseUrl(baseUrl)
    setEmbedUrl(embedUrl)
    setCompanyId(company?.id)
    setAuthApiKey(authApiKey)
    setWhitelabelDomain(whitelabelUrl)
    setLocationTimezone(timeZone)
    setLocale(locale)
    setIsNewCalendarSetupFlowEnabled(isNewCalendarSetupFlowEnabled)
    setIsOrganicBookingEnabled(isOrganicBookingEnabled)
    setTokenId(tokenId)
    setUser({
      id: userId,
      type: userType,
      role: userRole,
      version: 1,
    })
    setLocationId(locationId)
    setIsRecurringEnabled(recurringEventsEnabled)
    setIsAddGuestsEnabled(isAddGuestsEnabled)
    setIsEnabledNeoGroupWidget(isEnabledNeoGroupWidget)
    setIsICloudIntegrationEnabled(isICloudIntegrationEnabled)
    setIsTroubleshootToolEnabled(isTroubleshootToolEnabled)
    setIsOrganicBookingEnabled(isOrganicBookingEnabled)
    setIsCustomizeWidgetConfigEnabled(isCustomizeWidgetConfigEnabled)
    setIsDateOverrideEnabled(isDateOverrideEnabled)
    setIsTimezoneConfigEnabled(isTimezoneConfigEnabled)
    setIsAppointmentNotesEnabled(isAppointmentNotesEnabled)
    setIsNewCalIntegrationEnabled(isNewCalIntegrationEnabled)
    try {
      initPendo()
    } catch (error) {
      console.error('Calendar app: Pendo not initialized', error)
    }

    parent.emit('get-loggedin-user')
    parent.emit('get-location')

    if (source === 'calendar-setup') {
      fetchRequiredData(false)
      router.push({ name: 'calendar-settings__default' })
    } else if (source === 'appointments_page') {
      router.push({
        name: 'appointments-list',
        params: { locationId: locationId },
      })
    } else {
      router.push({ name: 'select-contact' })
    }
  })
}

const locale = computed(() => {
  return AppState?.locale?.replace('_', '')
})

onMounted(() => {
  openParentConnection()
})
</script>

<template>
  <div class="bg-gray-25">
    <UIContentWrap :full-screen="true" :locale="locale">
      <router-view></router-view>
    </UIContentWrap>
  </div>
</template>

<style></style>
