interface Config {
  mode: string
  restApiUrl: string
  calendarApiUrl: string
  baseUrl: string
  reserveServiceBaseUrl: string
}

const config: { [key: string]: Config } = {
  staging: {
    mode: 'staging',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    calendarApiUrl: 'https://staging.backend.leadconnectorhq.com',
    baseUrl: 'https://staging.backend.leadconnectorhq.com/appengine',
    reserveServiceBaseUrl:
      'https://staging.backend.leadconnectorhq.com/reserve',
  },
  production: {
    mode: 'production',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    calendarApiUrl: 'https://backend.leadconnectorhq.com',
    baseUrl: 'https://backend.leadconnectorhq.com/appengine',
    reserveServiceBaseUrl: 'https://backend.leadconnectorhq.com/reserve',
  },
}

const env = process.env.NODE_ENV === 'production' ? 'production' : 'staging'

const envConfig = config[env]

export default envConfig
