{
  "settings": {
    "calendar": {
      "calendar_settings": "Paramètres du calendrier",
      "group": "Groupe",
      "manage_your_calendars_and_groups": "Gérer vos calendriers et vos groupes",
      "groups": "Groupes",
      "create_group": "Créer un groupe",
      "create_calendar": "Créer un calendrier",
      "calendar_name": "Nom du calendrier",
      "group_duration": "Durée du groupe",
      "calendar_type": "Type de calendrier",
      "action": "Action",
      "round_robin": "Round Robin",
      "event": "Événement",
      "group_name": "Nom du groupe",
      "calendars": "Calendrier",
      "date_updated": "Date de mise à jour",
      "status": "Statut",
      "action_dropdown": "Lise déroulante",
      "edit": "Editer",
      "duplicate": "Dupliquer",
      "copy_embed_code": "Copier le code d'intégration",
      "copy_permanent_link": "Copier le lien permanent",
      "copy_new_slug_link": "Copier le lien de planification",
      "copy_scheduling_link": "Copier le lien de planification (Anciennement obsolète)",
      "deactivate_calendar": "Désactiver le calendrier",
      "move_to_group": "Deplacer vers le groupe",
      "delete_calendar": "Supprimer le calendrier",
      "delete_group": "Supprimer",
      "delete_this_group": "Supprimer ce groupe",
      "activate_calendar": "Activer le calendrier",
      "duration": "Durée (minutes)",
      "all": "Tout",
      "draft": "Brouillon",
      "active": "Actif",
      "rearrange_calendars": "Réorganiser les calendriers",
      "deactivate_all_calendars_in_group": "Désactiver tous les calendriers du groupe",
      "activate_all_calendars_in_group": "Activer tous les calendriers du groupe",
      "no_groups_found": "Votre recherche ne correspond à aucun groupe de calendrier",
      "no_groups_in_location": "Commencez à utiliser le groupe en créant un nouveau groupe de calendrier",
      "no_calendars_found": "Commencez à utiliser le calendrier en créant un nouveau calendrier",
      "no_calendars_found_search": "Votre recherche ne correspond à aucun calendrier",
      "delete_calendar_message": "Vous êtes sur le point de supprimer le Calendrier. Lorsque vous cliquez sur \"supprimer\", tous les rendez-vous seront également supprimés",
      "delete_calendar_message_title": "Supprimer ce calendrier ?",
      "no_calendars_found_title": "Aucun calendrier trouvé",
      "no_groups_in_location_title": "Aucun groupe trouvé",
      "delete_group_confirmation_title": "Supprimer ce groupe ?",
      "delete_group_confirmation": "Êtes-vous sûr de vouloir supprimer le groupe sélectionné ?",
      "delete_group_confirmation_checkbox_title": "Supprimer les calendriers et rendez-vous associés",
      "delete_group_confirmation_checkbox_description": "Sélectionner cette option supprimera également les calendriers de ce groupe, ainsi que tous les rendez-vous dans ces calendriers",
      "group_status_message_title": "{status} ce groupe ?",
      "calendar_status_message_title": "{status} de cet agenda ?",
      "calendar_status_message": "Vous êtes sur le point de {status} Groupe",
      "group_status_message": "Vous êtes sur le point de {status} Groupe",
      "cancel": "Annuler",
      "new_service_menu": "Nouveau menu de service",
      "service_menus": "Menu de services",
      "manage_your_calendars_groups_and_service_menus": "Gérez vos calendriers, groupes et menu de services",
      "deactivate_service_menu": "Désactiver le menu de service",
      "delete_service_menu": "Supprimer ce menu de service",
      "delete_service_menu_confirmation": "Êtes-vous sûr(e) de vouloir supprimer ce menu de service ?",
      "service_menu_status_message_title": "{status} ce menu de service ?",
      "service_menu_status_message": "Vous êtes sur le point d'accéder au menu de service {status}",
      "no_service_menus_in_location_title": "Créez votre menu de services personnalisé",
      "add_new_service_menu": "Ajouter un nouveau menu de services",
      "no_service_menus_in_location": "Commencez par créer un nouveau menu de services et ajoutez-y des groupes",
      "no_service_menu_found": "Menu de service non trouvé",
      "no_service_menu_found_desc": "Votre recherche ne correspond à aucun menu de service",
      "rooms": "Salles",
      "equipments": "Équipements",
      "round_robin_booking": "Tournoi en mêlée",
      "round_robin_description": "Répartit les rendez-vous entre les membres de l'équipe dans un ordre rotatif",
      "round_robin_example": "Par exemple : appels commerciaux, sessions de formation",
      "unassigned_booking": "Calendrier d'événements",
      "unassigned_description": "Pour planifier des événements physiques sans association hôte",
      "unassigned_example": "Par exemple : Conférences, séminaires publics, salons",
      "class_booking": "Réservation de classe",
      "class_booking_description": "Un hôte rencontre plusieurs participants",
      "class_booking_example": "Par exemple : Webinaires, formations en groupe, cours en ligne",
      "collective_booking": "Réservation collective",
      "collective_booking_description": "Plusieurs hôtes rencontrent un participant",
      "collective_booking_example": "Par exemple : entretiens en groupe, revues par un comité",
      "service_booking": "Réservation de service",
      "service_booking_description": "Planification sans faille pour les entreprises de services",
      "service_booking_example": "Par exemple : rendez-vous au spa, services de réparation, consultations",
      "personal_booking": "Réservation personnelle",
      "personal_booking_description": "Planifiez des réunions individuelles avec un membre spécifique de l'équipe",
      "personal_booking_example": "Par exemple : réunions avec les clients, consultations privées",
      "personal": "Personnel",
      "explore_more_types": "Découvrez plus de types",
      "scheduling_type": "Type de planification",
      "scheduling_type_description": "Choisissez un type de planification pour votre nouveau calendrier"
    },
    "preferences": {
      "preferences": "Préférences",
      "my_preferences": "Ma préférence",
      "account_preferences": "Préférence de compte",
      "user_preferences": "Préférences de l'utilisateur",
      "set_preferences": "Définissez les préférences de votre compte",
      "scheduling_options": "Options de planification",
      "meeting_location": "Lieu de la réunion",
      "zoom": "Zoom",
      "save_preferences": "Sauvegarder les préférences",
      "in_app_preferences": "Préférences dans l'application",
      "set_start_day": "Définir les préférences, comme le jour de lancement de l'application",
      "view_options": "Voir les options",
      "week_starts_on": "La semaine commence le",
      "sunday": "Dimanche",
      "widget_preferences": "Préférences des widgets",
      "set_widget_language": "Définir des préférences telles que la langue du widget",
      "language_and_region": "Langue et région",
      "language": "Langue",
      "english": "Anglais",
      "time_format": "Format de l'heure",
      "time_format_value": "Sat Dec 30 1899 13:30:00 GMT+0521 (India Standard Time)",
      "monday": "Lundi",
      "custom_meeting_location": "Lieu de réunion personnalisé"
    },
    "availability": {
      "availability": "Disponibilité",
      "my_availability": "Mes disponibilités",
      "account_availability": "Disponibilité du compte",
      "timezone": "Fuseau horaire",
      "working_hours": "Horaires de travail",
      "set_recurring_hours": "Fixez vos horaires de travail récurrents pour les réunions",
      "sunday": "Dimanche",
      "monday": "Lundi",
      "tuesday": "Mardi",
      "wednesday": "Mercredi",
      "thursday": "Jeudi",
      "friday": "Vendredi",
      "saturday": "Samedi",
      "widget_preferences": "Préférences des widgets",
      "set_widget_language": "Définir des préférences telles que la langue du widget",
      "language_and_region": "Langue et région",
      "language": "Langue",
      "english": "Anglais"
    },
    "connections": {
      "connections": "Connexions",
      "conferencing": "Conférence",
      "my_connections": "Mes connexions",
      "account_connections": "Connexions du compte",
      "connection_preferences": "Préférences de connexion",
      "manage_calendars_conferencing_channels": "Gérer les agendas et les canaux de conférence",
      "main_integration_calendar": "Agenda de l'intégration principale",
      "event_integration_calendar": "Les événements créés sur un agenda système dont vous faites partie seront également créés sur cet agenda intégré",
      "2way_sync": "Synchronisation bidirectionnelle : Créez des événements système à partir d'événements réservés sur l'agenda principal",
      "calendars": "Agendas",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Vérifier l'existence de conflits",
      "choose_accounts_to_connect": "Choisissez les comptes que vous souhaitez connecter",
      "payments": "Paiements",
      "stripe": "Stripe",
      "ads": "Publicité",
      "local_service_ads": "Annonces de service local",
      "fire_workflow": "Déclenchement du flux de travail pour les événements réservés via la synchronisation bidirectionnelle",
      "connect_google_outlook": "Veuillez connecter votre compte Google ou Outlook pour pouvoir choisir un agenda principal",
      "execute_automation": "Exécuter l'automatisation",
      "save": "Sauvegarder",
      "lsa": "Annonces de service local"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Sélectionner un contact",
    "start_time": "Heure de début",
    "end_time": "L'heure de fin",
    "search_by_name_email_phone_or_company": "Recherche par nom, courriel, téléphone ou entreprise",
    "add_new": "Ajouter un nouveau",
    "pick_from_available_contacts": "Ou choisir parmi les contacts disponibles",
    "book_appointment": "Prendre rendez-vous",
    "calendar": "Agenda",
    "repeat": "Répéter",
    "date_and_time": "Date et heure",
    "close": "Fermer",
    "team_member": "Membre d'équipe",
    "recurring_booking": "Réservation récurrente",
    "one_slot_booking": "Réservation d'un créneau horaire",
    "event_type": "Type d'événement",
    "occurrences_booked": "les occurrences seront réservées",
    "repeats": "Répétitions",
    "booking_details": "Détails de réservation",
    "showing_slots_in_this_timezone": "Afficher les créneaux horaires dans ce fuseau horaire :",
    "update_contact_tz": "Mettre à jour le fuseau horaire du contact également",
    "date": "Date",
    "slot": "Créneau",
    "calendar_default": "Agenda par défaut",
    "custom": "Personnalisé",
    "meeting_notes": "(ex) Notes de réunion pour notre prochain rendez-vous",
    "out_of_office": "(ex) Absent du bureau pour la semaine !",
    "standard": "Par défaut",
    "appointment_title": "Titre du rendez-vous",
    "event_title": "Titre de l'événement",
    "appt_with_bob": "(ex) Rendez-vous avec Bob",
    "vacation": "(ex) Vacances",
    "show_notes": "Afficher les notes",
    "hide_notes": "Cacher les notes",
    "additional_preferences": "Préférences supplémentaires",
    "meeting_location": "Lieu de la réunion",
    "appointment_status": "Statut du rendez-vous",
    "appt_description": "Description du rendez-vous",
    "recurring_details": "Détails récurrents",
    "event_description": "Description de l'événement",
    "in_contact_timezone": "Dans le fuseau horaire du contact",
    "vacation_message": "Vous partez en vacances ? Vous prenez des congés ? Bloquez votre agenda pour empêcher les clients de prendre des rendez-vous (les rendez-vous existants resteront sur votre agenda)",
    "contact": "Contact",
    "delete": "Supprimer",
    "cancel": "Annuler",
    "back": "Retour",
    "pick_available_contacts": "Ou choisir parmi les contacts disponibles",
    "no_contacts_found": "Aucun contact trouvé",
    "found": "trouvé",
    "select_one": "Sélectionnez une option",
    "tz_tooltip": "Cela n'a d'importance que si vous utilisez des valeurs personnalisées, telles que appointment_start_time appointment_end_time appointment_timezone etc.. dans vos communications sortantes avec vos clients. Il s'agit simplement de les convertir à l'heure locale",
    "meeting_location_tooltip": "Le lieu de la réunion sera celui configuré dans votre agenda",
    "appt_meeting_location_tooltip": "L'utilisateur peut définir le lieu de la réunion en fonction du rendez-vous",
    "gmeet_tooltip": "Veuillez vous assurer que le membre de l'équipe concerné dispose de Google Agenda en tant qu'agenda principal",
    "zoom_tooltip": "Veuillez vous assurer que le membre de l'équipe concerné a bien intégré Zoom",
    "enter_meeting_location": "Saisissez le lieu de la réunion",
    "save_appointment": "Sauvegarder le rendez-vous",
    "save_event": "Sauvegarder l'événement",
    "search_contacts": "Recherche par nom, courriel, téléphone ou entreprise",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Agenda",
    "appointment_owner": "Titulaire du rendez-vous",
    "source": "Source",
    "booked_by": "Réservé par",
    "view_activity_log": "Voir le journal d'activité",
    "status": "Statut",
    "cancel_appointment": "Annuler le rendez-vous",
    "google_event": "Événement Google",
    "outlook_event": "Événement Outlook",
    "blocked_slot": "Créneau bloqué",
    "appointment": "Rendez-vous",
    "confirmed": "Confirmé",
    "unconfirmed": "Non confirmé",
    "cancelled": "Annulé",
    "showed": "S'est pas présenté",
    "no_show": "Ne s'est pas présenté",
    "invalid": "Non valide"
  },
  "appointment_list": {
    "group_all": "TOUT",
    "group_primary": "PRIMAIRE",
    "group_user": "UTILISATEURS",
    "group_team": "GROUPES",
    "group_calendar": "CALENDRIERS",
    "all_appointments": "Tous les rendez-vous",
    "my_calendar": "Mon calendrier",
    "status_all": "Tout",
    "status_confirmed": "Confirmé",
    "status_new": "Nouveau (Action requise)",
    "status_show": "Montré",
    "status_no_show": "Non-présentation",
    "status_cancelled": "Annulé",
    "status_invalid": "Invalide",
    "filter_date_added_desc": "Date d'ajout (descendant)",
    "filter_start_added_desc": "Date de début (Desc)",
    "filter_start_added_asc": "Date de début croissante à partir d'aujourd'hui",
    "button_new_appointment": "Nouvelle nomination",
    "name": "Nom",
    "title": "Titre",
    "requested_time": "Temps demandé",
    "date_added": "Date d'ajout",
    "calendar": "Calendrier",
    "appointment_owner": "Le propriétaire de rendez-vous",
    "notes": "Notes",
    "status": "Statut",
    "action_view_detail": "Voir les détails",
    "action_view_notes": "Afficher les notes",
    "action_edit": "Modifier",
    "action_delete": "Supprimer",
    "action_reschedule": "Replanifier",
    "page_previous": "Précédent",
    "page_next": "Suivant",
    "appointment_notes": "Notes de rendez-vous",
    "close": "fermer",
    "confirm_delete_header": "Supprimer le rendez-vous",
    "confirm_delete_message": "Êtes-vous sûr(e) de vouloir supprimer ce rendez-vous ?",
    "confirm_delete_button": "Supprimer",
    "confirm_update_header": "Mise à jour du statut de rendez-vous",
    "confirm_update_confirmed_message": "Êtes-vous sûr(e) de vouloir marquer ce rendez-vous comme confirmé ?",
    "confirm_update_invalid_message": "Êtes-vous sûr(e) de vouloir marquer ce rendez-vous comme invalide ?",
    "confirm_update_showed_message": "Êtes-vous sûr de vouloir marquer ce rendez-vous comme effectué ?",
    "confirm_update_noshow_message": "Êtes-vous sûr(e) de vouloir marquer ce rendez-vous comme absent ?",
    "confirm_update_cancelled_message": "Êtes-vous sûr(e) de vouloir marquer ce rendez-vous comme annulé ?",
    "confirm_update_button": "Mise à jour",
    "disable_menu_warning": "Actuellement, vous n'avez pas l'autorisation de voir ou de modifier les détails de rendez-vous, car le contact associé ne vous est pas attribué. Veuillez contacter votre administrateur pour obtenir de l'aide supplémentaire",
    "no_contact_warning": "Vous ne pouvez pas accéder aux informations de contact pour le moment. Cela pourrait être dû au fait que le contact ne vous est pas assigné. Veuillez contacter votre administrateur pour obtenir de l'aide",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Reprogrammé"
  },
  "create_calendar": {
    "calendar_name": "Nom du calendrier",
    "calendar_logo": "Logo du calendrier",
    "select_team_member": "Sélectionner les membres de l'équipe",
    "eg_outbound_reach": "(Eg) Approche sortante",
    "description": "Description",
    "amount": "le montant",
    "description_placeholder": "Écrire une description",
    "meeting_duration": "Durée de la réunion",
    "seats_per_class": "Nombre de places par classe",
    "group": "groupe",
    "custom_url": "URL personnalisée",
    "meeting_invite_title": "Titre d'invitation à la réunion",
    "event_color": "La couleur de l'événement",
    "availability_type": "Type de disponibilité",
    "deposit_amount": "Montant du dépôt",
    "collect_deposit_amount": "Acceptez un paiement partiel",
    "percentage": "Pourcentage",
    "total_amount": "Montant total",
    "reschedule_preference": "Préférence de report",
    "reschedule_preference_subtext": "Si un contact reporte un rendez-vous :",
    "reassign_through_round_robin": "Réaffecter en utilisant le round robin",
    "keep_same_assinged_user": "Conservez le même rendez-vous propriétaire",
    "new_appointment_preference": "Nouvelle préférence de rendez-vous",
    "new_appointment_preference_text": "Toujours réserver avec l'utilisateur attribué au contact",
    "new_appointment_preference_subtext": "Prenez de nouveaux rendez-vous avec l'utilisateur attribué au contact plutôt que d'utiliser une planification par tour de rôle"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Astuce rapide",
      "timezone_change_tip": "Les fuseaux horaires peuvent être modifiés à tout moment dans les préférences des fuseaux horaires",
      "save": "sauver",
      "close": "Fermer",
      "meeting_details": "Détails de la réunion",
      "availability": "Disponibilité",
      "forms_and_payment": "Formulaires et paiement",
      "notifications_and_additional_options": "Notifications & Options supplémentaires",
      "connections": "Connections - Connexions",
      "customizations": "Personnalisation or Adaptations",
      "create": "Créer",
      "edit": "Modifier"
    },
    "meeting_details": {
      "meeting_location": "Lieu de rendez-vous",
      "enter_meeting_location": "Entrer le lieu de la réunion",
      "click_to_upload": "Cliquez pour télécharger",
      "square": "Carré",
      "circle": "Cercle",
      "remove_logo": "Supprimer le logo",
      "meeting_invite_title_tooltip": "Il s'agit du titre de l'événement du calendrier qui s'affiche dans Google Calendar, Outlook, Apple Calendar, etc",
      "event_color_tooltip": "Choisissez une couleur pour cet événement de réservation. Les couleurs se synchronisent avec les événements dans Google Agenda",
      "enter_a_name": "Veuillez entrer un nom",
      "enter_an_event_title": "Veuillez entrer un titre d'événement",
      "high_priority": "Haute priorité",
      "medium_priority": "Moyenne priorité",
      "low_priority": "Basse priorité",
      "custom": "Personnaliser",
      "zoom_not_added": "L'utilisateur n'a pas encore connecté son compte Zoom",
      "opt_avail": "Optimisez pour la disponibilité",
      "opt_equal": "Optimiser pour une répartition équitable",
      "team_members": "Sélectionnez les membres de l'équipe",
      "team_member": "Membre de l'équipe sélectionné",
      "meeting_location_required": "L'emplacement de la réunion est requis",
      "team_member_disabled_recurring_tooltip": "Les réunions récurrentes ne permettent qu'à un seul membre de l'équipe de participer. Pour ajouter plusieurs membres de l'équipe, veuillez désactiver les réunions récurrentes.",
      "team_member_disabled_class_booking_tooltip": "Seul un membre de l'équipe peut être ajouté au calendrier de réservation de la classe",
      "enable_contact_assigned_to_setting": "Pour activer ce paramètre, le formulaire doit être le premier dans l'ordre des widgets. Veuillez réorganiser l'ordre des widgets dans l'onglet Formulaires & Paiement",
      "reschedule_preference_setting_tooltip": "Sélectionnez à qui attribuer le rendez-vous en cas de report par un contact depuis le widget de réservation",
      "appointment_preference_tooltip": "Choisissez si les nouveaux rendez-vous doivent systématiquement être planifiés avec l'utilisateur assigné au contact. Si un contact a un utilisateur assigné, cet utilisateur aura la priorité ; sinon, il sera attribué en rotation. Conseil : Désactivez l'option 'Autoriser la sélection du personnel' pour empêcher les réservants de changer le membre du personnel sur le widget de réservation",
      "add_location": "Ajouter l'emplacement",
      "multiple_locations_neo": "Les multiples lieux de réunion ne sont pris en charge que par le Widget Neo avec le Formulaire par défaut",
      "make_owner": "Rendre propriétaire",
      "only_one_team_member": "Un seul membre de l'équipe est autorisé lorsque plusieurs emplacements sont ajoutés",
      "only_one_team_member_personal_calendar": "Seul un membre de l'équipe est autorisé pour les réservations personnelles"
    },
    "availability": {
      "my_availability": "Ma disponibilité",
      "set_availability": "Définissez votre disponibilité pour le calendrier ici",
      "standard": "Norme",
      "custom": "Personnalisé",
      "to": "à",
      "copy_to_all": "Tout copier",
      "add_time": "Ajouter du temps",
      "recurring_meeting": "Réunion récurrente",
      "recurring_info": "Un seul utilisateur par calendrier pour une réservation récurrente",
      "repeat": "répéter",
      "times_to_repeat": "Moments à répéter",
      "slots_unavailable": "Si les emplacements ne sont pas disponibles ?",
      "overlap_status": "Statut de rendez-vous avec des créneaux horaires qui se chevauchent",
      "meeting_interval": "Intervalle de réunion",
      "meeting_duration": "Durée de la réunion",
      "minimum_scheduling": "Avis de planification minimale",
      "date_range": "Plage de dates",
      "maximum_bookings_per_day": "Réservations maximales par jour",
      "maximum_bookings_per_slot": "Nombre maximal de réservations par créneau",
      "maximum_bookings_per_slot_per_user": "Nombre maximum de réservations par créneau (par utilisateur)",
      "seats_per_class": "Nombre de places par classe",
      "pre_buffer_time": "Temps de pré-tampon",
      "post_buffer_time": "temps tampon après la poste",
      "pre_buffer_tooltip": "Le pré-buffer est le temps supplémentaire pouvant être ajouté avant un rendez-vous, permettant d'avoir un peu plus de temps pour se préparer avant toute réunion",
      "post_buffer_tooltip": "Le Post-Buffer est du temps supplémentaire qui peut être ajouté après un rendez-vous, permettant d'avoir plus de temps pour terminer",
      "buffer_time": "temps tampon",
      "daily": "quotidien",
      "weekly": "hebdomadaire",
      "monthly": "Mensuel",
      "skip_booking": "Ignorer la réservation des créneaux indisponibles",
      "continue_booking": "Poursuivez la réservation",
      "book_next_available": "Réservez le prochain créneau disponible",
      "confirmed": "confirmé",
      "unconfirmed": "Non confirmé",
      "enter_valid_hours": "Veuillez entrer des horaires d'ouverture valides",
      "enter_valid_avail": "S'il vous plaît, veuillez entrer une disponibilité personnalisée valide",
      "availability_type_tooltip": "Choisissez Disponibilité Standard pour les réservations régulières offertes chaque semaine. Choisissez Sur-Mesure pour les réservations uniquement disponibles à des dates spécifiques",
      "meeting_interval_tooltip": "L'intervalle de réunion reflète la durée entre les créneaux de réservation qui seront affichés dans le calendrier. Pour un événement de 30 minutes qui doit être disponible en début de chaque heure, l'intervalle serait d'une heure. Pour un événement de 30 minutes qui doit être disponible toutes les 30 minutes, l'intervalle serait de 30 minutes",
      "date_range_tooltip": "Les événements peuvent être planifiés pour les prochains n jours",
      "maximum_bookings_per_day_tooltip": "Nombre maximum de rendez-vous autorisés par jour sur ce calendrier",
      "maximum_bookings_per_slot_tooltip": "Nombre maximum de rendez-vous autorisés par créneau horaire pour l'utilisateur (Si le nombre de rendez-vous dépasse le créneau donné pour l'utilisateur, cela passe soit à l'utilisateur suivant dans la file d'attente en file d'attente en rotation, soit marque le créneau comme indisponible pour le prospect de réserver)",
      "add_days": "Ajouter des jours",
      "month_on_day": "Mensuel le jour",
      "month_on_last_day": "Mensuel le dernier jour",
      "day": "Jour",
      "week": "La semaine",
      "month": "mois",
      "mins": "Minutes",
      "hours": "Heures",
      "days": "Les jours",
      "weeks": "semaines",
      "months": "Les mois",
      "meeting_interval_error": "L'intervalle entre les réunions doit être compris entre 5 minutes et 12 heures",
      "meeting_duration_error": "La durée de la réunion doit être comprise entre 1 minute et 12 heures",
      "buffer_time_error": "Le temps tampon doit être inférieur à 12 heures",
      "subTitle": "Choisissez la date pour fixer des heures spécifiques",
      "heading_1": "Nombre d'heures disponibles par semaine",
      "tooltip_1": "Définissez votre emploi du temps de disponibilité hebdomadaire. Ce sont vos heures standard qui seront appliquées de manière cohérente chaque semaine",
      "heading_2": "Quand êtes-vous disponible ?",
      "unavailable_label": "Indisponible",
      "tooltip_2": "Ajoutez des heures spécifiques à votre emploi du temps pour des dates précises. Modifiez vos heures régulières de la semaine en indiquant votre disponibilité ou indisponibilité pour certaines dates sélectionnées",
      "date_specific_level": "Ajouter des heures spécifiques à une date",
      "specific_hours_button": "Heures spécifiques de rendez-vous",
      "overlap_error": "Les plages horaires ne peuvent pas se chevaucher",
      "apply": "Appliquer",
      "copy_times_to": "Copiez les horaires vers",
      "no_date_specific_heading": "Aucune date ni heure spécifique ajoutée",
      "no_date_specific_description": "Vous pouvez ajouter/supprimer une date et une heure spécifique à votre disponibilité",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member, payment is turned off, and no date-specific hours are added.",
      "date_specific_hours_disabled_conditions_tooltip": "Pour inclure une heure spécifique à une date précise, veuillez vous assurer que les réunions récurrentes sont désactivées",
      "recurring_disable_event_calendar_conditions": "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous que le paiement est désactivé et qu'aucune heure spécifique n'est ajoutée",
      "service_interval": "Intervalle de service",
      "service_interval_tooltip": "L'intervalle de réunion détermine la fréquence à laquelle les créneaux de réservation apparaissent dans le calendrier. Par exemple, pour un événement de 30 minutes, un intervalle d'une heure signifie que les créneaux apparaissent toutes les heures. Un intervalle de 30 minutes signifie que les créneaux apparaissent toutes les 30 minutes",
      "service_duration_tooltip": "Cela fixe la durée de votre rendez-vous, déterminant combien de temps il durera",
      "minimum_scheduling_tooltip": "Cela définit le temps minimum requis pour réserver un rendez-vous via le calendrier. Par exemple, si cela est fixé à 4 heures, les rendez-vous ne peuvent pas être pris moins de 4 heures avant l'heure de début ; les réservations doivent être faites au moins 4 heures à l'avance",
      "service_duration": "Service duration",
      "look_busy_title": "Fais semblant d'être occupé",
      "look_busy_percentage_description": "Cacher le nombre de places disponibles de x%",
      "look_busy_helper_text": "Masquer un pourcentage de vos créneaux horaires disponibles sur le widget de réservation pour avoir l'air plus occupé et en forte demande",
      "maximum_bookings_per_slot_tooltip_personal": "Nombre maximum de rendez-vous autorisés par créneau pour l'utilisateur"
    },

    "forms_payment": {
      "forms": "Les formulaires",
      "forms_sub": "Définir les préférences du formulaire de planification des publications.",
      "select_form": "Choisir formulaire",
      "sticky_contacts": "Pré-remplir les champs (contacts enregistrés)",
      "confirmation_page": "Page de confirmation",
      "default": "Par défaut",
      "redirect_url": "URL de redirection",
      "thank_you": "Message de remerciements",
      "enter_url": "Saisir l'URL",
      "pixel_id": "Identifiant de pixel Facebook (facultatif)",
      "auto_confirm": "Auto-confirmation des nouveaux rendez-vous du calendrier",
      "configure_payment": "Configurer les options de paiement",
      "accept_payments": "Accepter les paiements",
      "test": "Test",
      "live": "Vivre",
      "enable_guests": "Ajouter des invités",
      "add_guest_tooltip": "L'option Ajouter un invité permettra à votre participant d'ajouter plusieurs participants au même rendez-vous",
      "add_guest_same_form_check": "Envoyez le même formulaire par courrier pour recueillir des informations à tous les invités",
      "payment_tooltip": "Le paiement ne serait pris en considération que pour le participant principal et non pour les invités",
      "default_form": "Défaut (Prénom, Nom, Email, Téléphone, Notes)",
      "select_form_tooltip": "Si vous souhaitez recueillir plus d'informations que le prénom, le nom de famille, l'e-mail et le téléphone standard des personnes qui réservent sur ce calendrier, créez un formulaire personnalisé dans Sites > Forms > Builder, puis sélectionnez le formulaire dans la liste déroulante ci-dessous",
      "confirmation_page_tooltip": "Une fois que quelqu'un fait une réservation, vous pouvez choisir de lui afficher un message personnalisé sur la même page (par défaut) ou de le rediriger vers une page de votre choix (redirection)",
      "add_guest_collect_email": "Avec nom et adresse e-mail",
      "add_guest_count_only": "Compter seulement",
      "custom_email_notification": "Envoyer le formulaire sur mesure aux invités",
      "deposit_amount_error": "Le montant du dépôt doit être inférieur au montant total",
      "deposit_percentage_error": "Le pourcentage de dépôt doit être inférieur à 100%",
      "partial_payment_tooltip": "Activer les paiements partiels pour collecter le montant de l'acompte des clients lors de la réservation du rendez-vous",
      "partial_payment_enable_error": "Le montant total saisi doit être supérieur à 0 pour permettre les paiements partiels",
      "payment_disable_text_integration": "Intégrez un fournisseur de paiement dans la section Paiements > Intégrations pour commencer à accepter les paiements",
      "payment_disable_text_recurring": "L'option de paiement n'est pas disponible pour les réunions récurrentes. Désactivez la récurrence pour activer les paiements",
      "date_and_time_selector": "Sélecteur de date et d'heure",
      "form_selector": "Formulaire",
      "order_step": "Étape",
      "widget_order_label": "Ordre des widgets",
      "widget_order_tool_tip": "Réorganisez l'ordre du 'Sélecteur de date et heure' et du 'Formulaire' sur le widget du calendrier. Il suffit de les faire glisser et de les réorganiser selon vos préférences",
      "same_user_assignment_tooltip": "Pour activer ce paramètre, le Formulaire doit être placé en premier sur le widget. Réorganisez le widget pour positionner le Formulaire en premier afin d'activer ce paramètre",
      "widget_re_order_tooltip": "Le réarrangement est uniquement disponible avec le style de widget Neo. Passez à Neo pour activer le réarrangement",
      "widget_re_order_disabled_tooltip": "Pour réorganiser l'ordre des widgets, veuillez désactiver 'Nouvelle préférence de rendez-vous (Toujours réserver avec l'utilisateur assigné du contact)' dans Détails de la réunion > Membres de l'équipe > Section Paramètres avancés",
      "enable_same_user_assignment": "Affectez les contacts à leurs membres d'équipe de calendrier respectifs chaque fois qu'un rendez-vous est réservé"
    },
    "notifications": {
      "notifications": "Notifications et Options supplémentaires",
      "notifications_sub": "Configurer les notifications et les options supplémentaires",
      "select_notification_type": "Sélectionnez le type de notification",
      "notification_person": "Qui devrait recevoir cette notification ?",
      "contact": "Contact",
      "assigned_user": "Utilisateur assigné",
      "emails": "Courriels",
      "alert_email_address": "Adresse email d'alerte",
      "ack_email": "Email d'accusé de réception",
      "send_google_invites": "Permettre à Google agenda d'envoyer des invitations ou des mises à jour par email aux participants",
      "send_google_outlook_invites": "Autoriser Google / Outlook Calendar à envoyer des invitations et des mises à jour par e-mail aux participants",
      "assign_user_to_contact": "Affecter les contacts à leurs membres d'équipe de calendrier respectifs chaque fois qu'un rendez-vous est réservé",
      "skip_assigning_user_to_contact": "Ne pas attribuer de contact si le contact a déjà un utilisateur assigné",
      "guest_label_count": "Nombre d'invités",
      "guest_count_error": "Le nombre d'invités devrait être compris entre 1 et 100",
      "allow_cancellation": "Autoriser l'annulation de la réunion",
      "allow_reschedule": "Permettre de reprogrammer la réunion",
      "allow_cancellation_tooltip": "Lorsque activé, un lien d'annulation sera ajouté à la section des notes additionnelles et inclus dans l'invitation au calendrier. Ce lien expirera comme configuré avant l'heure de début de la réunion, empêchant ainsi le réservateur d'annuler la réunion",
      "allow_reschedule_tooltip": "Lorsque activé, un lien de report sera ajouté à la section des notes supplémentaires et inclus dans l'invitation au calendrier. Ce lien expirera comme configuré avant l'heure de début de la réunion, empêchant ainsi le réservant de reporter la réunion",
      "cancellation_link_expiry": "Le lien d'annulation expirera",
      "reschedule_link_expiry": "Le lien de report sera expiré",
      "before_meeting": "avant la réunion",
      "cancellation_and_reschedule_policy": "Politique d'annulation et de réacheminement :"
    },
    "connections": {
      "connections_heading": "Synchronisation de calendrier externe",
      "connections_sub": "Synchroniser les événements avec un calendrier externe",
      "link_to_calendar": "Lien vers le calendrier",
      "sync_option": "option de synchronisation",
      "execute_automation": "Exécutez l'automatisation",
      "fire_workflow": "Déclencheurs de flux de travail de feu pour les événements réservés via une synchronisation bidirectionnelle",
      "reserve_with_google": "Réservez avec Google",
      "connect_lsa": "Connectez-vous à votre compte publicitaire des services locaux",
      "reserve_error": "Actuellement, la réservation par le biais de Google est prise en charge uniquement pour les \"services à domicile\", l'\"immobilier\" et les \"services juridiques\" (prestataires de services locaux) dans le secteur des entreprises aux États-Unis",
      "one_way": "Un sens unique",
      "two_way": "Deux sens",
      "smart": "Intelligent",
      "confirm_remove": "Êtes-vous sûr de vouloir supprimer le calendrier Google ?",
      "read_only_access": "Vous n'avez pas l'autorisation d'écriture sur ce calendrier. Veuillez sélectionner un autre calendrier",
      "reserve_with_google_desc": "Ce service de calendrier sera répertorié en réservation uniquement sur Google suite à une intégration réussie",
      "check_integration": "Veuillez vérifier votre statut d'intégration"
    },
    "extras": {
      "extras": "Personnalisations",
      "extras_sub": "Définir le style du widget et autres préférences",
      "calendar_widget_style": "Style de widget de calendrier",
      "neo": "Néo",
      "classic": "Classique",
      "insert_custom_code": "Insérer un code personnalisé",
      "show_seats_per_slot": "Montrer les places par créneau horaire",
      "allow_reschedule_meeting": "Autoriser à reprogrammer la réunion",
      "allow_cancelling_meeting": "Permettre d'annuler la réunion",
      "additional_notes": "Notes complémentaires",
      "cancellation_and_reschedule": "Annulation et report :",
      "allow_staff": "Permettre la sélection du personnel lors de la réservation",
      "code_block_tooltip": "Les scripts tiers à l'intérieur du bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils représentent également des risques pour la vie privée, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de vérifier le contenu de ce script avant de l'enregistrer dans le calendrier"
    }
  },
  "add_guest": {
    "guest_label": "Invité(s)",
    "search_placeholder": "Rechercher dans les contacts",
    "add_new": "Ajouter nouveau",
    "save": "sauver",
    "close": "Fermer",
    "name": "Nom",
    "phone": "Téléphone",
    "email": "courrier électronique"
  },
  "languages": {
    "english": "Anglais",
    "dutch": "Néerlandais",
    "french": "Français",
    "german": "Allemand",
    "hungarian": "Hongrois",
    "italian": "Italien",
    "polish": "Polonais",
    "portuguese_brazil": "Portugais (Brésil)",
    "portuguese_portugal": "Portugais (Portugal)",
    "spanish": "Espagnol",
    "danish": "Danois"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Détails du menu de service",
    "select_groups_header": "Services sélectionnés",
    "arrange_groups_header": "Organisez vos services",
    "new_service_menu": "Nouveau menu de service",
    "service_menu_sub_title": "Créez un menu de services personnalisé pour vos clients en seulement quelques clics",
    "quick_tip": "Vous pouvez ajouter des groupes dans le menu de service",
    "service_menu_name": "Nom du service au menu",
    "service_menu_description": "La description",
    "service_menu_slug": "URL du service",
    "select_groups_calendars": "Sélectionner des groupes et des calendriers",
    "select_groups_calendars_desc": "Ajoutez des services à votre menu de services",
    "select_all": "Tout sélectionner",
    "arrange_groups": "Organiser l'ordre des services",
    "arrange_groups_desc": "Déterminez la séquence des services sur le menu des services",
    "arrange_services_quicktip": "Pour réorganiser les calendriers au sein du groupe, allez dans Groupes > Options > Réorganiser les calendriers",
    "enable_add_guest_desc": "Permet aux clients d'inclure des invités supplémentaires lors de la réservation",
    "enable_multiple_service_desc": "Permet aux clients de réserver plusieurs services lors d'un même rendez-vous.",
    "enable_staff_selection_desc": "Permet aux clients de sélectionner les membres du personnel préférés lors de la réservation.",
    "enable_add_guest": "Autoriser l'ajout d'invités",
    "enable_multiple_service": "Autoriser la sélection de plusieurs services",
    "enable_staff_selection": "Activer la sélection du personnel",
    "additional_options": "Options supplémentaires",
    "code_block_tooltip": "Les scripts tiers à l'intérieur du bloc HTML peuvent offrir une fonctionnalité puissante, mais ils comportent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de revoir le contenu de ce script avant de l'enregistrer dans le menu de service.",
    "custom_code_placeholder": "Veuillez insérer le code personnalisé ici",
    "insert_custom_code": "Insérer du code personnalisé",
    "no_service": "Aucun service trouvé",
    "no_service_desc": "Aucun service trouvé. Créez des services et ajoutez-les à des groupes pour créer un menu de services",
    "learn_more": "En savoir plus",
    "no_service_selected": "Aucun service sélectionné",
    "create_service_calendars": "Créer des calendriers de service",
    "alert_description": "Pour créer un menu de services, vous devez créer des calendriers de services et les ajouter à des groupes",
    "dont_show_again": "Ne montrez plus cela",
    "service_name_required": "Le nom du menu de service est requis",
    "slug_already_taken": "Cette limace est déjà prise",
    "slug_required": "Le slug est requis",
    "please_enter_name": "Veuillez entrer un nom",
    "service_name_placeholder": "Menu principal",
    "consent_label": "Case à cocher de consentement",
    "consent_input_placeholder": "Ajouter le texte de consentement",
    "consent_tooltip": "Activez cette option pour permettre d'obtenir le consentement des contacts lorsqu'ils fournissent leur numéro de téléphone pendant le processus de réservation d'un rendez-vous"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personnalisez le widget calendrier",
    "works_with_neo_widget": "Fonctionne uniquement avec le widget Neo",
    "customise_widget_desc": "Personnaliser l'apparence du widget : couleur principale, couleur de fond et texte du bouton",
    "learn_more": "En savoir plus",
    "primary_settings": "Paramètres des bases",
    "primary_settings_desc": "Configurez la couleur principale, la couleur d'arrière-plan et autres préférences de widget pour personnaliser votre widget de calendrier",
    "primary_color": "La couleur primaire",
    "primary_color_desc": "Définir la couleur des boutons, des dates, des créneaux horaires et des actions",
    "background_color": "Couleur de fond",
    "background_color_desc": "Définissez la couleur de fond qui sert de toile de fond à la fois pour le sélecteur de date-heure et le formulaire",
    "button_text": "Texte du bouton",
    "button_text_desc": "Personnalisez le texte affiché sur le bouton lors de la réservation d'un rendez-vous",
    "calendar_title": "Titre du calendrier",
    "calendar_title_desc": "Le nom de votre calendrier",
    "calendar_description": "Description du calendrier",
    "calendar_description_desc": "La description donnée",
    "calendar_details": "Détails du calendrier",
    "calendar_details_desc": "Inclut des informations telles que la durée, la date et l'heure, les détails récurrents et le fuseau horaire",
    "reset_to_default": "Réinitialiser par défaut",
    "preview_widget": "Aperçu de widget"
  }
}
