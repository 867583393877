<!-- eslint-disable no-prototype-builtins -->
<script setup lang="ts">
import { formService } from '@/class/services/FormService'
import AppState, {
  setEditCalendarValidationData,
  setIsAdvancedCalendarPageLoading,
} from '@/states/app'
import {
  DotsVerticalIcon,
  Percent02Icon,
  Tag01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UICheckbox,
  UIDivider,
  UIDropdownTree,
  UIForm,
  UIFormItem,
  UIHeader,
  UIInput,
  UIInputGroup,
  UIInputGroupLabel,
  UIInputNumber,
  UIRadio,
  UISelect,
  UISwitch,
  UITabPane,
  UITabs,
} from '@gohighlevel/ghl-ui'
import { InformationCircleIcon } from '@heroicons/vue/outline'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { useI18n } from 'vue-i18n'
import {
  DepositType,
  GuestType,
  WidgetConfig,
  WidgetType,
} from '../../models/calendar'
import { currency } from '../../utils/currencyHelper'
import {
  handleCustomValueModification,
  thankyouMessageFieldOptions,
  thankyouMessageFieldRecurringOptions,
} from '../../utils/customValueMapper'
import { getTooltip } from '../../utils/generalFunctions'
const defaultPageOrder = [
  {
    position: 0,
    kind: 'calendar',
  },
  {
    position: 1,
    kind: 'form',
  },
]

const { t } = useI18n()

const state = reactive({
  isLoading: false,
  forms: [],
  shouldAcceptPayment: false,
  reorderedPageOption: [] as { position: number; kind: string }[] | undefined,
  shouldAcceptDepositAmount: false,
  isClassicWidget: false,
  formData: {
    stickyContact: false,
    autoConfirm: true,
    formId: '',
    pixelId: '',
    formSubmitType: 'ThankYouMessage',
    formSubmitRedirectURL: '',
    formSubmitThanksMessage: '',
    isLivePaymentMode: false,
    stripe: {
      amount: 0,
      currency: 'USD',
      chargeDescription: '',
      depositAmount: 0,
      depositPercentage: 0,
      depositType: DepositType.AMOUNT,
    },
    enableGuests: false,
    guestType: GuestType.COLLECT_DETAIL,
    shouldSendCustomForm: false,
    enableConsentCheck: false,
    consentLabel: '' as string | undefined,
    widgetConfig: undefined as WidgetConfig | undefined,
  },
  isFormFirstEnabled: false,
})
const listLabels = {
  calendar: t('calendar_advanced.forms_payment.date_and_time_selector'),
  form: t('calendar_advanced.forms_payment.form_selector'),
}
const stripeFormRef = ref()

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const isClassBookingCalendar = computed(() => {
  return editingCalendar.value?.isClassBookingCalendar
})

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})

const enableAddGuests = computed(() => {
  return !isClassBookingCalendar.value && !isServiceCalendar.value
})

const disableFormOrder = computed(() => {
  return (
    AppState.isClassicWidgetSelected ||
    Boolean(editingCalendar?.value?.enableSameUserAssignment)
  )
})

const paymentCurrencyOptions = computed(() => {
  const currencyOptions: string[] = []
  for (const key of Object.keys(currency)) {
    currencyOptions.push(currency[key].code)
  }
  return currencyOptions.map(x => {
    return {
      value: x,
      label: x,
    }
  })
})

const formOptions = computed(() => {
  const customFormOptions = state.forms.map(x => {
    return {
      value: x._id,
      label: x.name,
    }
  })

  return [
    {
      value: '',
      label: t('calendar_advanced.forms_payment.default_form'),
    },
    ...customFormOptions,
  ]
})

watch(
  () => formOptions.value,
  () => {
    if (formOptions.value.length === 0) {
      return
    }

    const formOption = formOptions.value.find(
      x => x.value === state.formData.formId
    )

    if (!formOption) {
      state.formData.formId = ''
    }
  }
)

watch(
  () => state.formData.enableGuests,
  () => {
    setEditCalendarValidationData('enableGuests', state.formData.enableGuests)
  },
  { deep: true }
)

watch(
  () => state.isFormFirstEnabled,
  () => {
    AppState.isFormFirstEnabled = state.isFormFirstEnabled
  }
)

const shouldAllowPayment = computed(() => {
  return (
    !AppState.editCalendarValidationData.enableRecurring &&
    (AppState.shouldAllowPayment ||
      (editingCalendar.value?.stripe &&
        Object(editingCalendar.value?.stripe).hasOwnProperty('amount')))
  )
})

const isPartialPaymentEnabled = computed(() => {
  return state.formData.stripe.amount > 0
})

const rules = computed(() => {
  return {
    stripeAmount: {
      trigger: ['input', 'blur'],
      validator() {
        if (!state.formData.stripe.amount) {
          return true
        }
        if (state.formData.stripe.amount.toString().match(/^\d+(\.\d{3,})$/)) {
          return new Error(t('calendar_advanced.forms_payment.stripe_error'))
        }
        return true
      },
    },
    depositAmount: {
      trigger: ['input', 'blur'],
      validator() {
        if (
          state.formData.stripe.depositAmount > state.formData.stripe.amount
        ) {
          return new Error(
            t('calendar_advanced.forms_payment.deposit_amount_error')
          )
        }
        if (
          state.formData.stripe.depositAmount
            .toString()
            .match(/^\d+(\.\d{3,})$/)
        ) {
          return new Error(t('calendar_advanced.forms_payment.stripe_error'))
        }
        return true
      },
    },
    depositPercentage: {
      trigger: ['input', 'blur'],
      validator() {
        if (state.formData.stripe.depositPercentage > 100) {
          return new Error(
            t('calendar_advanced.forms_payment.deposit_percentage_error')
          )
        }

        if (
          state.formData.stripe.depositPercentage
            .toString()
            .match(/^\d+(\.\d{3,})$/)
        ) {
          return new Error(t('calendar_advanced.forms_payment.stripe_error'))
        }
        return true
      },
    },
    chargeDescription: {
      trigger: ['input', 'blur'],
      validator() {
        if (!state.formData.stripe.chargeDescription) {
          return true
        }
        if (state.formData.stripe.chargeDescription.length > 1000) {
          return new Error(
            t('calendar_advanced.forms_payment.payment_description_error')
          )
        }
        return true
      },
    },
  }
})

const emit = defineEmits(['onSave', 'onCancel'])

const onClickTab = tab => {
  state.formData.stripe.depositType = tab
}

const thankyouMessageRef = ref()
const thankyouMessageRecurringRef = ref()

watchEffect(() => {
  const customValues = handleCustomValueModification(AppState.customValues)

  thankyouMessageRef.value = customValues
    ? [...thankyouMessageFieldOptions, customValues]
    : thankyouMessageFieldOptions
  thankyouMessageRecurringRef.value = customValues
    ? [...thankyouMessageFieldRecurringOptions, customValues]
    : thankyouMessageFieldRecurringOptions
})

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    state.shouldAcceptPayment = Boolean(
      editingCalendar.value?.stripe?.hasOwnProperty('amount')
    )
    state.shouldAcceptDepositAmount = Boolean(
      editingCalendar.value?.stripe?.hasOwnProperty('deposit')
    )
    state.formData = {
      stickyContact: Boolean(editingCalendar.value?.stickyContact),
      isLivePaymentMode: Boolean(editingCalendar.value?.isLivePaymentMode),
      autoConfirm: Boolean(editingCalendar.value?.autoConfirm),
      formId: editingCalendar.value?.formId || '',
      pixelId: editingCalendar.value?.pixelId || '',
      formSubmitType:
        editingCalendar.value?.formSubmitType || 'ThankYouMessage',
      formSubmitRedirectURL: editingCalendar.value?.formSubmitRedirectURL || '',
      formSubmitThanksMessage:
        editingCalendar.value?.formSubmitThanksMessage || '',
      stripe: {
        amount: String(editingCalendar.value?.stripe?.amount || 0) || '0',
        currency: editingCalendar.value?.stripe?.currency || 'USD',
        chargeDescription:
          editingCalendar.value?.stripe?.chargeDescription || '',
        depositAmount:
          (editingCalendar.value?.stripe?.depositType === DepositType.AMOUNT &&
            editingCalendar.value?.stripe?.deposit) ||
          0,
        depositPercentage:
          (editingCalendar.value?.stripe?.depositType ===
            DepositType.PERCENTAGE &&
            editingCalendar.value?.stripe?.deposit) ||
          0,
        depositType:
          editingCalendar.value?.stripe?.depositType || DepositType.AMOUNT,
      },
      enableGuests: Boolean(editingCalendar.value?.enableGuests),
      guestType: editingCalendar.value?.guestType || GuestType.COLLECT_DETAIL,
      shouldSendCustomForm: Boolean(
        editingCalendar.value?.shouldSendCustomForm
      ),
      enableConsentCheck: Boolean(editingCalendar.value?.enableConsentCheck),
      consentLabel: editingCalendar.value?.consentLabel,
      widgetConfig: editingCalendar.value?.widgetConfig || {},
    }
    if (state.formData.widgetConfig) {
      state.formData.widgetConfig.pageOrder =
        state.formData?.widgetConfig?.pageOrder || defaultPageOrder
    }
    state.isClassicWidget =
      WidgetType.CLASSIC === editingCalendar.value?.widgetType
    state.reorderedPageOption = state.formData.widgetConfig?.pageOrder
    const pageOrder = state.reorderedPageOption?.find(
      (x: any) => x.kind === 'form'
    )
    state.isFormFirstEnabled = pageOrder?.position === 0
    await Promise.all([methods.loadForms()])
    setIsAdvancedCalendarPageLoading(false)
  },
  async loadForms() {
    const batchSize = 50
    const {
      data: { forms, total },
    } = await formService.getAll(AppState.locationId, 0)

    const totalBatches = Math.ceil((total - batchSize) / batchSize)

    const fetchedForms = await Promise.all(
      Array.from({ length: totalBatches }, (_, i) =>
        formService.getAll(AppState.locationId, (i + 1) * batchSize)
      )
    )

    fetchedForms.forEach(({ data: { forms: nextForms } }) => {
      if (nextForms && nextForms.length > 0) {
        forms.push(...nextForms)
      }
    })

    state.forms = forms
  },
  async emitSave() {
    const payload = {
      ...state.formData,
    } as any

    if (
      state.shouldAcceptPayment &&
      !AppState.editCalendarValidationData.enableRecurring
    ) {
      try {
        await stripeFormRef.value.getForm().validate()
      } catch (error) {
        return
      }
      payload.stripe = {
        amount: state.formData.stripe.amount,
        currency: state.formData.stripe.currency,
        chargeDescription: state.formData.stripe.chargeDescription,
      }

      if (state.shouldAcceptDepositAmount) {
        payload.stripe = {
          ...payload.stripe,
          deposit:
            state.formData.stripe.depositType === DepositType.AMOUNT
              ? state.formData.stripe.depositAmount
              : state.formData.stripe.depositPercentage,
          depositType: state.formData.stripe.depositType,
        }
      }
    } else {
      payload.stripe = {}
    }
    if (payload?.stripe?.amount) {
      payload.stripe.amount = Number(payload.stripe.amount)
    }
    const reorderedPageOption = state.reorderedPageOption?.map(
      (item, index) => ({
        kind: item?.kind,
        position: index,
      })
    )
    if (reorderedPageOption?.length) {
      payload.widgetConfig.pageOrder = reorderedPageOption
    }

    return payload
  },
  handleOptionChange(e) {
    const pageOrder = state.reorderedPageOption?.at(0)
    state.isFormFirstEnabled = pageOrder?.kind === 'form'
  },
}

defineExpose({
  methods,
})

watch(
  () => state.shouldAcceptPayment,
  () => (AppState.shouldAcceptPayment = state.shouldAcceptPayment)
)

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

watch(
  () => state.formData.stripe.amount,
  newValue => {
    if (newValue <= 0) {
      state.shouldAcceptDepositAmount = false
      state.formData.stripe.deposit = 0
    }
  }
)
</script>

<template>
  <UIHeader id="calendars-forms-payments-tab" :secondary-title="$t('calendar_advanced.forms_payment.forms')"
    :description="$t('calendar_advanced.forms_payment.forms_sub')">
  </UIHeader>
  <UIDivider />

  <UIFormItem :label="$t('calendar_advanced.forms_payment.select_form')" path="formData.formId" :tooltip="getTooltip($t('calendar_advanced.forms_payment.select_form_tooltip'))
    ">
    <div class="mt-1 flex flex-col">
      <UISelect id="calendar-selectForm" v-model:value="state.formData.formId" class="w-96" :filterable="true"
        :options="formOptions" />
    </div>
  </UIFormItem>
  <UIFormItem :label="t('calendar_advanced.forms_payment.widget_order_label')" path="formData.formId" :tooltip="getTooltip(t('calendar_advanced.forms_payment.widget_order_tool_tip'))
    ">
    <div v-if="state.formData.widgetConfig?.pageOrder" class="flex w-full flex-row">
      <div class="w-[12%] px-4">
        <div v-for="(_, index) in state.formData.widgetConfig?.pageOrder" :key="'step-' + index"
          class="flex w-full cursor-move items-center space-x-5 py-4">
          <div class="w-full font-medium text-gray-700">
            {{
    `${t('calendar_advanced.forms_payment.order_step')} ${index + 1} `
  }}
          </div>
        </div>
      </div>
      <VueDraggableNext :list="state.reorderedPageOption" class="dragArea list-group w-[36%] rounded-md border"
        :disabled="disableFormOrder" @change="methods.handleOptionChange">
        <transition-group name=" flip-list">
          <div v-for="(pageOrder, index) in state.formData.widgetConfig?.pageOrder" :key="pageOrder?.kind + index"
            class="flex items-center space-x-5 border py-4 font-medium"
            :class="disableFormOrder ? 'cursor-no-drop' : 'cursor-move'">
            <UITooltip v-if="disableFormOrder" :title="editingCalendar?.enableSameUserAssignment
    ? t(
      'calendar_advanced.forms_payment.widget_re_order_disabled_tooltip'
    )
    : t('calendar_advanced.forms_payment.widget_re_order_tooltip')
    " placement="top">
              <div class="flex items-center space-x-5">
                <div class="pl-2">
                  <DotsVerticalIcon class="h-5 w-4 text-gray-500" />
                  <DotsVerticalIcon class="-ml-2 h-5 w-4 text-gray-500" />
                </div>
                <div class="capitalize text-gray-700">
                  {{ listLabels[pageOrder.kind] }}
                </div>
              </div>
            </UITooltip>
            <div v-else class="flex items-center space-x-5">
              <div class="pl-2">
                <DotsVerticalIcon class="h-5 w-4 text-gray-500" />
                <DotsVerticalIcon class="-ml-2 h-5 w-4 text-gray-500" />
              </div>
              <div class="capitalize text-gray-700">
                {{ listLabels[pageOrder.kind] }}
              </div>
            </div>
          </div>
        </transition-group>
      </VueDraggableNext>
    </div>
  </UIFormItem>
  <div v-if="state.formData.formId === ''" class="flex items-center space-x-3 pb-3">
    <div id="Sticky-Contacts-Toggle">
      <UISwitch v-model:value="state.formData.stickyContact" size="small">
      </UISwitch>
    </div>
    <div class="font-normal">
      {{ $t('calendar_advanced.forms_payment.sticky_contacts') }}
    </div>
  </div>
  <div v-if="state.formData.formId === ''" class="">
    <div class="flex space-x-3 pb-3">
      <div id="consent-form-toggle">
        <UISwitch v-model:value="state.formData.enableConsentCheck" size="small">
        </UISwitch>
      </div>
      <div class="font-normal">
        {{ $t('calendar_advanced.forms_payment.consent_label') }}
      </div>
      <UITooltip :title="$t('calendar_advanced.forms_payment.consent_tooltip')" placement="top">
        <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
      </UITooltip>
    </div>
    <UIInput v-if="state.formData.enableConsentCheck" id="consent-form-input"
      v-model:value="state.formData.consentLabel" type="textarea" :rows="4" class="mb-3 !w-96 items-center"
      :placeholder="$t('calendar_advanced.forms_payment.consent_input_placeholder')
    " />
  </div>

  <!-- v-if= enabled for companies or location -->
  <!-- <UIDivider /> -->

  <div v-if="enableAddGuests" class="flex items-center space-x-3 pb-3">
    <div id="enable-guest-toggle">
      <UISwitch v-model:value="state.formData.enableGuests" size="small">
      </UISwitch>
    </div>
    <div class="font-normal">
      {{ $t('calendar_advanced.forms_payment.enable_guests') }}
    </div>
    <UITooltip :title="$t('calendar_advanced.forms_payment.add_guest_tooltip')" placement="top">
      <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
    </UITooltip>
  </div>
  <div v-if="state.formData.enableGuests" class="ml-4 mt-2 flex">
    <UIRadio id="calendar-guest-type-collect-detail" class="p-1"
      :checked="state.formData.guestType === GuestType.COLLECT_DETAIL" name="custom"
      @change="state.formData.guestType = GuestType.COLLECT_DETAIL">
      {{ $t('calendar_advanced.forms_payment.add_guest_collect_email') }}
    </UIRadio>
    <UIRadio id="calendar-guest-type-count-only" class="p-1"
      :checked="state.formData.guestType === GuestType.COUNT_ONLY" name="default"
      @change="state.formData.guestType = GuestType.COUNT_ONLY">
      {{ $t('calendar_advanced.forms_payment.add_guest_count_only') }}
    </UIRadio>
  </div>
  <div v-if="state.formData.enableGuests &&
    state.formData.formId !== '' &&
    state.formData.guestType === GuestType.COLLECT_DETAIL
    " class="ml-5 mt-4">
    <UICheckbox id="calendar-guest-custom-form-notification" v-model:checked="state.formData.shouldSendCustomForm">
      {{ $t('calendar_advanced.forms_payment.custom_email_notification') }}
    </UICheckbox>
  </div>

  <UIDivider />

  <UIFormItem :label="$t('calendar_advanced.forms_payment.confirmation_page')" path="isDefaultConfirmationPage"
    :tooltip="getTooltip(
    $t('calendar_advanced.forms_payment.confirmation_page_tooltip')
  )
    ">
    <div class="flex w-full flex-col space-y-4">
      <div class="flex space-x-4">
        <UIRadio id="calendar-default-thankyou-message" :checked="state.formData.formSubmitType === 'ThankYouMessage'"
          name="default" @change="state.formData.formSubmitType = 'ThankYouMessage'">
          {{ $t('calendar_advanced.forms_payment.default') }}
        </UIRadio>

        <UIRadio id="calendar-redirect-url" :checked="state.formData.formSubmitType === 'RedirectURL'" name="custom"
          @change="state.formData.formSubmitType = 'RedirectURL'">
          {{ $t('calendar_advanced.forms_payment.redirect_url') }}
        </UIRadio>
      </div>

      <div v-if="state.formData.formSubmitType === 'ThankYouMessage'" class="w-96">
        <UIFormItem :label="$t('calendar_advanced.forms_payment.thank_you')" path="formData.formSubmitThanksMessage">
          <UIInput id="redirect-url-input" v-model:value="state.formData.formSubmitThanksMessage" type="textarea"
            :rows="4" class="relative">
            <template #suffix>
              <UIDropdownTree :options="AppState.editCalendarValidationData.enableRecurring ||
    AppState.isFormFirstEnabled
    ? thankyouMessageRecurringRef
    : thankyouMessageRef
    " :path-separator="'/'" :disable-search="false" :disabled="undefined" :trigger-label="undefined" :debug="undefined"
                :placement="undefined" :loading="undefined" :remote="undefined" :search-placeholder="''" @on-select="(value: string) => {
    state.formData.formSubmitThanksMessage =
      state.formData.formSubmitThanksMessage +
      ' ' +
      value
  }">
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template #trigger="triggerProps">
                  <UIButton id="dd" class="absolute bottom-0 right-4 cursor-pointer" quaternary>
                    <Tag01Icon class="h-5 w-5 lg:-mr-2" />
                  </UIButton>
                </template>
              </UIDropdownTree>
            </template>
          </UIInput>
        </UIFormItem>
      </div>

      <div v-if="state.formData.formSubmitType === 'RedirectURL'" class="w-96">
        <UIInput id="redirect-url-input" v-model:value="state.formData.formSubmitRedirectURL"
          :placeholder="$t('calendar_advanced.forms_payment.enter_url')" />
      </div>
    </div>
  </UIFormItem>

  <UIFormItem :label="$t('calendar_advanced.forms_payment.pixel_id')" path="formData.pixelId">
    <div class="w-96">
      <UIInput id="calendar-pixel-id" v-model="state.formData.pixelId" placeholder="Pixel ID" />
    </div>
  </UIFormItem>

  <UIDivider />

  <div class="flex items-center space-x-3 pb-3">
    <div id="calendar-autoConfirm-switch">
      <UISwitch v-model:value="state.formData.autoConfirm" size="small">
      </UISwitch>
    </div>
    <div class="font-normal">
      {{
    isServiceCalendar
      ? $t('calendar_advanced.forms_payment.auto_confirm_service')
      : $t('calendar_advanced.forms_payment.auto_confirm')
  }}
    </div>
  </div>

  <div v-if="shouldAllowPayment" class="">
    <UIDivider />

    <UIHeader id="text-only-header" secondary-title="Payment"
      :description="$t('calendar_advanced.forms_payment.configure_payment')">
    </UIHeader>

    <UIDivider />

    <div class="flex items-center space-x-3 pb-3">
      <div id="calendars-acceptPayments-switch">
        <UISwitch v-model:value="state.shouldAcceptPayment" size="small">
        </UISwitch>
      </div>

      <div class="font-normal">
        {{ $t('calendar_advanced.forms_payment.accept_payments') }}
      </div>
      <UITooltip v-if="!isServiceCalendar" :title="$t('calendar_advanced.forms_payment.payment_tooltip')"
        placement="top">
        <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
      </UITooltip>
    </div>

    <div v-if="state.shouldAcceptPayment" class="">
      <UIForm id="stripe-payment-form" ref="stripeFormRef" :model="state.formData" :rules="rules">
        <UIFormItem :label="$t('create_calendar.total_amount')" class="mt-4 w-full" path="stripeAmount">
          <UIInputGroup>
            <UIInputNumber id="calendars-stripe-amount" v-model="state.formData.stripe.amount" placeholder="" :min="0"
              :max="999999.99" :show-button="false" size="large" class="w-full">
            </UIInputNumber>
            <UISelect id="calendars-stripe-currency" v-model:value="state.formData.stripe.currency" class="w-32"
              :options="paymentCurrencyOptions">
            </UISelect>
          </UIInputGroup>
        </UIFormItem>

        <div v-if="state.shouldAcceptPayment" class="mb-5 mt-5">
          <div v-if="isPartialPaymentEnabled" class="mt-2 flex items-center space-x-1">
            <UICheckbox id="calendars-collect-deposit" v-model:checked="state.shouldAcceptDepositAmount"
              :disabled="undefined" type="default">
              {{ $t('create_calendar.collect_deposit_amount') }}
            </UICheckbox>
            <UITooltip :title="$t('calendar_advanced.forms_payment.partial_payment_tooltip')
    " placement="top">
              <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
            </UITooltip>
          </div>

          <UITooltip v-else placement="left" :title="$t('calendar_advanced.forms_payment.partial_payment_enable_error')
    ">
            <UICheckbox id="calendars-collect-deposit" v-model:checked="state.shouldAcceptDepositAmount"
              :disabled="true" type="default">
              {{ $t('create_calendar.collect_deposit_amount') }}
            </UICheckbox>
          </UITooltip>

          <div v-if="state.shouldAcceptDepositAmount && isPartialPaymentEnabled" class="relative mt-4 flex w-52">
            <UITabs v-model="state.formData.stripe.depositType" :default-value="state.formData.stripe.depositType"
              :type="'segment'" :animated="true" @update:value="val => onClickTab(val)">
              <UITabPane name="amount" :tab="$t('create_calendar.amount')">
                <div class="hidden">test1</div>
              </UITabPane>
              <UITabPane name="percentage" :tab="$t('create_calendar.percentage')">
                <div class="hidden">test2</div>
              </UITabPane>
            </UITabs>
          </div>

          <div v-if="state.shouldAcceptDepositAmount && isPartialPaymentEnabled" class="mt-2 flex w-80 flex-col">
            <UIFormItem :path="state.formData.stripe.depositType === DepositType.AMOUNT
    ? 'depositAmount'
    : 'depositPercentage'
    " :label="$t('create_calendar.deposit_amount')">
              <UIInputGroup v-if="state.formData.stripe.depositType === DepositType.AMOUNT">
                <UIInputNumber id="calendars-deposit-stripe-amount" v-model="state.formData.stripe.depositAmount"
                  placeholder="" :min="0" :max="999999.99" :show-button="false" size="large" class="w-full">
                </UIInputNumber>
                <UIInputGroupLabel class="w-20 text-center">{{
    state.formData.stripe.currency
  }}</UIInputGroupLabel>
              </UIInputGroup>

              <UIInputGroup v-else>
                <UIInputNumber id="calendars-deposit-stripe-amount" v-model="state.formData.stripe.depositPercentage"
                  placeholder="" :min="0" :max="999999.99" :show-button="false" size="large" class="w-full">
                </UIInputNumber>
                <UIInputGroupLabel class="w-20 text-center">
                  <Percent02Icon class="mb-1 h-5 w-5" />
                </UIInputGroupLabel>
              </UIInputGroup>
            </UIFormItem>
          </div>
        </div>

        <UIFormItem :label="$t('create_calendar.description')" path="chargeDescription">
          <UIInput id="calendar-stripe-currency-input" v-model="state.formData.stripe.chargeDescription" type="textarea"
            :placeholder="$t('create_calendar.payment_placeholder')" :rows="4" :show-count="true" :maxlength="1000" />
        </UIFormItem>
      </UIForm>

      <div class="mt-3 flex items-center space-x-3 pb-3">
        <div class="font-normal">
          {{ $t('calendar_advanced.forms_payment.test') }}
        </div>
        <div id="calendars-payment-live">
          <UISwitch v-model:value="state.formData.isLivePaymentMode" size="small">
          </UISwitch>
        </div>

        <div class="font-normal">
          {{ $t('calendar_advanced.forms_payment.live') }}
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div>
      <UIDivider />
      <UIHeader id="text-only-header" secondary-title="Payment"
        :description="$t('calendar_advanced.forms_payment.configure_payment')">
      </UIHeader>
      <UIDivider />

      <div class="flex items-center space-x-3 pb-3">
        <div id="calendars-acceptPayments-switch">
          <UISwitch size="small" :disabled="true"> </UISwitch>
        </div>
        <div class="font-normal">
          {{ $t('calendar_advanced.forms_payment.accept_payments') }}
        </div>
        <UITooltip :title="!(
    AppState.shouldAllowPayment ||
    (editingCalendar?.stripe &&
      Object(editingCalendar?.stripe).hasOwnProperty('amount'))
  )
    ? $t(
      'calendar_advanced.forms_payment.payment_disable_text_integration'
    )
    : $t(
        'calendar_advanced.forms_payment.payment_disable_text_recurring'
      )
    " placement="top">
          <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
        </UITooltip>
      </div>
    </div>
  </div>
</template>
